import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { doc, serverTimestamp, getDoc, updateDoc, query, where, getDocs, collection, deleteDoc, setDoc, limit, orderBy } from "firebase/firestore";

import { auth, firestoreDatabase } from '../../services/firebase'

// import { Storage } from './Storage';

const UsersCollection = 'users'


interface createUserData {
    email: string;
    password: string;
}

interface updateUserData {
    uid: string;
    enframe2024: boolean;
    noenframe2024: boolean;
    desc2024: boolean;
}


export function Admin() {


    async function isAdminUser(email: string) {
        try {
            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection),
                    where('email', '==', email),
                    where('type', '==', 'admin'),
                )
            )
                
            return docsSnap.docs.length > 0;
        } catch (error) {
            console.log('ERROR on User - isAdminUser: ', error)
            return false
        }
    }


    async function getData(userUid: string) {
        try {
            const docSnap = await getDoc(doc(firestoreDatabase, UsersCollection, userUid))
            if (docSnap.exists()) {
                return { ...docSnap.data(), uid: docSnap.id }
            } else {
                return {}
            }
        } catch (error) {
            // console.log('ERROR on User - getData: ', error)
            return 'Error!'
        }
    }

    async function getUsersData() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection),
                    // where('email', '==', email),
                    
                )
            )

            const data = docsSnap.docs.map(doc => ({ ...doc.data() }))

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getUsersDataByEmail(email: string) {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection),
                    where('email', '==', email),
                )
            )

            const data = docsSnap.docs.map(doc => doc.data().type)
            return data;

        } catch (error) {
            console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getInstitutionsData() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, 'institution'),
                    // where('email', '==', email),
                    // limit(1)
                )
            )

            const data = docsSnap.docs.map(doc => ({ ...doc.data() }))

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getAllUserDataForReport() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection)
                )
            )

            const data = docsSnap.docs.map(doc => {

                const userData = doc.data()

                // Email	Nome	Catgoria	Última etapa enviada	Terminou?	Data último login	Data de cadastro

                const lastStep = userData.category === 'Tecnologia' ? (
                    userData.additionalInformation ? 'Informações adicionais' :
                        userData.technologicalContributions2 ? 'Contribuições tecnológicas parte 2' :
                            userData.technologicalContributions ? 'Contribuições tecnológicas' :
                                userData.technologyPersonalData ? 'Dados pessoais' : 'Cadastro inicial'
                ) : (
                    userData.extraData ? 'Dados adicionais' :
                        userData.scientificProduction ? 'Produção científica' :
                            userData.bibliometricInformation ? 'dados Bibliométricos' :
                                userData.evidenceOfRecognition ? 'Evidências de reconhecimento' :
                                    userData.sciencePersonalData ? 'Dados pessoais' : 'Cadastro inicial'
                )

                const dateString = (item: any) => typeof item === 'number'
                    ? new Date(item).toISOString()
                    : new Date(item.seconds * 1000).toISOString()

                return {
                    email: userData.email,
                    name: userData.name,
                    category: userData.category,
                    lastStep: userData.completed ? 'Revisão' : lastStep,
                    completed: userData.completed,
                    loginAt: dateString(userData.loginAt),
                    createdAt: dateString(userData.createdAt),
                }
            })

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }


    async function login(email: string, password: string) {
        try {
            const resp = await signInWithEmailAndPassword(auth, email, password)
            return resp.user.uid
        } catch (error: any) {
            // console.log('ERROR on User - login: ', error)
            throw new Error(error)
        }
    }

    async function logout() {
        try {
            return await signOut(auth)
        } catch (error) {
            // console.log('ERROR on User - logout: ', error)
            return 'Error!'
        }
    }

    async function enframe({ uid, enframe2024, noenframe2024, ...rest }: updateUserData) {
        if (!uid) return null

        if (!uid.trim()) {
            return null
        }

        try {

            await updateDoc(doc(firestoreDatabase, UsersCollection, uid), {
                enframe2024,  updatedAt: serverTimestamp(),
                noenframe2024
            })

            return {
                ...rest, enframe2024, noenframe2024,  updatedAt: new Date().getTime(), uid
            }
        } catch (error: any) {
            // console.log('ERROR on User - update: ', error, error.message)
            throw new Error(error)
        }
    }

    async function  enframe2({ uid, noenframe2024, enframe2024,  ...rest }: updateUserData) {
        if (!uid) return null

        if (!uid.trim()) {
            return null
        }

        try {

            await updateDoc(doc(firestoreDatabase, UsersCollection, uid), {
                noenframe2024,  updatedAt: serverTimestamp(),
                enframe2024

            })

            return {
                ...rest, noenframe2024, enframe2024, updatedAt: new Date().getTime(),  uid
            }
        } catch (error: any) {
            // console.log('ERROR on User - update: ', error, error.message)
            throw new Error(error)
        }
    }

   

    async function enframe1({ uid, desc2024, ...rest }: updateUserData) {
        if (!uid) return null

        if (!uid.trim()) {
            return null
        }

        try {

            await updateDoc(doc(firestoreDatabase, UsersCollection, uid), {
                 desc2024, updatedAt: serverTimestamp()
            })

            return {
                ...rest,  desc2024, updatedAt: new Date().getTime(), uid
            }
        } catch (error: any) {
            // console.log('ERROR on User - update: ', error, error.message)
            throw new Error(error)
        }
    }


    return {
        getData, login, logout, getUsersData, getAllUserDataForReport, getInstitutionsData, enframe, enframe1, enframe2, getUsersDataByEmail, isAdminUser
    }
}
