
import { FormLabel } from '@mui/material'
import { FieldArray, Formik, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../../components/Button'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { InputFile } from '../../../components/InputFile'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'

import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'
import './styles.scss'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'
import { User } from '../../../core/Controllers/User'


// const validationSchema = yup.object({
// 	recommendationLetters: yup.array().of(
// 		yup.string().required('É necessário o envio do arquivo para continuar.')
// 	),
// 	curriculum: yup
// 		.string()
// 		.required('É necessário o envio do arquivo para continuar.'),
// })


export function ExtraData() {
	const navigate = useNavigate();
	const userContext = useAuth();


	const UserLogOut = async function () {
		await User().logout();
		return window.location.href = '/login';
	};

	const [valid1, setValid1] = useState(false);
	const [valid2, setValid2] = useState(false);
	const [valid3, setValid3] = useState(false);

	const [initialValues, setInitialValues] = useState({
		otherRelevantInformation: '',
		recommendationLetters: [{
			residue: '',
			origin: '',
			environmentalLiability: '',
			classification: '',
			collectionFrequency: '',
		}],
		curriculum: ''
	})
	const [loading, setLoading] = useState(true)

	// const formik = useFormik({
	// 	initialValues: {
	// 		otherRelevantInformation: '',
	// 		recommendationLetters: [''],
	// 		curriculum: ''
	// 	},
	// 	validationSchema: validationSchema,
	// 	onSubmit: (values) => {
	// 		userContext.updateUser({
	// 			extraData : values
	// 		})

	// 		navigate(routesNames.scienceRevision.path)
	// 	},
	// })

	function onSubmit(values: any) {
		const anoAtual = new Date().getFullYear().toString();
		const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};
	  
		userContext.updateUser({
		  ideias: {
			...userContext.user.ideias,
			[anoAtual]: {
			  ...dadosExistentes,
			  extraData: values,
			},
		  },
		});
	  
		window.scrollTo({ top: 0, behavior: 'smooth' });
	  
		let result = false;
		let count = 0;
		let count1 = 0;
	  
		for (let x in values.recommendationLetters) {
		  if (typeof values.recommendationLetters[x] === 'string') {
			count++;
		  }
		  count1++;
		}
	  
		if (count1 === count) {
		  result = true;
		}
	  
		if (
		  values.otherRelevantInformation.length > 0 &&
		  values.curriculum.length > 0 &&
		  result
		) {
		  navigate(routesNames.scienceRevision.path);
		} else {
		  setValid1(true);
		  setValid2(true);
		  setValid3(true);
		}
	  }

	  useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};
	  
		const bloco2025 = ideias[anoAtual]?.extraData;
		const bloco2024 = userContext?.user?.extraData1;
		const bloco2023 = userContext?.user?.extraData;

		let dataToLoad: Record<string, any> | null = null;

		if (bloco2025 && typeof bloco2025 === 'object' && Object.keys(bloco2025).length > 0) {
			dataToLoad = bloco2025;
		} else if (bloco2024 && typeof bloco2024 === 'object' && Object.keys(bloco2024).length > 0) {
			dataToLoad = bloco2024;
		} else if (bloco2023 && typeof bloco2023 === 'object' && Object.keys(bloco2023).length > 0) {
			dataToLoad = bloco2023;
		}
	  
		if (dataToLoad) {
		  setInitialValues((old) => ({
			...old,
			...dataToLoad,
		  }));
		}
	  
		setLoading(false);
	  }, [userContext?.user]);


	const allRequiredFields = [
		"mostImportantArticlesInYourResearch",
		"mostCitedArticlesAndCitationNumbers",
		"publishedBookChaptersAndArticles",
		"listPublishedBooks",
		"patentsGrantedByINPI",

		"scientificArticlesPublishedIndexedByScopus",
		"totalCitations",
		"totalScientificInitiationStudentsSupervised",
		"hIndexScopusScore",
		"leadershipIndex",

		"scienceAcademiesWhichYouAreMember",
		"listOfMostRelevantAwardsWithDescription",
		"listPlenaryAndKeynoteTalks",
		"listCurrentPositionsEditorshipInJournals",
		"listCongressesOrganizesAsChairman",
		"listLeadershipPositionsScientificSocieties",

		"otherRelevantInformation",
	];


	return (
		<><div className='formContainerCiencia'>
			<h2 className='textDadospessoais'>Informações adicionais</h2>

			{!loading && <Formik initialValues={initialValues} onSubmit={onSubmit}>

				{({ errors, values, touched, setValues }: { errors: any; values: any; touched: any; setValues: any }) => (
					<Form>

						{/* <Button
                    label="Salvar e sair"
                    type="outline"
                    style={{position: 'absolute', top: 0, right: 0}}
                    onClick={() => {
                        setTimeout(() => {
                            userContext?.signOutUser()
                        }, 200);
                    }}
                />  */}

						<FormLabel className="textScienticProd formLabel">Descreva outras informações relevantes:</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="otherRelevantInformation"
								name="otherRelevantInformation"
								value={values.otherRelevantInformation}
								onChange={(e: any) => setValues({ ...values, otherRelevantInformation: e.target.value, })}
								// error={touched.otherRelevantInformation && Boolean(errors.otherRelevantInformation)}
								// helperText={touched.otherRelevantInformation && errors.otherRelevantInformation}
								multiline
								minRows={4}
								maxRows={7} />
						</div>
						{valid1 &&
							<p className='error-extradados'>É necessário preencher o campo para continuar</p>}
						<FieldArray name="recommendationLetters">
							{({ insert, remove, push }) => (
								<div>
									{values.recommendationLetters.length > 0 &&
										values.recommendationLetters.map((recommendationLetter: any, index: any) => {



											return (
												<InputFile
													label={`Carregar cartas de recomendação*`}
													name={`recommendationLetters.${index}`}
													value={typeof recommendationLetter === 'string' ? recommendationLetter : recommendationLetter.value}
													onChange={(e: any) => setValues(
														{
															...values, recommendationLetters: values.recommendationLetters.map(
																(recLetter: string, index2: number) => index === index2 ? e.target.value : recLetter)
														}
													)} />
											)
										})}

									{/* {values.recommendationLetters.length === 25 && <div>
                            <br />
                            <p>Caso possua mais cartas de recomendação <strong>entre em contato</strong> com o suporte do Prêmio CBMM.</p>
                            <br />
                            <br />
                        </div>} */}

									{values.recommendationLetters.length < 25 && <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
										<Button
											type="outline"
											label="Adicionar mais"
											color={baseColors.darkBlue}
											typeSubmit={false}
											onClick={() => push({
												residue: '',
												origin: '',
												environmentalLiability: '',
												classification: '',
												collectionFrequency: '',
												estimatedMonthlyQuantity: '',
											})} />
									</div>}
								</div>
							)}
						</FieldArray>

						{valid2 &&
							<p className='error-extradados'>É necessário enviar um arquivo para continuar</p>}


						<InputFile
							label="Currículo Vitae Lattes:*"
							name="curriculum"
							value={values.curriculum}
							onChange={(e: any) => setValues({ ...values, curriculum: e.target.value })} />

						{valid3 &&
							<p className='error-extradados'>É necessário enviar um arquivo para continuar</p>}

						<div className='row-gap last'>
							<ButtonStages
								type="outline"
								label="SALVAR E SAIR"
								color={baseColors.lightIndigo}
								onClick={() => UserLogOut()} />

							<ButtonStagesBack
								type="outline"
								label="VOLTAR"
								typeSubmit
								color={baseColors.lightIndigo}
								onClick={() => navigate(-1)} />


							<Button
								style={{
									cursor: 'pointer',
									padding: '10px 50px',
									fontWeight: 400,
									fontSize: 16,
									fontFamily: "Open Sans",
									textAlign: 'center',
									marginRight: 40,
								}}

								label="AVANÇAR"
								color={baseColors.lightIndigo} />

							{/* <Button
            label="Salvar e avançar"
            color={baseColors.darkBlue}
        // style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
        /> */}
						</div>

						<ConfirmPopupBack />
						<ConfirmPopupSaveback />

					</Form>
				)}
			</Formik>}

		</div><div className='divProgress'>

				<ProgressBar
					userData={
						userContext?.user?.ideias?.[new Date().getFullYear().toString()] || {}
					}
					requiredFields={allRequiredFields} />

			</div></>
	)
}
