import { Navigate, Outlet } from "react-router-dom";
import { routesNames } from '../../routes/routesNames';

export const ProtectedAdminRoute = () => {
  
  const isAdmin = sessionStorage.getItem('isAdmin') === 'true';
  
  if (!isAdmin) {
    console.log("Redirecionando para:", routesNames.login.path);
    return <Navigate to={routesNames.login.path} replace />;
  }
  
  // Se for admin, permite acesso
  return <Outlet />;
};