
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../components/Button'
import { TextInput } from '../../components/TextInput'
import { Admin } from '../../core/Controllers/Admin'
import { useAuth } from '../../hooks/useAuth'
import { routesNames } from '../../routes/routesNames'
import { baseColors } from '../../styles'


import './styles.scss'


const validationSchema = yup.object({
	email: yup
		.string()
		.email('É preciso que o e-mail seja válido.')
		.required('É necessário preencher um e-mail válido para continuar.'),
	password: yup
		.string()
		.min(8, 'A senha precisa ter no mínimo 8 caracteres.')
		.required('É necessário preencher uma senha válida para continuar.'),
})

export function LoginAdmin() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const [email, setEmail] = useState('')

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			type: ''

		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			Admin().isAdminUser(email)
				.then((isAdmin) => {
					if (!isAdmin) {
						alert("Você não possui permissão para fazer login!");
						return navigate(routesNames.login.path);
					}

					Admin().login(values.email, values.password)
						.then(uid => Admin().getData(uid))
						.then((data) => {
							const dataToSave = { ...data } as any;
							delete dataToSave?.password;
							userContext.updateUser({
								...dataToSave
							});

							// Adicione esta linha para marcar o usuário como admin
							sessionStorage.setItem('isAdmin', 'true');

							return new Promise((resolve) => { setTimeout(resolve, 400) });
						})
						.then(() => navigate(routesNames.adminPage.path));
				});
		}
	})

	return (
		<div className='formContainer'>
			<h2 className='textInscrevaseTitle'>Entre como administrador:</h2>

			<form onSubmit={formik.handleSubmit}>
				<TextInput
					fullWidth
					id="email"
					name="email"
					placeholder="E-mail"
					value={formik.values.email}
					onChange={(e: any) => {
						formik.handleChange(e)
						setEmail(e.target?.value)
					}}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
				/>

				<TextInput
					fullWidth
					id="password"
					name="password"
					placeholder="Senha"
					type="password"
					value={formik.values.password}
					onChange={formik.handleChange}
					error={formik.touched.password && Boolean(formik.errors.password)}
					helperText={formik.touched.password && formik.errors.password}
				/>


				<div className='row-gap' style={{ marginTop: formik.touched.password && Boolean(formik.errors.password) ? 20 : 40, justifyContent: 'flex-end' }}>
					<Button
						label="Entrar"
						color={baseColors.lightIndigo}
					/>
				</div>

			</form>

		</div>
	)
}
