import { Navigate, Outlet } from "react-router-dom";
import { routesNames } from '../../routes/routesNames';

export const ProtectedUserRoute = () => {
    
    const isUser = sessionStorage.getItem('dados[0]') === 'user';
    
    if (!isUser) {
      console.log("Redirecionando para:", routesNames.login.path);
      return <Navigate to={routesNames.indicationLogin.path} replace />;
    }
    
    return <Outlet />;
  };