import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { SidebarData } from './SidebarData';
import { User } from "../../core/Controllers/User";
import './styles.css';

export function SideBarNavigator() {
    const userContext = useAuth();


    const downloadButtonClickAll = async () => {
        const csvContent = await User().exportUsersByCategory();

        if (!csvContent) return;

        if (typeof csvContent === 'string') {
            // Caso seja uma string simples
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "customers.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            // Caso seja um objeto com dois CSVs
            const { technologyCsv, scienceCsv } = csvContent;

            const download = (content: string, fileName: string) => {
                const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            };

            download(technologyCsv, 'technology.csv');
            download(scienceCsv, 'science.csv');
        }
    };


    const downloadButtonClickCiência = async () => {

        const csvContent = await User().exportAllUsersToCSV('Ciência');

        if (!csvContent) return

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "customers.csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    const downloadButtonClickTecnologia = async () => {

        const csvContent = await User().exportAllUsersToCSV('Tecnologia');

        if (!csvContent) return

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "customers.csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadButtonClickIndicados = async () => {

        const csvContent = await User().exportAllIndicationToCSV();

        if (!csvContent) return

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "customers.csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const url = window.location.href.split('/admin/')[1];

    return (
        <div id="sidebar-navigator">
            <nav className={'nav-menu active'}>
                <ul className='nav-menu-items'>

                    <li className='navbar-toggle'>
                        <div className='menu-bars'>
                            <p> </p>
                        </div>
                    </li>

                    {SidebarData.map((item, index) => (
                        <li key={index} className="nav-text">
                            <Link
                                to={item.path}
                                className={item.path.split('/admin/')[1] === url ? "selected" : ""}
                            >
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    ))}

                    <li className="nav-text">
                        <button className="buttonCommon" onClick={downloadButtonClickCiência}>
                            Exportar relatório Ciência
                        </button>
                    </li>

                    <li className="nav-text">
                        <button className="buttonCommon" onClick={downloadButtonClickTecnologia}>
                            Exportar relatório Tecnologia
                        </button>
                    </li>

                    <li className="nav-text">
                        <button className="buttonCommon" onClick={downloadButtonClickAll}>
                            Exportar relatório Completo
                        </button>
                    </li>

                    <li className="nav-text">
                        <button className="buttonCommon" onClick={downloadButtonClickIndicados}>
                            Exportar relatório Indicados
                        </button>
                    </li>

                    <Link
                        to={'https://premiocbmm.com.br/'}
                        className="logout-button"
                        onClick={() => {
                            userContext?.signOutUser();
                        }}
                    >
                        <span>Sair</span>
                    </Link>
                </ul>
            </nav>
        </div>
    );
}
