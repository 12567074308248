
import { FormLabel } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'
import { Button } from '../../../components/Button'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'


import './styles.scss'
import { TextInputChildren } from '../../../components/TextInputChildren'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'



const validationSchema = yup.object({
	mostImportantArticlesInYourResearch: yup
		.string(),
	mostCitedArticlesAndCitationNumbers: yup
		.string(),
	publishedBookChaptersAndArticles: yup
		.string(),
	listPublishedBooks: yup
		.string(),
	patentsGrantedByINPI: yup
		.string(),
})


export function ScientificProduction() {
	const navigate = useNavigate();
	const userContext = useAuth()


	const formik = useFormik({
		initialValues: {
		  mostImportantArticlesInYourResearch: '',
		  mostCitedArticlesAndCitationNumbers: '',
		  publishedBookChaptersAndArticles: '',
		  listPublishedBooks: '',
		  patentsGrantedByINPI: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
		  const anoAtual = new Date().getFullYear().toString();
	  
		  // Obtém os dados já existentes para o ano atual (se houver)
		  const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};
	  
		  // Atualiza o contexto do usuário com merge automático dos dados anteriores
		  userContext.updateUser({
			ideias: {
			  ...userContext.user.ideias,
			  [anoAtual]: {
				...dadosExistentes,
				scientificProduction: values, // Atualiza apenas a parte relacionada à produção científica
			  },
			},
		  });
	  
		  window.scrollTo({ top: 0, behavior: 'smooth' });
		  navigate(routesNames.evidenceOfRecognition.path);
		},
	  });


	  useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};
	  
		const bloco2025 = ideias[anoAtual]?.scientificProduction;
		const bloco2024 = userContext?.user?.scientificProduction1;
		const bloco2023 = userContext?.user?.scientificProduction;

		let dataToLoad: Record<string, any> | null = null;

	  
		if (bloco2025 && typeof bloco2025 === 'object' && Object.keys(bloco2025).length > 0) {
			dataToLoad = bloco2025;
		} else if (bloco2024 && typeof bloco2024 === 'object' && Object.keys(bloco2024).length > 0) {
			dataToLoad = bloco2024;
		} else if (bloco2023 && typeof bloco2023 === 'object' && Object.keys(bloco2023).length > 0) {
			dataToLoad = bloco2023;
		}
	  
		
	  
		if (dataToLoad) {
		  Object.entries(dataToLoad).forEach(([key, value]) => {
			formik.setFieldValue(key, value);
		  });
		}
	  }, [userContext?.user]);
	  

	const allRequiredFields = [
		"mostImportantArticlesInYourResearch",
		"mostCitedArticlesAndCitationNumbers",
		"publishedBookChaptersAndArticles",
		"listPublishedBooks",
		"patentsGrantedByINPI",

		"scientificArticlesPublishedIndexedByScopus",
		"totalCitations",
		"totalScientificInitiationStudentsSupervised",
		"hIndexScopusScore",
		"leadershipIndex",

		"scienceAcademiesWhichYouAreMember",
		"listOfMostRelevantAwardsWithDescription",
		"listPlenaryAndKeynoteTalks",
		"listCurrentPositionsEditorshipInJournals",
		"listCongressesOrganizesAsChairman",
		"listLeadershipPositionsScientificSocieties",

		"otherRelevantInformation",
	];

	return (
		<><div className='formContainerCiencia'>
			<h2 className='textDadospessoais'>Conte um pouco sobre os seus feitos para concluir a inscrição.</h2>

			<form onSubmit={formik.handleSubmit}>



				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os 10 artigos de maior importância na<br /> sua área de pesquisa pulicado em revistas:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="mostImportantArticlesInYourResearch"
						name="mostImportantArticlesInYourResearch"
						value={formik.values.mostImportantArticlesInYourResearch}
						onChange={formik.handleChange}
						error={formik.touched.mostImportantArticlesInYourResearch && Boolean(formik.errors.mostImportantArticlesInYourResearch)}
						helperText={formik.touched.mostImportantArticlesInYourResearch && formik.errors.mostImportantArticlesInYourResearch}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os seus dez (10) artigos mais citados e os respectivos números de citações:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="mostCitedArticlesAndCitationNumbers"
						name="mostCitedArticlesAndCitationNumbers"
						value={formik.values.mostCitedArticlesAndCitationNumbers}
						onChange={formik.handleChange}
						error={formik.touched.mostCitedArticlesAndCitationNumbers && Boolean(formik.errors.mostCitedArticlesAndCitationNumbers)}
						helperText={formik.touched.mostCitedArticlesAndCitationNumbers && formik.errors.mostCitedArticlesAndCitationNumbers}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os capítulos de livros e artigos publicados a convite de editores de revistas:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="publishedBookChaptersAndArticles"
						name="publishedBookChaptersAndArticles"
						value={formik.values.publishedBookChaptersAndArticles}
						onChange={formik.handleChange}
						error={formik.touched.publishedBookChaptersAndArticles && Boolean(formik.errors.publishedBookChaptersAndArticles)}
						helperText={formik.touched.publishedBookChaptersAndArticles && formik.errors.publishedBookChaptersAndArticles}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os livros publicados:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listPublishedBooks"
						name="listPublishedBooks"
						value={formik.values.listPublishedBooks}
						onChange={formik.handleChange}
						error={formik.touched.listPublishedBooks && Boolean(formik.errors.listPublishedBooks)}
						helperText={formik.touched.listPublishedBooks && formik.errors.listPublishedBooks}
						multiline
						minRows={4}
						maxRows={7} />
				</div>
				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste as patentes concedidas pelo INPI e instituições estrangeiras:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="patentsGrantedByINPI"
						name="patentsGrantedByINPI"
						value={formik.values.patentsGrantedByINPI}
						onChange={formik.handleChange}
						error={formik.touched.patentsGrantedByINPI && Boolean(formik.errors.patentsGrantedByINPI)}
						helperText={formik.touched.patentsGrantedByINPI && formik.errors.patentsGrantedByINPI}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<div className='row-gap last'>
					<ButtonStages
						type="outline"
						label="SALVAR E SAIR"
						color={baseColors.lightIndigo}
						onClick={() => {
							setTimeout(() => {
								userContext?.signOutUser()
							}, 200)
						}} />

					<ButtonStagesBack
						type="outline"
						label="VOLTAR"
						color={baseColors.lightIndigo}
						typeSubmit
						onClick={() => navigate(-1)} />


					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,
						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />

					{/* <Button
        label="Salvar e avançar"
        color={baseColors.darkBlue}
    // style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
    /> */}
				</div>

				<ConfirmPopupBack />
				<ConfirmPopupSaveback />

			</form>

		</div><div className='divProgress'>

				<ProgressBar
					userData={
						userContext?.user?.ideias?.[new Date().getFullYear().toString()] || {}
					}
					requiredFields={allRequiredFields} />

			</div></>
	)
}
