import { useEffect, useState } from "react";
import { SideBarNavigator } from "../../components/SideBar";
import { Admin } from "../../core/Controllers/Admin";
import { Institution } from "../../core/Controllers/Institution"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Dialog, DialogActions, Button, DialogContent, DialogTitle } from "@mui/material";
import { ViewIndicationsData } from "../../components/ViewIndicationsData";

export function AdminInsti2025() {
    const [institutions, setInstitutions] = useState<any[]>([]);
    const [indicationsMap, setIndicationsMap] = useState<Record<string, any>>({});
    const [loading, setLoading] = useState(true);
    
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState<any>(null);

    // Buscar instituições
    useEffect(() => {
        setLoading(true);
        Admin().getInstitutionsData().then((users: any) => {
            setInstitutions(users);
        });
    }, []);

    // Buscar indicações para cada instituição
    useEffect(() => {
        const fetchIndicationsForAll = async () => {
            setLoading(true);
            const indicationsData: Record<string, any> = {};
            
            // Criar um array de promessas para todas as instituições
            const promises = institutions.map(async (institution) => {
                if (institution.uid) {
                    try {
                        const indications = await Institution().getIndications(institution.uid);
                        if (indications && indications._total) {
                            indicationsData[institution.uid] = indications;
                        }
                    } catch (error) {
                        console.error(`Erro ao buscar indicações para ${institution.uid}:`, error);
                    }
                }
                return null; // Retorna null para manter a estrutura do array
            });
            
            // Aguardar todas as promessas concluírem
            await Promise.all(promises);
            
            setIndicationsMap(indicationsData);
            setLoading(false);
        };

        if (institutions.length > 0) {
            fetchIndicationsForAll();
        }
    }, [institutions]);

    return (
        <>
            <SideBarNavigator />

            <div style={{ marginLeft: 220 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, width: 1200 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome do usuário</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Nome da Instituição</TableCell>
                                <TableCell style={{ minWidth: 170 }}>CNPJ</TableCell>
                                <TableCell>Indicação Ciência</TableCell>
                                <TableCell>Indicação Tecnologia</TableCell>
                                <TableCell align="right">Completou as indicações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        Carregando dados...
                                    </TableCell>
                                </TableRow>
                            ) : (
                                institutions.map((row: any) => {
                                    // Obter dados de indicação para essa instituição
                                    const institutionIndications = indicationsMap[row.uid] || {};
                                    const totalIndications = institutionIndications._total || 0;
                                    
                                    // Calcular indicações de ciência e tecnologia
                                    let scienceCount = 0;
                                    let techCount = 0;
                                    
                                    // Contar indicações por categoria
                                    for (let i = 1; i <= totalIndications; i++) {
                                        const category = institutionIndications[`category_${i}`];
                                        if (category === "Ciência") scienceCount++;
                                        if (category === "Tecnologia") techCount++;
                                    }

                                    let createdAtDate: any;
                                    if (row.createdAt?.nanoseconds) {
                                        createdAtDate = new Date(row.createdAt.seconds * 1000 + row.createdAt.nanoseconds / 1000000);
                                    } else {
                                        createdAtDate = new Date(row.createdAt);
                                    }

                                    if (createdAtDate.getFullYear() === 2025) {
                                        return (
                                            <TableRow
                                                key={row.uid}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setRowData({
                                                        ...row,
                                                        indications: institutionIndications
                                                    });
                                                    setOpen(true);
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.institutionName}</TableCell>
                                                <TableCell>{row.cnpj}</TableCell>
                                                <TableCell align="center">{scienceCount} / 5</TableCell>
                                                <TableCell align="center">{techCount} / 5</TableCell>

                                                <TableCell align="right">{row.completed ? 'Sim' : 'Não'}</TableCell>
                                            </TableRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog
                    maxWidth={'lg'}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>Dados da inscrição</DialogTitle>

                    <DialogContent>
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <ViewIndicationsData data={rowData || {}} />
                        </Box>
                    </DialogContent>

                    <DialogActions style={{ gap: 20, marginRight: 30 }}>
                        <Button onClick={() => setOpen(false)} variant="contained">Fechar</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}