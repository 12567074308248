
import { useEffect, useState } from 'react'

import { FormControl, FormLabel, InputLabel, MenuItem, Select } from "@mui/material"
import { Institution } from "../../core/Controllers/Institution"
import { TextInput } from "../TextInput"
import { TextInputMask } from '../TextInputMask'


export function ViewIndicationsData({ data }: { data: any }) {


    const [indicationsData, setIndicationsData] = useState<any>({})
    const [totalIndications, setTotalIndications] = useState(0)


    useEffect(() => {
        Institution().getIndications(data.uid).then((indications) => {
            if (indications._total) {
                setIndicationsData(indications)
                setTotalIndications(indications._total)
            }
        })
    }, [data.uid])

    console.log('indicationsData> ', indicationsData)
    console.log('totalIndications> ', totalIndications)




    return (
        <>

            {totalIndications === 0 && <div>
                <br />
                <br />
                <h3>Não foram realizadas indicações para essa instituição ainda.</h3>
                <br />
                <br />
            </div>}



            {totalIndications > 0 && <div>
                <h4 className='tit-view-candidato'>Candidato 1</h4>

                <TextInput
                    fullWidth
                    id="name_1"
                    name="name_1"
                    label="Nome do candidato*"
                    value={indicationsData.name_1}
                    disabled={indicationsData.completed_1}
                    InputLabelProps={{ shrink: indicationsData.completed_1 }}
                />

                <TextInput
                    fullWidth
                    id="email_1"
                    name="email_1"
                    label="e-mail"
                    value={indicationsData.email_1}
                    disabled={indicationsData.completed_1}
                    InputLabelProps={{ shrink: indicationsData.completed_1 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_1"
                    name="phoneNumber_1"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_1}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_1}
                    InputLabelProps={{ shrink: indicationsData.completed_1 }}
                />


                <TextInput
                    fullWidth
                    id="category_1"
                    name="category_1"
                    label="Categoria"
                    value={indicationsData.category_1}
                    disabled={indicationsData.completed_1}
                    InputLabelProps={{ shrink: indicationsData.completed_1 }}
                />


                {/* <TextInputMask
                    fullWidth
                    id="cep_1"
                    name="cep_1"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_1}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_1}
                    InputLabelProps={{ shrink: indicationsData.completed_1 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_1"
                        name="city_1"
                        label="Cidade"
                        value={indicationsData.city_1}
                        disabled={indicationsData.completed_1}
                        InputLabelProps={{ shrink: indicationsData.completed_1 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_1"
                        name="city_1"
                        label="Estado"
                        value={indicationsData.state_1}
                        disabled={indicationsData.completed_1}
                        InputLabelProps={{ shrink: indicationsData.state_1 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_1"
                        name="address_1"
                        label="Endereço"
                        value={indicationsData.address_1}
                        disabled={indicationsData.completed_1}
                        InputLabelProps={{ shrink: indicationsData.completed_1 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_1"
                        name="addressComplement_1"
                        label="Complemento"
                        value={indicationsData.addressComplement_1}
                        disabled={indicationsData.completed_1}
                        InputLabelProps={{ shrink: indicationsData.completed_1 }}
                    />
                </div> */}
            </div>}



            {totalIndications > 1 && <div>
                <h4 className='tit-view-candidato'>Candidato 2</h4>

                <TextInput
                    fullWidth
                    id="name_2"
                    name="name_2"
                    label="Nome do candidato*"
                    value={indicationsData.name_2}
                    disabled={indicationsData.completed_2}
                    InputLabelProps={{ shrink: indicationsData.completed_2 }}
                />

                <TextInput
                    fullWidth
                    id="email_2"
                    name="email_2"
                    label="e-mail"
                    value={indicationsData.email_2}
                    disabled={indicationsData.completed_2}
                    InputLabelProps={{ shrink: indicationsData.completed_2 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_2"
                    name="phoneNumber_2"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_2}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_2}
                    InputLabelProps={{ shrink: indicationsData.completed_2 }}
                />

                <TextInput
                    fullWidth
                    id="category_2"
                    name="category_2"
                    label="Categoria"
                    value={indicationsData.category_2}
                    disabled={indicationsData.completed_2}
                    InputLabelProps={{ shrink: indicationsData.completed_2 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_2"
                    name="cep_2"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_2}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_2}
                    InputLabelProps={{ shrink: indicationsData.completed_2 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_2"
                        name="city_2"
                        label="Cidade"
                        value={indicationsData.city_2}
                        disabled={indicationsData.completed_2}
                        InputLabelProps={{ shrink: indicationsData.completed_2 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_2"
                        name="city_2"
                        label="Estado"
                        value={indicationsData.state_2}
                        disabled={indicationsData.completed_2}
                        InputLabelProps={{ shrink: indicationsData.state_2 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_2"
                        name="address_2"
                        label="Endereço"
                        value={indicationsData.address_2}
                        disabled={indicationsData.completed_2}
                        InputLabelProps={{ shrink: indicationsData.completed_2 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_2"
                        name="addressComplement_2"
                        label="Complemento"
                        value={indicationsData.addressComplement_2}
                        disabled={indicationsData.completed_2}
                        InputLabelProps={{ shrink: indicationsData.completed_2 }}
                    />
                </div> */}

            </div>}



            {totalIndications > 2 && <div>
                <h4 className='tit-view-candidato'>Candidato 3</h4>

                <TextInput
                    fullWidth
                    id="name_3"
                    name="name_3"
                    label="Nome do candidato*"
                    value={indicationsData.name_3}
                    disabled={indicationsData.completed_3}
                    InputLabelProps={{ shrink: indicationsData.completed_3 }}
                />

                <TextInput
                    fullWidth
                    id="email_3"
                    name="email_3"
                    label="e-mail"
                    value={indicationsData.email_3}
                    disabled={indicationsData.completed_3}
                    InputLabelProps={{ shrink: indicationsData.completed_3 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_3"
                    name="phoneNumber_3"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_3}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_3}
                    InputLabelProps={{ shrink: indicationsData.completed_3 }}
                />

                <TextInput
                    fullWidth
                    id="category_3"
                    name="category_3"
                    label="Categoria"
                    value={indicationsData.category_3}
                    disabled={indicationsData.completed_3}
                    InputLabelProps={{ shrink: indicationsData.completed_3 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_3"
                    name="cep_3"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_3}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_3}
                    InputLabelProps={{ shrink: indicationsData.completed_3 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_3"
                        name="city_3"
                        label="Cidade"
                        value={indicationsData.city_3}
                        disabled={indicationsData.completed_3}
                        InputLabelProps={{ shrink: indicationsData.completed_3 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_3"
                        name="city_3"
                        label="Estado"
                        value={indicationsData.state_3}
                        disabled={indicationsData.completed_3}
                        InputLabelProps={{ shrink: indicationsData.state_3 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_3"
                        name="address_3"
                        label="Endereço"
                        value={indicationsData.address_3}
                        disabled={indicationsData.completed_3}
                        InputLabelProps={{ shrink: indicationsData.completed_3 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_3"
                        name="addressComplement_3"
                        label="Complemento"
                        value={indicationsData.addressComplement_3}
                        disabled={indicationsData.completed_3}
                        InputLabelProps={{ shrink: indicationsData.completed_3 }}
                    />
                </div> */}

            </div>}



            {totalIndications > 3 && <div>
                <h4 className='tit-view-candidato'>Candidato 4</h4>

                <TextInput
                    fullWidth
                    id="name_4"
                    name="name_4"
                    label="Nome do candidato*"
                    value={indicationsData.name_4}
                    disabled={indicationsData.completed_4}
                    InputLabelProps={{ shrink: indicationsData.completed_4 }}
                />

                <TextInput
                    fullWidth
                    id="email_4"
                    name="email_4"
                    label="e-mail"
                    value={indicationsData.email_4}
                    disabled={indicationsData.completed_4}
                    InputLabelProps={{ shrink: indicationsData.completed_4 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_4"
                    name="phoneNumber_4"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_4}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_4}
                    InputLabelProps={{ shrink: indicationsData.completed_4 }}
                />

                <TextInput
                    fullWidth
                    id="category_4"
                    name="category_4"
                    label="Categoria"
                    value={indicationsData.category_4}
                    disabled={indicationsData.completed_4}
                    InputLabelProps={{ shrink: indicationsData.completed_4 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_4"
                    name="cep_4"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_4}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_4}
                    InputLabelProps={{ shrink: indicationsData.completed_4 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_4"
                        name="city_4"
                        label="Cidade"
                        value={indicationsData.city_4}
                        disabled={indicationsData.completed_4}
                        InputLabelProps={{ shrink: indicationsData.completed_4 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_4"
                        name="city_4"
                        label="Estado"
                        value={indicationsData.state_4}
                        disabled={indicationsData.completed_4}
                        InputLabelProps={{ shrink: indicationsData.state_4 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_4"
                        name="address_4"
                        label="Endereço"
                        value={indicationsData.address_4}
                        disabled={indicationsData.completed_4}
                        InputLabelProps={{ shrink: indicationsData.completed_4 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_4"
                        name="addressComplement_4"
                        label="Complemento"
                        value={indicationsData.addressComplement_4}
                        disabled={indicationsData.completed_4}
                        InputLabelProps={{ shrink: indicationsData.completed_4 }}
                    />
                </div> */}

            </div>}



            {totalIndications > 4 && <div>
                <h4 className='tit-view-candidato'>Candidato 5</h4>

                <TextInput
                    fullWidth
                    id="name_5"
                    name="name_5"
                    label="Nome do candidato*"
                    value={indicationsData.name_5}
                    disabled={indicationsData.completed_5}
                    InputLabelProps={{ shrink: indicationsData.completed_5 }}
                />

                <TextInput
                    fullWidth
                    id="email_5"
                    name="email_5"
                    label="e-mail"
                    value={indicationsData.email_5}
                    disabled={indicationsData.completed_5}
                    InputLabelProps={{ shrink: indicationsData.completed_5 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_5"
                    name="phoneNumber_5"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_5}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_5}
                    InputLabelProps={{ shrink: indicationsData.completed_5 }}
                />


                <TextInput
                    fullWidth
                    id="category_5"
                    name="category_5"
                    label="Categoria"
                    value={indicationsData.category_5}
                    disabled={indicationsData.completed_5}
                    InputLabelProps={{ shrink: indicationsData.completed_5 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_5"
                    name="cep_5"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_5}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_5}
                    InputLabelProps={{ shrink: indicationsData.completed_5 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_5"
                        name="city_5"
                        label="Cidade"
                        value={indicationsData.city_5}
                        disabled={indicationsData.completed_5}
                        InputLabelProps={{ shrink: indicationsData.completed_5 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_5"
                        name="city_5"
                        label="Estado"
                        value={indicationsData.state_5}
                        disabled={indicationsData.completed_5}
                        InputLabelProps={{ shrink: indicationsData.state_5 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_5"
                        name="address_5"
                        label="Endereço"
                        value={indicationsData.address_5}
                        disabled={indicationsData.completed_5}
                        InputLabelProps={{ shrink: indicationsData.completed_5 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_5"
                        name="addressComplement_5"
                        label="Complemento"
                        value={indicationsData.addressComplement_5}
                        disabled={indicationsData.completed_5}
                        InputLabelProps={{ shrink: indicationsData.completed_5 }}
                    />
                </div> */}

            </div>}

            {totalIndications > 5 && <div>
                <h4 className='tit-view-candidato'>Candidato 6</h4>

                <TextInput
                    fullWidth
                    id="name_6"
                    name="name_6"
                    label="Nome do candidato*"
                    value={indicationsData.name_6}
                    disabled={indicationsData.completed_6}
                    InputLabelProps={{ shrink: indicationsData.completed_6 }}
                />

                <TextInput
                    fullWidth
                    id="email_6"
                    name="email_6"
                    label="e-mail"
                    value={indicationsData.email_6}
                    disabled={indicationsData.completed_6}
                    InputLabelProps={{ shrink: indicationsData.completed_6 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_6"
                    name="phoneNumber_6"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_6}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_6}
                    InputLabelProps={{ shrink: indicationsData.completed_6 }}
                />

                <TextInput
                    fullWidth
                    id="category_6"
                    name="category_6"
                    label="Categoria"
                    value={indicationsData.category_6}
                    disabled={indicationsData.completed_6}
                    InputLabelProps={{ shrink: indicationsData.completed_6 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_6"
                    name="cep_6"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_6}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_6}
                    InputLabelProps={{ shrink: indicationsData.completed_6 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_6"
                        name="city_6"
                        label="Cidade"
                        value={indicationsData.city_6}
                        disabled={indicationsData.completed_6}
                        InputLabelProps={{ shrink: indicationsData.completed_6 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_6"
                        name="city_6"
                        label="Estado"
                        value={indicationsData.state_6}
                        disabled={indicationsData.completed_6}
                        InputLabelProps={{ shrink: indicationsData.state_6 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_6"
                        name="address_6"
                        label="Endereço"
                        value={indicationsData.address_6}
                        disabled={indicationsData.completed_6}
                        InputLabelProps={{ shrink: indicationsData.completed_6 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_6"
                        name="addressComplement_6"
                        label="Complemento"
                        value={indicationsData.addressComplement_6}
                        disabled={indicationsData.completed_6}
                        InputLabelProps={{ shrink: indicationsData.completed_6 }}
                    />
                </div> */}

            </div>}

            {totalIndications > 6 && <div>
                <h4 className='tit-view-candidato'>Candidato 7</h4>

                <TextInput
                    fullWidth
                    id="name_7"
                    name="name_7"
                    label="Nome do candidato*"
                    value={indicationsData.name_7}
                    disabled={indicationsData.completed_7}
                    InputLabelProps={{ shrink: indicationsData.completed_7 }}
                />

                <TextInput
                    fullWidth
                    id="email_7"
                    name="email_7"
                    label="e-mail"
                    value={indicationsData.email_7}
                    disabled={indicationsData.completed_7}
                    InputLabelProps={{ shrink: indicationsData.completed_7 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_7"
                    name="phoneNumber_7"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_7}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_7}
                    InputLabelProps={{ shrink: indicationsData.completed_7 }}
                />


                <TextInput
                    fullWidth
                    id="category_7"
                    name="category_7"
                    label="Categoria"
                    value={indicationsData.category_7}
                    disabled={indicationsData.completed_7}
                    InputLabelProps={{ shrink: indicationsData.completed_7 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_7"
                    name="cep_7"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_7}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_7}
                    InputLabelProps={{ shrink: indicationsData.completed_7 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_7"
                        name="city_7"
                        label="Cidade"
                        value={indicationsData.city_7}
                        disabled={indicationsData.completed_7}
                        InputLabelProps={{ shrink: indicationsData.completed_7 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_7"
                        name="city_7"
                        label="Estado"
                        value={indicationsData.state_7}
                        disabled={indicationsData.completed_7}
                        InputLabelProps={{ shrink: indicationsData.state_7 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_7"
                        name="address_7"
                        label="Endereço"
                        value={indicationsData.address_7}
                        disabled={indicationsData.completed_7}
                        InputLabelProps={{ shrink: indicationsData.completed_7 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_7"
                        name="addressComplement_7"
                        label="Complemento"
                        value={indicationsData.addressComplement_7}
                        disabled={indicationsData.completed_7}
                        InputLabelProps={{ shrink: indicationsData.completed_7 }}
                    />
                </div> */}

            </div>}

            {totalIndications > 7 && <div>
                <h4 className='tit-view-candidato'>Candidato 8</h4>

                <TextInput
                    fullWidth
                    id="name_8"
                    name="name_8"
                    label="Nome do candidato*"
                    value={indicationsData.name_8}
                    disabled={indicationsData.completed_8}
                    InputLabelProps={{ shrink: indicationsData.completed_8 }}
                />

                <TextInput
                    fullWidth
                    id="email_8"
                    name="email_8"
                    label="e-mail"
                    value={indicationsData.email_8}
                    disabled={indicationsData.completed_8}
                    InputLabelProps={{ shrink: indicationsData.completed_8 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_8"
                    name="phoneNumber_8"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_8}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_8}
                    InputLabelProps={{ shrink: indicationsData.completed_8 }}
                />


                <TextInput
                    fullWidth
                    id="category_8"
                    name="category_8"
                    label="Categoria"
                    value={indicationsData.category_8}
                    disabled={indicationsData.completed_8}
                    InputLabelProps={{ shrink: indicationsData.completed_8 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_8"
                    name="cep_8"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_8}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_8}
                    InputLabelProps={{ shrink: indicationsData.completed_8 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_8"
                        name="city_8"
                        label="Cidade"
                        value={indicationsData.city_8}
                        disabled={indicationsData.completed_8}
                        InputLabelProps={{ shrink: indicationsData.completed_8 }}
                    />

                    <TextInput
                        style={{ maxWidth: '28%' }}
                        id="city_8"
                        name="city_8"
                        label="Estado"
                        value={indicationsData.state_8}
                        disabled={indicationsData.completed_8}
                        InputLabelProps={{ shrink: indicationsData.state_8 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_8"
                        name="address_8"
                        label="Endereço"
                        value={indicationsData.address_8}
                        disabled={indicationsData.completed_8}
                        InputLabelProps={{ shrink: indicationsData.completed_8 }}
                    />

                    <TextInput
                        style={{ maxWidth: '28%' }}
                        id="addressComplement_8"
                        name="addressComplement_8"
                        label="Complemento"
                        value={indicationsData.addressComplement_8}
                        disabled={indicationsData.completed_8}
                        InputLabelProps={{ shrink: indicationsData.completed_8 }}
                    />
                </div> */}

            </div>}


            {totalIndications > 8 && <div>
                <h4 className='tit-view-candidato'>Candidato 9</h4>

                <TextInput
                    fullWidth
                    id="name_9"
                    name="name_9"
                    label="Nome do candidato*"
                    value={indicationsData.name_9}
                    disabled={indicationsData.completed_9}
                    InputLabelProps={{ shrink: indicationsData.completed_9 }}
                />

                <TextInput
                    fullWidth
                    id="email_9"
                    name="email_9"
                    label="e-mail"
                    value={indicationsData.email_9}
                    disabled={indicationsData.completed_9}
                    InputLabelProps={{ shrink: indicationsData.completed_9 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_9"
                    name="phoneNumber_9"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_9}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_9}
                    InputLabelProps={{ shrink: indicationsData.completed_9 }}
                />

                <TextInput
                    fullWidth
                    id="category_9"
                    name="category_9"
                    label="Categoria"
                    value={indicationsData.category_9}
                    disabled={indicationsData.completed_9}
                    InputLabelProps={{ shrink: indicationsData.completed_9 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_9"
                    name="cep_9"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_9}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    disabled={indicationsData.completed_9}
                    InputLabelProps={{ shrink: indicationsData.completed_9 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_9"
                        name="city_9"
                        label="Cidade"
                        value={indicationsData.city_9}
                        disabled={indicationsData.completed_9}
                        InputLabelProps={{ shrink: indicationsData.completed_9 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_9"
                        name="city_9"
                        label="Estado"
                        value={indicationsData.state_9}
                        disabled={indicationsData.completed_9}
                        InputLabelProps={{ shrink: indicationsData.state_9 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_9"
                        name="address_9"
                        label="Endereço"
                        value={indicationsData.address_9}
                        disabled={indicationsData.completed_9}
                        InputLabelProps={{ shrink: indicationsData.completed_9 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_9"
                        name="addressComplement_9"
                        label="Complemento"
                        value={indicationsData.addressComplement_9}
                        disabled={indicationsData.completed_9}
                        InputLabelProps={{ shrink: indicationsData.completed_9 }}
                    />
                </div> */}

            </div>}


            {totalIndications > 9 && <div>
                <h4 className='tit-view-candidato'>Candidato 10</h4>

                <TextInput
                    fullWidth
                    id="name_10"
                    name="name_10"
                    label="Nome do candidato*"
                    value={indicationsData.name_10}
                    disabled={indicationsData.completed_10}
                    InputLabelProps={{ shrink: indicationsData.completed_10 }}
                />

                <TextInput
                    fullWidth
                    id="email_10"
                    name="email_10"
                    label="e-mail"
                    value={indicationsData.email_10}
                    disabled={indicationsData.completed_10}
                    InputLabelProps={{ shrink: indicationsData.completed_10 }}
                />

                <TextInputMask
                    fullWidth
                    id="phoneNumber_10"
                    name="phoneNumber_10"
                    label="Telefone do candidato"
                    value={indicationsData.phoneNumber_10}
                    sx={{
                        border: "none !important" // ✅ Funciona com MUI
                    }}
                    mask="(##) # ####-####"
                    definitions={{
                        '#': /[0-10]/,
                    }}
                    disabled={indicationsData.completed_10}
                    InputLabelProps={{ shrink: indicationsData.completed_10 }}
                />

                <TextInput
                    fullWidth
                    id="category_10"
                    name="category_10"
                    label="Categoria"
                    value={indicationsData.category_10}
                    disabled={indicationsData.completed_10}
                    InputLabelProps={{ shrink: indicationsData.completed_10 }}
                />

                {/* <TextInputMask
                    fullWidth
                    id="cep_10"
                    name="cep_10"
                    label="CEP"
                    placeholder='00000-000'
                    value={indicationsData.cep_10}
                    mask="#####-###"
                    definitions={{
                        '#': /[0-10]/,
                    }}
                    disabled={indicationsData.completed_10}
                    InputLabelProps={{ shrink: indicationsData.completed_10 }}
                /> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="city_10"
                        name="city_10"
                        label="Cidade"
                        value={indicationsData.city_10}
                        disabled={indicationsData.completed_10}
                        InputLabelProps={{ shrink: indicationsData.completed_10 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="city_10"
                        name="city_10"
                        label="Estado"
                        value={indicationsData.state_10}
                        disabled={indicationsData.completed_10}
                        InputLabelProps={{ shrink: indicationsData.state_10 }}
                    />
                </div> */}

                {/* <div className="formBlock">
                    <TextInput
                        fullWidth
                        id="address_10"
                        name="address_10"
                        label="Endereço"
                        value={indicationsData.address_10}
                        disabled={indicationsData.completed_10}
                        InputLabelProps={{ shrink: indicationsData.completed_10 }}
                    />

                    <TextInput
                        style={{ maxWidth: '27%' }}
                        id="addressComplement_10"
                        name="addressComplement_10"
                        label="Complemento"
                        value={indicationsData.addressComplement_10}
                        disabled={indicationsData.completed_10}
                        InputLabelProps={{ shrink: indicationsData.completed_10 }}
                    />
                </div> */}

            </div>}


        </>
    )
}

