
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { ProgressBarTecnology } from '../../../components/ProgressBarTecnology/ProgressBarTecnology'
import { Button } from '../../../components/Button'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { CepService } from '../../../services/cep'
import { baseColors } from '../../../styles'


import './styles.scss'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'

// // const validationSchema = yup.object({
// // 	technologyCompaniesCreated: yup
// // 		.string()
// // 		.required('É necessário preencher um nome para continuar.'),
// 	// email: yup
// 	// 	.string()
// 	// 	.email('É preciso que o e-mail seja válido.')
// 	// 	.required('É necessário preencher um e-mail válido para continuar.'),
// 	// password: yup
// 	// 	.string()
// 	// 	.min(8, 'A senha precisa ter no mínimo 8 caracteres.')
// 	// 	.required('É necessário preencher uma senha válida para continuar.'),
// 	// passwordConfirm: yup
// 	//     .string()
// 	//     .min(8, 'A senha precisa ter no mínimo 8 caracteres.')
// 	//     .required('É necessário preencher uma senha válida para continuar.')
// 	//     .oneOf([yup.ref('password')], 'As senhas não são iguais.'),
// 	// phoneNumber: yup
// 	//     .string()
// 	//     .min(6, 'O número de telefone precisa ter no mínimo 6 caracteres.'),
// 	// category: yup
// 	//     .string()
// 	//     .required('É necessário selecionar uma categoria para continuar.')
// 	//     .oneOf(['Ciência', 'Tecnologia']),
// 	// acceptTerms: yup
// 	//     .boolean()
// 	//     .required('É necessário concordar com os termos do regulamento para continuar.')
// 	//     .test('Foi aceito', 'É necessário concordar com os termos do regulamento para continuar.', (value: boolean) => value)
// })


export function TechnologicalContributions2() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const formik = useFormik({
		initialValues: {
		  technologyCompaniesCreated: '',
		  listOfTechnologyCompaniesCreated: '',
		  demonstrationOfCompaniesBenefited: '',
		  patentsGrantedByINPI: '',
		  patentsLicensed: '',
		  licensingOfPatentsToCompanies: '',
		  developmentOfProductsAndProcessesBeenPatented: '',
		  developedProductsOnMarket: '',
		  developedTechnologicalProcessesExecutedByCompanies: ''
		},
		onSubmit: (values) => {
		  const anoAtual = new Date().getFullYear().toString();
	  
		  const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};
	  
		  userContext.updateUser({
			ideias: {
			  ...userContext.user.ideias,
			  [anoAtual]: {
				...dadosExistentes,
				technologicalContributions2: values,
			  },
			},
		  });
	  
		  window.scrollTo({ top: 0, behavior: 'smooth' });
		  navigate(routesNames.additionalInformation.path);
		},
	  });


	  useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};
	
		// Blocos por ano
		const bloco2025 = ideias[anoAtual]?.technologicalContributions2;
		const bloco2024 = userContext?.user?.technologicalContributions4;
		const bloco2023 = userContext?.user?.technologicalContributions2;
	
		let dataToLoad = null;
	
		if (bloco2025 && typeof bloco2025 === 'object' && Object.keys(bloco2025).length > 0) {
			dataToLoad = bloco2025;
		} else if (bloco2024 && typeof bloco2024 === 'object' && Object.keys(bloco2024).length > 0) {
			dataToLoad = bloco2024;
		} else if (bloco2023 && typeof bloco2023 === 'object' && Object.keys(bloco2023).length > 0) {
			dataToLoad = bloco2023;
		}
	
		if (dataToLoad) {
			Object.entries(dataToLoad).forEach(([key, value]) => {
				formik.setFieldValue(key, value);
			});
		}
	}, [userContext?.user]);
	


	const allRequiredFields = [
		"degreeOfInnovation",
		"relevanceOfContributions",
		"evidenceOfRecognition",
		"formOfRecognition",
		"technologyCompaniesCreated",
		"listOfTechnologyCompaniesCreated",
		"demonstrationOfCompaniesBenefited",
		"patentsGrantedByINPI",
		"patentsLicensed",
		"licensingOfPatentsToCompanies",
		"developmentOfProductsAndProcessesBeenPatented",
		"developedProductsOnMarket",
		"developedTechnologicalProcessesExecutedByCompanies",
		"activitiesInTechnologicalSystems",
		"consultancyToTechnologyCompanies",
		"mainAwardsWithImportance",
		"trainingHumanResourcesImpactedInnovation",
		"familiarityWithModernTechnologies",
		"mostRelevantTechnicalScientificPublication",
		"otherRelevantInformation"
	];



	return (
		<><div className='formContainerTecnologia'>
			<h2 className='textDadospessoais'>Continue descrevendo suas contribuições tecnológicas</h2>

			<form onSubmit={formik.handleSubmit}>

				{/* <Button
        label="Salvar e sair"
        type="outline"
        style={{position: 'absolute', top: 0, right: 0}}
        onClick={() => {
            setTimeout(() => {
                userContext?.signOutUser()
            }, 200);
        }}
    /> */}

				<FormLabel className="textScienticProd formLabel">Quantas empresas de tecnologia foram criadas ou ajudou a criar? (resposta numérica)</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="technologyCompaniesCreated"
						name="technologyCompaniesCreated"
						value={formik.values.technologyCompaniesCreated}
						onChange={formik.handleChange}
						error={formik.touched.technologyCompaniesCreated && Boolean(formik.errors.technologyCompaniesCreated)}
						helperText={formik.touched.technologyCompaniesCreated && formik.errors.technologyCompaniesCreated}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">Lista das empresas de tecnologia criadas ou que ajudou a criar. Nota: O item requer a comprovação inequívoca das empresas criadas.</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listOfTechnologyCompaniesCreated"
						name="listOfTechnologyCompaniesCreated"
						type="listOfTechnologyCompaniesCreated"
						value={formik.values.listOfTechnologyCompaniesCreated}
						onChange={formik.handleChange}
						error={formik.touched.listOfTechnologyCompaniesCreated && Boolean(formik.errors.listOfTechnologyCompaniesCreated)}
						helperText={formik.touched.listOfTechnologyCompaniesCreated && formik.errors.listOfTechnologyCompaniesCreated}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">Apresente demonstração de empresas que se beneficiaram de suas contribuições tecnológicas:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="demonstrationOfCompaniesBenefited"
						name="demonstrationOfCompaniesBenefited"
						type="demonstrationOfCompaniesBenefited"
						value={formik.values.demonstrationOfCompaniesBenefited}
						onChange={formik.handleChange}
						error={formik.touched.demonstrationOfCompaniesBenefited && Boolean(formik.errors.demonstrationOfCompaniesBenefited)}
						helperText={formik.touched.demonstrationOfCompaniesBenefited && formik.errors.demonstrationOfCompaniesBenefited}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">Liste as patentes concedidas pelo INPI ou agências estrangeiras:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="patentsGrantedByINPI"
						name="patentsGrantedByINPI"
						type="patentsGrantedByINPI"
						value={formik.values.patentsGrantedByINPI}
						onChange={formik.handleChange}
						error={formik.touched.patentsGrantedByINPI && Boolean(formik.errors.patentsGrantedByINPI)}
						helperText={formik.touched.patentsGrantedByINPI && formik.errors.patentsGrantedByINPI}
						multiline
						minRows={4}
						maxRows={7} />
				</div>
				<FormLabel className="textScienticProd formLabel">Quantas patentes foram licenciadas? (resposta numérica)</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="patentsLicensed"
						name="patentsLicensed"
						value={formik.values.patentsLicensed}
						onChange={formik.handleChange}
						error={formik.touched.patentsLicensed && Boolean(formik.errors.patentsLicensed)}
						helperText={formik.touched.patentsLicensed && formik.errors.patentsLicensed}
						multiline
						minRows={4}
						maxRows={7} />

				</div>
				<FormLabel className="textScienticProd formLabel">Descreva o licenciamento de patentes a empresas no país e no exterior, destacando os impactos econômicos. Nota: O item requer a comprovação inequívoca dos impactos econômicos.</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="licensingOfPatentsToCompanies"
						name="licensingOfPatentsToCompanies"
						type="licensingOfPatentsToCompanies"
						value={formik.values.licensingOfPatentsToCompanies}
						onChange={formik.handleChange}
						error={formik.touched.licensingOfPatentsToCompanies && Boolean(formik.errors.licensingOfPatentsToCompanies)}
						helperText={formik.touched.licensingOfPatentsToCompanies && formik.errors.licensingOfPatentsToCompanies}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">Descreva o desenvolvimento de produtos e processos que não foram patenteados:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="developmentOfProductsAndProcessesBeenPatented"
						name="developmentOfProductsAndProcessesBeenPatented"
						type="developmentOfProductsAndProcessesBeenPatented"
						value={formik.values.developmentOfProductsAndProcessesBeenPatented}
						onChange={formik.handleChange}
						error={formik.touched.developmentOfProductsAndProcessesBeenPatented && Boolean(formik.errors.developmentOfProductsAndProcessesBeenPatented)}
						helperText={formik.touched.developmentOfProductsAndProcessesBeenPatented && formik.errors.developmentOfProductsAndProcessesBeenPatented}
						multiline
						minRows={4}
						maxRows={7} />

				</div>

				<FormLabel className="textScienticProd formLabel">Indique os produtos desenvolvidos que estão no mercado:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="developedProductsOnMarket"
						name="developedProductsOnMarket"
						type="developedProductsOnMarket"
						value={formik.values.developedProductsOnMarket}
						onChange={formik.handleChange}
						error={formik.touched.developedProductsOnMarket && Boolean(formik.errors.developedProductsOnMarket)}
						helperText={formik.touched.developedProductsOnMarket && formik.errors.developedProductsOnMarket}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">Indique processos tecnológicos desenvolvidos que estão em execução por empresas:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="developedTechnologicalProcessesExecutedByCompanies"
						name="developedTechnologicalProcessesExecutedByCompanies"
						type="developedTechnologicalProcessesExecutedByCompanies"
						value={formik.values.developedTechnologicalProcessesExecutedByCompanies}
						onChange={formik.handleChange}
						error={formik.touched.developedTechnologicalProcessesExecutedByCompanies && Boolean(formik.errors.developedTechnologicalProcessesExecutedByCompanies)}
						helperText={formik.touched.developedTechnologicalProcessesExecutedByCompanies && formik.errors.developedTechnologicalProcessesExecutedByCompanies}
						multiline
						minRows={4}
						maxRows={7} />

				</div>
				<div className='row-gap last'>
					<ButtonStages
						type="outline"
						label="SALVAR E SAIR"
						color={baseColors.lightIndigo}
						onClick={() => {
							setTimeout(() => {
								userContext?.signOutUser()
							}, 200)
						}} />

					<ButtonStagesBack
						type="outline"
						label="VOLTAR"
						color={baseColors.lightIndigo}
						typeSubmit
						onClick={() => navigate(-1)} />

					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,
						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />

					{/* <Button
        label="Salvar e avançar"
        color={baseColors.darkBlue}
    // style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
    /> */}
				</div>

				<ConfirmPopupBack />
				<ConfirmPopupSaveback />

			</form>

		</div><div className='divProgress'>
		<ProgressBarTecnology
  userData={userContext?.user?.ideias?.[new Date().getFullYear().toString()] || {}}
  requiredFields={allRequiredFields}
/>
			</div></>
	)
}
