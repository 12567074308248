
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { ProgressBarTecnology } from '../../../components/ProgressBarTecnology/ProgressBarTecnology'
import { Button } from '../../../components/Button'
import { TextInputChildren } from '../../../components/TextInputChildren'
import { ConfirmPopupEdit } from '../../../components/ConfirmPopupEdit'
import { ConfirmPopupAvanca } from '../../../components/ConfirmPopupAvanca'
import { ConfirmPopupSetores } from '../../../components/ConfirmPopupSetores'
import { EditableTextInput } from '../../../components/EditableTextInput'
import { InputWithCheckbox } from '../../../components/InputWithCheckbox'
import { TextInput } from '../../../components/TextInput'
import { TextInputMask } from '../../../components/TextInputMask'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { CepService } from '../../../services/cep'
import { baseColors } from '../../../styles'


import './styles.scss'

const estadosBrasil = [
	{
		id: 12,
		sigla: 'AC',
		nome: 'Acre',
	},
	{
		id: 27,
		sigla: 'AL',
		nome: 'Alagoas',
	},
	{
		id: 16,
		sigla: 'AP',
		nome: 'Amapá',
	},
	{
		id: 13,
		sigla: 'AM',
		nome: 'Amazonas',
	},
	{
		id: 29,
		sigla: 'BA',
		nome: 'Bahia',
	},
	{
		id: 23,
		sigla: 'CE',
		nome: 'Ceará',
	},
	{
		id: 53,
		sigla: 'DF',
		nome: 'Distrito Federal',
	},
	{
		id: 32,
		sigla: 'ES',
		nome: 'Espírito Santo',
	},
	{
		id: 52,
		sigla: 'GO',
		nome: 'Goiás',
	},
	{
		id: 21,
		sigla: 'MA',
		nome: 'Maranhão',
	},
	{
		id: 51,
		sigla: 'MT',
		nome: 'Mato Grosso',
	},
	{
		id: 50,
		sigla: 'MS',
		nome: 'Mato Grosso do Sul',
	},
	{
		id: 31,
		sigla: 'MG',
		nome: 'Minas Gerais',
	},
	{
		id: 15,
		sigla: 'PA',
		nome: 'Pará',
	},
	{
		id: 25,
		sigla: 'PB',
		nome: 'Paraíba',
	},
	{
		id: 41,
		sigla: 'PR',
		nome: 'Paraná',
	},
	{
		id: 26,
		sigla: 'PE',
		nome: 'Pernambuco',
	},
	{
		id: 22,
		sigla: 'PI',
		nome: 'Piauí',
	},
	{
		id: 33,
		sigla: 'RJ',
		nome: 'Rio de Janeiro',
	},
	{
		id: 24,
		sigla: 'RN',
		nome: 'Rio Grande do Norte',
	},
	{
		id: 43,
		sigla: 'RS',
		nome: 'Rio Grande do Sul',
	},
	{
		id: 11,
		sigla: 'RO',
		nome: 'Rondônia',
	},
	{
		id: 14,
		sigla: 'RR',
		nome: 'Roraima',
	},
	{
		id: 42,
		sigla: 'SC',
		nome: 'Santa Catarina',
	},
	{
		id: 35,
		sigla: 'SP',
		nome: 'São Paulo',
	},
	{
		id: 28,
		sigla: 'SE',
		nome: 'Sergipe',
	},
	{
		id: 17,
		sigla: 'TO',
		nome: 'Tocantins',
	},
]

const validationSchema = yup.object({
	applicationTitle: yup
		.string()
		.required('É necessário preencher o título da candidatura para continuar.'),
	rg: yup
		.string()
		.min(5, 'RG inválido.')
		.required('É necessário preencher o RG para continuar.'),
	cpf: yup
		.string()
		.min(14, 'CPF inválido.')
		.max(14, 'CPF inválido.')
		.required('É necessário preencher um CPF válido para continuar.'),
	cep: yup
		.string()
		.min(8, 'CEP inválido.')
		.required('É necessário preencher um CEP válido para continuar.'),
	city: yup
		.string()
		.required('É necessário informar a cidade para continuar.'),
	address: yup
		.string()
		.required('É necessário informar o endereço para continuar.'),
	state: yup
		.string()
		.min(2, 'Estado inválido.')
		.max(2, 'Estado inválido.')
		.oneOf(estadosBrasil.map(estado => estado.sigla), 'Estado inválido.')
		.required('É necessário informar o Estado para continuar.'),
	maternalAffiliation: yup
		.string()
		.required('É necessário informar a filiação maternal para continuar.'),
	paternalAffiliation: yup
		.string()
		.required('É necessário informar a filiação paternal para continuar.'),
	howDidYouFindOutAboutUs: yup
		.string()
		.required('É necessário informar esse campo para continuar.'),
	motivationToParticipate: yup
		.string()
		.required('É necessário informar esse campo para continuar.'),
	shortCurriculum: yup
		.string()
		.required('É necessário informar esse campo para continuar.'),
	genero: yup
		.string()
		.required('É necessário informar o gênero para continuar.'),
})


export function PersonalData() {


	const [charCount, setCharCount] = useState(0)
	const navigate = useNavigate();
	const userContext = useAuth()

	const formik = useFormik({
		initialValues: {
			applicationTitle: '',
			applicationCoTitle: '',
			cpf: '',
			rg: '',
			genero: '',
			cep: '',
			city: '',
			state: '',
			address: '',
			addressComplement: '',
			maternalAffiliation: '',
			paternalAffiliation: '',
			howDidYouFindOutAboutUs: '',
			motivationToParticipate: '',
			shortCurriculum: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			userContext.updateUser({
				personalData: values
			})

			if (userPersonalData.phoneNumber === "") {
				console.log(userPersonalData.phoneNumber);
				alert("É necessário preencher o número de telefone.");
				window.scrollTo({ top: 0, behavior: "smooth" });
				return;
			  } else {
				window.scrollTo({ top: 0, behavior: "smooth" });

			navigate(routesNames.technologicalContributions.path)
			  }

			
		},
	})

	const [userPersonalData, setUserPersonalData] = useState<any>({})

	const [cep, setCep] = useState('')

	useEffect(() => {
		CepService.get(cep,
			(data) => {
				formik.setFieldValue('city', data.city)
				formik.setFieldValue('state', data.state)
				formik.setFieldValue('address', data.street)
			},
			() => { }
		)
	}, [cep])

	useEffect(() => {
		setCharCount(formik.values.shortCurriculum.length)
	}, [formik.values.shortCurriculum])


	useEffect(() => {
		if (userContext?.user?.personalData) {
			Object.keys(userContext?.user?.personalData || {}).forEach(key => {
				formik.setFieldValue(key, userContext?.user?.personalData[key])
			})
		}
		if (userContext?.user) {
			setUserPersonalData(userContext?.user)
		}
	}, [userContext?.user])


	function updateUserInfo(name: string, value: string) {
		userContext.updateUser({
			[name]: value
		})
	}


	const allRequiredFields = [
		"degreeOfInnovation",
		"relevanceOfContributions",
		"evidenceOfRecognition",
		"formOfRecognition",
		"technologyCompaniesCreated",
		"listOfTechnologyCompaniesCreated",
		"demonstrationOfCompaniesBenefited",
		"patentsGrantedByINPI",
		"patentsLicensed",
		"licensingOfPatentsToCompanies",
		"developmentOfProductsAndProcessesBeenPatented",
		"developedProductsOnMarket",
		"developedTechnologicalProcessesExecutedByCompanies",
		"activitiesInTechnologicalSystems",
		"consultancyToTechnologyCompanies",
		"mainAwardsWithImportance",
		"trainingHumanResourcesImpactedInnovation",
		"familiarityWithModernTechnologies",
		"mostRelevantTechnicalScientificPublication",
		"otherRelevantInformation"
	];


	return (
		<><div className='formContainerTecnologia'>
			<ConfirmPopupSetores />
			<h2 className='textDadospessoais'>Complete seus dados para concorrer ao prêmio CBMM.</h2>

			<div className="personalDataEditContainer">
				<ConfirmPopupEdit />
				<div className='bgDivDados'>
					<EditableTextInput
						label={'Nome'}
						value={userPersonalData.name}
						onChange={(value) => updateUserInfo('name', value)} />
				</div>
				<div className='bgDivDados'>
					<EditableTextInput
						label={'Email'}
						value={userPersonalData.email}
						onChange={(value) => updateUserInfo('email', value)} />
				</div>
				<div className='bgDivDados'>
					<EditableTextInput
						label={'Telefone'}
						value={userPersonalData.phoneNumber}
						onChange={(value) => updateUserInfo('phoneNumber', value)}
						mask="(##) # ####-####"
						definitions={{
							'#': /[0-9]/,
						}} />
				</div>
				<div className='bgDivDados'>
					<p className="personalDataEditRow">Categoria:
						<EditableTextInput
							select={['Ciência', 'Tecnologia']}
							label={''}
							value={userPersonalData.category}
							onChange={(value) => updateUserInfo('category', value)} />
					</p>
				</div>
			</div>

			<form onSubmit={formik.handleSubmit}>
				<span className='textDocuments'>Título da candidatura*:</span>
				<TextInputChildren
					fullWidth
					id="applicationTitle"
					name="applicationTitle"
					value={formik.values.applicationTitle}
					onChange={formik.handleChange}
					error={formik.touched.applicationTitle && Boolean(formik.errors.applicationTitle)}
					helperText={formik.touched.applicationTitle && formik.errors.applicationTitle} />

				<div className='infoTitular'>
					<span className='textDocuments'>Cotitulares da candidatura em grupo (se aplicável)*:</span>
					<span className='textDocuments'>Se houver mais de um cotitular, separar os nomes por vírgula.</span>
				</div>

				<TextInputChildren
					fullWidth
					id="applicationCoTitle"
					name="applicationCoTitle"
					value={formik.values.applicationCoTitle}
					onChange={formik.handleChange} />

				<span className='textDocuments'>CPF*:</span>
				<TextInputMask
					fullWidth
					id="cpf"
					name="cpf"
					placeholder='000.000.000-00'
					value={formik.values.cpf}
					onChange={formik.handleChange}
					error={formik.touched.cpf && Boolean(formik.errors.cpf)}
					helperText={formik.touched.cpf && formik.errors.cpf}
					mask="###.###.###-##"
					definitions={{
						'#': /[0-9]/,
					}} />

				<span className='textDocuments'>RG*:</span>
				<TextInputChildren
					fullWidth
					id="rg"
					name="rg"
					value={formik.values.rg}
					onChange={formik.handleChange}
					error={formik.touched.rg && Boolean(formik.errors.rg)}
					helperText={formik.touched.rg && formik.errors.rg} />


				<span className='textDocuments'>Gênero*:</span>
				<div className="formBlockEndereco">
					<FormControl className="textInput" fullWidth>
						<Select
							labelId="genero-label"
							name="genero"
							id="genero"
							value={formik.values.genero}
							onChange={formik.handleChange}
							error={formik.touched.genero && Boolean(formik.errors.genero)}
						>
							<MenuItem value={'Masculino'}>Masculino</MenuItem>
							<MenuItem value={'Feminino'}>Feminino</MenuItem>
							<MenuItem value={'Outro'}>Outro</MenuItem>
							<MenuItem value={'Prefiro não informar'}>Prefiro não informar</MenuItem>
						</Select>
						<FormHelperText>
							{formik.touched.genero && formik.errors.genero}
						</FormHelperText>
					</FormControl>
				</div>


				<span className='textDocuments'>CEP*:</span>
				<TextInputMask
					fullWidth
					id="cep"
					name="cep"
					placeholder='00000-000'
					value={formik.values.cep}
					onChange={(e: any) => {
						setCep(e.target.value)
						formik.handleChange(e)
					}}
					error={formik.touched.cep && Boolean(formik.errors.cep)}
					helperText={formik.touched.cep && formik.errors.cep}
					mask="#####-###"
					definitions={{
						'#': /[0-9]/,
					}} />

				<div className='divEndereco'>

					<div className="formTextBlock">
						<span className='textDocuments'>Cidade*:</span>
						<span className='textDocuments'>Estado*:</span>

					</div>

					<div className="formBlockEndereco">
						<TextInput
							fullWidth
							id="city"
							name="city"
							value={formik.values.city}
							onChange={formik.handleChange}
							error={formik.touched.city && Boolean(formik.errors.city)}
							helperText={formik.touched.city && formik.errors.city} />

						<FormControl className="textInput" fullWidth style={{ maxWidth: '27%' }}>

							<Select
								labelId="state-label"
								name="state"
								id="state"
								placeholder='Selecione'
								value={formik.values.state}
								onChange={formik.handleChange}
								error={formik.touched.state && Boolean(formik.errors.state)}
							>
								{estadosBrasil.map(estado => (
									<MenuItem key={estado.id} value={estado.sigla}>
										{estado.sigla}
									</MenuItem>
								))}
							</Select>
							<FormHelperText id="state-helper-text">
								{formik.touched.state && formik.errors.state}
							</FormHelperText>
						</FormControl>



					</div>



					<div className="formEndereço">
						<span className='textDocuments'>Endereço*:</span>
						<span className='textDocuments'>Complemento</span>

					</div>


					<div className="formBlockEndereco">
						<TextInput
							fullWidth
							id="address"
							name="address"
							value={formik.values.address}
							onChange={formik.handleChange}
							error={formik.touched.address && Boolean(formik.errors.address)}
							helperText={formik.touched.address && formik.errors.address} />

						<TextInput
							style={{ maxWidth: '27%' }}
							id="addressComplement"
							name="addressComplement"
							value={formik.values.addressComplement}
							onChange={formik.handleChange}
							error={formik.touched.addressComplement && Boolean(formik.errors.addressComplement)}
							helperText={formik.touched.addressComplement && formik.errors.addressComplement} />
					</div>

				</div>

				<span className='textDocuments'>Filiação:</span>

				<div className='formBlockFiliacao'>
					<InputWithCheckbox
						id="maternalAffiliation"
						name="maternalAffiliation"
						checkboxLabel="Não consta"
						placeholder='Nome da mãe'
						value={formik.values.maternalAffiliation}
						onChange={formik.handleChange}
						error={formik.touched.maternalAffiliation && Boolean(formik.errors.maternalAffiliation)}
						helperText={formik.touched.maternalAffiliation && formik.errors.maternalAffiliation} />
				</div>

				<br />

				<div className='formBlockFiliacao'>

					<InputWithCheckbox
						id="paternalAffiliation"
						name="paternalAffiliation"
						checkboxLabel="Não consta"
						placeholder='Nome do pai'
						value={formik.values.paternalAffiliation}
						onChange={formik.handleChange}
						error={formik.touched.paternalAffiliation && Boolean(formik.errors.paternalAffiliation)}
						helperText={formik.touched.paternalAffiliation && formik.errors.paternalAffiliation} />

				</div>



				<FormControl className="textInput" fullWidth>
					<span className='textMotivos'>
						Como você ficou sabendo do Prêmio CBMM?*
					</span>
					<div className="formBlockFiliacao">
						<Select
							labelId="how-did-you-know-label"
							name="howDidYouFindOutAboutUs"
							id="howDidYouFindOutAboutUs"
							value={formik.values.howDidYouFindOutAboutUs}
							onChange={formik.handleChange}
							error={formik.touched.howDidYouFindOutAboutUs &&
								Boolean(formik.errors.howDidYouFindOutAboutUs)}
						>
							<MenuItem value={'Google'}>Google</MenuItem>
							<MenuItem value={'Facebook'}>Facebook</MenuItem>
							<MenuItem value={'Instagram'}>Instagram</MenuItem>
							<MenuItem value={'LinkdedIn'}>LinkdedIn</MenuItem>
							<MenuItem value={'Site CBMM'}>Site CBMM</MenuItem>
							<MenuItem value={'Indicação'}>Indicação</MenuItem>
							<MenuItem value={'Revista Pesquisa Fapesp'}>Revista Pesquisa Fapesp</MenuItem>
							<MenuItem value={'Universidade'}>Universidade</MenuItem>
							<MenuItem value={'Outro'}>Outro</MenuItem>
						</Select>
					</div>
					<FormHelperText id="how-did-you-know-helper-text">
						{formik.touched.howDidYouFindOutAboutUs && formik.errors.howDidYouFindOutAboutUs}
					</FormHelperText>
				</FormControl>


				<span className='textMotivos'>O que te motivou a participar?*</span>
				<TextInputChildren
					fullWidth
					id="motivationToParticipate"
					name="motivationToParticipate"
					value={formik.values.motivationToParticipate}
					onChange={formik.handleChange}
					error={formik.touched.motivationToParticipate && Boolean(formik.errors.motivationToParticipate)}
					helperText={formik.touched.motivationToParticipate && formik.errors.motivationToParticipate} />



				<FormLabel
					className="formLabel textMotivos"
					style={{ marginTop: -20 }}
				>
					Descreva em uma (1) página, suas descobertas científicas mais relevantes*:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="shortCurriculum"
						placeholder='Limite máximo de 3000 caracteres.'
						name="shortCurriculum"
						value={formik.values.shortCurriculum}
						onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
							formik.handleChange(e)
							setCharCount(e.target.value.length)
						}}
						error={formik.touched.shortCurriculum && Boolean(formik.errors.shortCurriculum)}
						helperText={formik.touched.shortCurriculum && formik.errors.shortCurriculum}
						multiline
						minRows={5}
						maxRows={8}
						inputProps={{ maxLength: 3000 }} />
				</div>

				<div className='divCont'>
					<div className=''>
						<span style={{
							color: '#991A1A',
							fontFamily: "Open Sans",
							fontSize: 15,
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '130%',
						}}>
							*Preenchimento<br /> obrigatório
						</span>
					</div>
					<div className=''>
						{charCount}/3000
					</div>
				</div>

				<div className='row-gap last'>
					<ConfirmPopupAvanca />
					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,
						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />
				</div>



			</form>

		</div>
		<div className='divProgress'>
				<ProgressBarTecnology
					userData={
						userContext?.user?.ideias?.[new Date().getFullYear().toString()] || {}
					}
					requiredFields={allRequiredFields} />
			</div></>
	)
}
