import { memo, useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { getStorage, ref, getDownloadURL, listAll, uploadBytes } from "firebase/storage";
import "./styles.scss";

interface InputFileProps {
  label?: string;
  name?: string;
  value?: string;
  onChange?: (event: any) => void;
  error?: any;
  helperText?: any;
  disabled?: boolean;
  mode?: "upload" | "download";
}

export const InputFile = memo(function InputFile({
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  disabled,
  mode = "upload",
}: InputFileProps) {
  const [fileData, setFileData] = useState("");
  const [fileUrls, setFileUrls] = useState<{ url: string; name: string }[]>([]);
  const userContext = useAuth();
  const storage = getStorage();

  const htmlElementId = `fileInputId_${name}`;

  // ✅ Upload corrigido para armazenar o nome real do arquivo
  async function handleChange(event: any) {
    if (!userContext?.user?.uid) return;

    const files = event.target.files;
    const selectedFile = files[0];

    if (!selectedFile) return;

    setFileData("Carregando...");

    try {
      const filePath = `uploads/${userContext.user.uid}/${name}`; // Mantém o nome original do arquivo
      const fileRef = ref(storage, filePath);

      await uploadBytes(fileRef, selectedFile);
      setFileData("Envio completo.");

      // Atualiza o estado do componente pai para refletir o nome real do arquivo
      onChange?.({
        target: {
          value: selectedFile.name,
          id: name,
        },
      });

      setTimeout(() => setFileData(""), 2000);
    } catch (error) {
      console.error("Erro no upload:", error);
      setFileData("Houve um erro, tente novamente!");
    }
  }

  // ✅ Download corrigido para exibir o nome correto dos arquivos
  useEffect(() => {
    if (!userContext?.user?.uid || !name) return;

    const uid = userContext.user.uid;
    const folderRef = ref(storage, `uploads/${uid}`);

    const fetchFiles = async () => {
      try {
        const fileList = await listAll(folderRef);
        const urls = await Promise.all(
          fileList.items
            .filter((item) => item.name.startsWith(name)) // Garante que os arquivos corretos sejam listados
            .map(async (item) => {
              const fileUrl = await getDownloadURL(item);
              return { url: fileUrl, name: item.name.replace(`${name}-`, "") }; // Remove o prefixo para exibir o nome real
            })
        );

        setFileUrls(urls);
      } catch (error) {
        console.error("Erro ao listar arquivos:", error);
      }
    };

    fetchFiles();
  }, [userContext?.user?.uid, name]);

  return (
    <>
      <label htmlFor={htmlElementId} className={`fileInputContainer${error ? " error" : ""}`}>
        {value && <label htmlFor={htmlElementId} className="fileLabelTitle">{label}</label>}

        {mode === "upload" ? (
          <>
            <label htmlFor={htmlElementId} className="fileLabel">{value ? value : label}</label>
            <label htmlFor={htmlElementId} className="fileButton">UPLOAD</label>
            <input
              id={htmlElementId}
              className="pictureInput"
              type="file"
              accept=".pdf"
              onChange={handleChange}
              style={{ display: "none" }}
              disabled={disabled}
            />
          </>
        ) : (
          <div className="downloadContainer">
            {fileUrls.length > 0 ? (
              fileUrls.map(({ url, name }, index) => (
                <div key={index} className="downloadItem">
                  <span className="fileName">{value ? value : label}</span>
                  <a href={url} target="_blank" rel="noopener noreferrer" className="fileButton">
                    DOWNLOAD
                  </a>
                </div>
              ))
            ) : (
              <div className="noFilesContainer">
                <span className="noFiles">Nenhum arquivo disponível para download.</span>
              </div>
            )}
          </div>
        )}
      </label>

      {fileData && <p>{fileData}</p>}
      {helperText && <p className="errorHelperText">{helperText}</p>}
    </>
  );
});
