
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../../components/Button'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { CepService } from '../../../services/cep'
import { baseColors } from '../../../styles'


import './styles.scss'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'

const validationSchema = yup.object({
	scienceAcademiesWhichYouAreMember: yup
		.string(),
	listOfMostRelevantAwardsWithDescription: yup
		.string(),
	listPlenaryAndKeynoteTalks: yup
		.string(),
	listCurrentPositionsEditorshipInJournals: yup
		.string(),
	listCongressesOrganizesAsChairman: yup
		.string(),
	listLeadershipPositionsScientificSocieties: yup
		.string(),
})


export function EvidenceOfRecognition() {
	const navigate = useNavigate();
	const userContext = useAuth()
	const formik = useFormik({
		initialValues: {
		  scienceAcademiesWhichYouAreMember: '',
		  listOfMostRelevantAwardsWithDescription: '',
		  listPlenaryAndKeynoteTalks: '',
		  listCurrentPositionsEditorshipInJournals: '',
		  listCongressesOrganizesAsChairman: '',
		  listLeadershipPositionsScientificSocieties: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
		  const anoAtual = new Date().getFullYear().toString();
	  
		  // Recupera dados existentes do ano atual
		  const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};
	  
		  // Atualiza apenas o campo necessário, mantendo os demais
		  userContext.updateUser({
			ideias: {
			  ...userContext.user.ideias,
			  [anoAtual]: {
				...dadosExistentes,
				evidenceOfRecognition: values,
			  },
			},
		  });
	  
		  window.scrollTo({ top: 0, behavior: 'smooth' });
		  navigate(routesNames.bibliometricInformation.path);
		},
	  });


	  useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};

		const bloco2025 = ideias[anoAtual]?.evidenceOfRecognition;
		const bloco2024 = userContext?.user?.evidenceOfRecognition1;
		const bloco2023 = userContext?.user?.evidenceOfRecognition;
	  
		let dataToLoad: Record<string, any> | null = null;
	  
		if (bloco2025 && typeof bloco2025 === 'object' && Object.keys(bloco2025).length > 0) {
			dataToLoad = bloco2025;
		} else if (bloco2024 && typeof bloco2024 === 'object' && Object.keys(bloco2024).length > 0) {
			dataToLoad = bloco2024;
		} else if (bloco2023 && typeof bloco2023 === 'object' && Object.keys(bloco2023).length > 0) {
			dataToLoad = bloco2023;
		}
	  
		if (dataToLoad) {
		  Object.entries(dataToLoad).forEach(([key, value]) => {
			formik.setFieldValue(key, value);
		  });
		}
	  }, [userContext?.user]);

	const allRequiredFields = [
		"mostImportantArticlesInYourResearch",
		"mostCitedArticlesAndCitationNumbers",
		"publishedBookChaptersAndArticles",
		"listPublishedBooks",
		"patentsGrantedByINPI",

		"scientificArticlesPublishedIndexedByScopus",
		"totalCitations",
		"totalScientificInitiationStudentsSupervised",
		"hIndexScopusScore",
		"leadershipIndex",

		"scienceAcademiesWhichYouAreMember",
		"listOfMostRelevantAwardsWithDescription",
		"listPlenaryAndKeynoteTalks",
		"listCurrentPositionsEditorshipInJournals",
		"listCongressesOrganizesAsChairman",
		"listLeadershipPositionsScientificSocieties",

		"otherRelevantInformation",
	];


	return (
		<><div className='formContainerCiencia'>
			<h2 className='textDadospessoais'>Mostre que a comunidade científica reconhece o seu trabalho.</h2>

			<form onSubmit={formik.handleSubmit}>

				{/* <Button
        label="Salvar e sair"
        type="outline"
        style={{ position: 'absolute', top: 0, right: 0 }}
        onClick={() => {
            setTimeout(() => {
                userContext?.signOutUser()
            }, 200);
        }}
    /> */}

				<FormLabel className="textScienticProd formLabel">
					Liste as academias de Ciência nas quais é membro:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="scienceAcademiesWhichYouAreMember"
						name="scienceAcademiesWhichYouAreMember"
						type="number"
						value={formik.values.scienceAcademiesWhichYouAreMember}
						onChange={formik.handleChange}
						error={formik.touched.scienceAcademiesWhichYouAreMember && Boolean(formik.errors.scienceAcademiesWhichYouAreMember)}
						helperText={formik.touched.scienceAcademiesWhichYouAreMember && formik.errors.scienceAcademiesWhichYouAreMember}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">
					Liste seus prêmios nacionais e internacionais mais relevantes, descrevendo brevemente a importância de cada um:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listOfMostRelevantAwardsWithDescription"
						name="listOfMostRelevantAwardsWithDescription"
						value={formik.values.listOfMostRelevantAwardsWithDescription}
						onChange={formik.handleChange}
						error={formik.touched.listOfMostRelevantAwardsWithDescription && Boolean(formik.errors.listOfMostRelevantAwardsWithDescription)}
						helperText={formik.touched.listOfMostRelevantAwardsWithDescription && formik.errors.listOfMostRelevantAwardsWithDescription}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">
					Liste suas plenary e keynote talks em eventos no Brasil e no exterior:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listPlenaryAndKeynoteTalks"
						name="listPlenaryAndKeynoteTalks"
						value={formik.values.listPlenaryAndKeynoteTalks}
						onChange={formik.handleChange}
						error={formik.touched.listPlenaryAndKeynoteTalks && Boolean(formik.errors.listPlenaryAndKeynoteTalks)}
						helperText={formik.touched.listPlenaryAndKeynoteTalks && formik.errors.listPlenaryAndKeynoteTalks}
						multiline
						minRows={4}
						maxRows={7} />

				</div>
				<FormLabel className="textScienticProd formLabel">
					Liste as posições atuais e anteriores na editoria de revistas científicas:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listCurrentPositionsEditorshipInJournals"
						name="listCurrentPositionsEditorshipInJournals"
						value={formik.values.listCurrentPositionsEditorshipInJournals}
						onChange={formik.handleChange}
						error={formik.touched.listCurrentPositionsEditorshipInJournals && Boolean(formik.errors.listCurrentPositionsEditorshipInJournals)}
						helperText={formik.touched.listCurrentPositionsEditorshipInJournals && formik.errors.listCurrentPositionsEditorshipInJournals}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">
					Liste os congressos nacionais e internacionais organizados como chairman:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listCongressesOrganizesAsChairman"
						name="listCongressesOrganizesAsChairman"
						multiline
						value={formik.values.listCongressesOrganizesAsChairman}
						onChange={formik.handleChange}
						error={formik.touched.listCongressesOrganizesAsChairman && Boolean(formik.errors.listCongressesOrganizesAsChairman)}
						helperText={formik.touched.listCongressesOrganizesAsChairman && formik.errors.listCongressesOrganizesAsChairman}
						minRows={4}
						maxRows={7} />
				</div>

				<FormLabel className="textScienticProd formLabel">
					Liste as posições de liderança atuais e passadas em sociedades<br /> e instituições científicas do Brasil e internacionais:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listLeadershipPositionsScientificSocieties"
						name="listLeadershipPositionsScientificSocieties"
						value={formik.values.listLeadershipPositionsScientificSocieties}
						onChange={formik.handleChange}
						error={formik.touched.listLeadershipPositionsScientificSocieties && Boolean(formik.errors.listLeadershipPositionsScientificSocieties)}
						helperText={formik.touched.listLeadershipPositionsScientificSocieties && formik.errors.listLeadershipPositionsScientificSocieties}
						multiline
						minRows={4}
						maxRows={7} />
				</div>

				<div className='row-gap last'>
					<ButtonStages
						type="outline"
						label="SALVAR E SAIR"
						color={baseColors.lightIndigo}
						onClick={() => {
							setTimeout(() => {
								userContext?.signOutUser()
							}, 200)
						}} />

					<ButtonStagesBack
						type="outline"
						label="VOLTAR"
						color={baseColors.lightIndigo}
						typeSubmit
						onClick={() => navigate(-1)} />

					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,
						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />

					{/* <Button
        label="Salvar e avançar"
        color={baseColors.darkBlue}
    // style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
    /> */}
				</div>

				<ConfirmPopupBack />
				<ConfirmPopupSaveback />

			</form>

		</div><div className='divProgress'>

				<ProgressBar
					userData={
						userContext?.user?.ideias?.[new Date().getFullYear().toString()] || {}
					}
					requiredFields={allRequiredFields} />

			</div></>
	)
}
