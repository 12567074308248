
import { FormLabel } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { Button } from '../../../components/Button'
import { ConfirmPopup } from '../../../components/ConfirmPopup'
import { InputFile } from '../../../components/InputFile'
import { TextInput } from '../../../components/TextInput'
import { TextInputRevision } from '../../../components/TextInputRevision'
import { useAuth } from '../../../hooks/useAuth'

import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'


import './styles.scss'
import { User } from '../../../core/Controllers/User'
import axios from 'axios'


export function ScienceRevision() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const [currentDate, setCurrentDate] = useState(new Date().toISOString());

	const formik = useFormik({
		initialValues: {
			mostImportantArticlesInYourResearch: '',
			mostCitedArticlesAndCitationNumbers: '',
			publishedBookChaptersAndArticles: '',
			listPublishedBooks: '',
			patentsGrantedByINPI: '',


			scienceAcademiesWhichYouAreMember: '',
			listOfMostRelevantAwardsWithDescription: '',
			listPlenaryAndKeynoteTalks: '',
			listCurrentPositionsEditorshipInJournals: '',
			listCongressesOrganizesAsChairman: '',
			listLeadershipPositionsScientificSocieties: '',


			scientificArticlesPublishedIndexedByScopus: '',
			totalCitations: '',
			totalScientificInitiationStudentsSupervised: '',
			hIndexScopusScore: '',
			leadershipIndex: '',


			otherRelevantInformation: '',
			recommendationLetters: [],
			curriculum: ''
		},
		onSubmit: (values) => {



			// e12a3579e02ba536d2b58bf8f12d66dd-us7

			let axiosConfig = {
				headers: {
					'authorization': "Basic e12a3579e02ba536d2b58bf8f12d66dd-us7",
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				}
			};

			let data = {
				email_address: 'uriel@lcomunicacao.com',
				status: "subscribed"
			};





			let urls = [

				'https://api.lcomunicacao.com/index.php?email=' + userContext.user.email + '&tag=2025-Completou cadastro&phone=' + userContext.user.phoneNumber + '&nome=' + userContext.user.name + '&atualizar=1',
			]

			// 'https://cbmm.lcomunicacao.com/?' + candidate_1.email_1 + '&tag=INSTITUIÇÕES_2024'


			const requests = urls.map((url) => axios.get(url));


			axios.all(requests)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				})


			const obj = Object.entries(values)
			let result = 0;
			obj.forEach((item) => {
				if (item[1].length > 0) {
					result++;
				}
			});

			// console.log('values ', JSON.stringify(values, null, 2))

			if (result < 14) {
				console.log(result)
				alert('É necessário preencher pelo menos 70% do formulário para continuar.');
			} else {


				const anoAtual = new Date().getFullYear().toString();
				const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};

				userContext.updateUser({
				  ideias: {
					...userContext.user.ideias,
					[anoAtual]: {
					  ...dadosExistentes,
					  completed: true, // marca a ideia do ano atual como finalizada
					},
				  },
				});

				navigate(routesNames.thankYou.path)
			}



		},
	})


	useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};
	  
		// Lista de blocos de dados que queremos recuperar
		const blocos = [
		  'scientificProduction',
		  'evidenceOfRecognition',
		  'bibliometricInformation',
		  'extraData',
		];
	  
		// Aplica preenchimento por bloco (prioriza ideias[anoAtual], depois *_1, depois o antigo)
		blocos.forEach((bloco) => {
		  const currentData = ideias[anoAtual]?.[bloco];
		  const fallbackData =
			userContext?.user?.[`${bloco}1`] ||
			userContext?.user?.[bloco] ||
			null;
	  
		  const dataToLoad = currentData || fallbackData;
	  
		  if (dataToLoad && typeof dataToLoad === 'object' && !Array.isArray(dataToLoad)) {
			Object.entries(dataToLoad).forEach(([key, value]) => {
			  formik.setFieldValue(key, value);
			});
		  }
		});
	  
		if (userContext?.user?.createdAt) {
		  setCurrentDate(userContext.user.createdAt);
		}
	  }, [userContext?.user]);


	return (
		<div className='formContainerCiencia'>
			<h2 className='textDadospessoais'>Revise todas as respostas antes de enviar.</h2>

			<form onSubmit={formik.handleSubmit}>

				<FormLabel className="textScienticProd formLabel">
					Liste os 10 artigos de maior importância na sua área de pesquisa pulicado em revistas:
				</FormLabel>
				<TextInputRevision
					fullWidth
					id="mostImportantArticlesInYourResearch"
					name="mostImportantArticlesInYourResearch"
					multiline={true}
					value={formik.values.mostImportantArticlesInYourResearch}
					onChange={formik.handleChange}
					error={formik.touched.mostImportantArticlesInYourResearch && Boolean(formik.errors.mostImportantArticlesInYourResearch)}
					helperText={formik.touched.mostImportantArticlesInYourResearch && formik.errors.mostImportantArticlesInYourResearch}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">
					Liste os seus 10 artigos mais citados e os respectivos números de citações:
				</FormLabel>
				<TextInput
					fullWidth
					id="mostCitedArticlesAndCitationNumbers"
					name="mostCitedArticlesAndCitationNumbers"
					multiline={true}
					value={formik.values.mostCitedArticlesAndCitationNumbers}
					onChange={formik.handleChange}
					error={formik.touched.mostCitedArticlesAndCitationNumbers && Boolean(formik.errors.mostCitedArticlesAndCitationNumbers)}
					helperText={formik.touched.mostCitedArticlesAndCitationNumbers && formik.errors.mostCitedArticlesAndCitationNumbers}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">
					Liste os capítulos de livros e artigos publicados a convite de editores de revistas:
				</FormLabel>
				<TextInput
					fullWidth
					id="publishedBookChaptersAndArticles"
					name="publishedBookChaptersAndArticles"
					multiline={true}
					value={formik.values.publishedBookChaptersAndArticles}
					onChange={formik.handleChange}
					error={formik.touched.publishedBookChaptersAndArticles && Boolean(formik.errors.publishedBookChaptersAndArticles)}
					helperText={formik.touched.publishedBookChaptersAndArticles && formik.errors.publishedBookChaptersAndArticles}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">
					Liste os livros publicados:
				</FormLabel>
				<TextInput
					fullWidth
					id="listPublishedBooks"
					name="listPublishedBooks"
					multiline={true}
					value={formik.values.listPublishedBooks}
					onChange={formik.handleChange}
					error={formik.touched.listPublishedBooks && Boolean(formik.errors.listPublishedBooks)}
					helperText={formik.touched.listPublishedBooks && formik.errors.listPublishedBooks}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">
					Liste as patentes concedidas pelo INPI e instituições estrangeiras:
				</FormLabel>
				<TextInput
					fullWidth
					id="patentsGrantedByINPI"
					name="patentsGrantedByINPI"
					multiline={true}
					value={formik.values.patentsGrantedByINPI}
					onChange={formik.handleChange}
					error={formik.touched.patentsGrantedByINPI && Boolean(formik.errors.patentsGrantedByINPI)}
					helperText={formik.touched.patentsGrantedByINPI && formik.errors.patentsGrantedByINPI}

					minRows={4}
					maxRows={7}
					disabled
				/>




				<FormLabel className="textScienticProd formLabel">
					Liste as academias de Ciência nas quais é membro:
				</FormLabel>
				<TextInput
					fullWidth
					id="scienceAcademiesWhichYouAreMember"
					name="scienceAcademiesWhichYouAreMember"
					multiline={true}
					value={formik.values.scienceAcademiesWhichYouAreMember}
					onChange={formik.handleChange}
					error={formik.touched.scienceAcademiesWhichYouAreMember && Boolean(formik.errors.scienceAcademiesWhichYouAreMember)}
					helperText={formik.touched.scienceAcademiesWhichYouAreMember && formik.errors.scienceAcademiesWhichYouAreMember}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Liste seus prêmios nacionais e internacionais mais relevantes, descrevendo brevemente a importância de cada um:
				</FormLabel>
				<TextInput
					fullWidth
					id="listOfMostRelevantAwardsWithDescription"
					name="listOfMostRelevantAwardsWithDescription"
					multiline={true}
					value={formik.values.listOfMostRelevantAwardsWithDescription}
					onChange={formik.handleChange}
					error={formik.touched.listOfMostRelevantAwardsWithDescription && Boolean(formik.errors.listOfMostRelevantAwardsWithDescription)}
					helperText={formik.touched.listOfMostRelevantAwardsWithDescription && formik.errors.listOfMostRelevantAwardsWithDescription}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Liste suas plenary e keynote talks em eventos no Brasil e no exterior:
				</FormLabel>
				<TextInput
					fullWidth
					id="listPlenaryAndKeynoteTalks"
					name="listPlenaryAndKeynoteTalks"
					multiline={true}
					value={formik.values.listPlenaryAndKeynoteTalks}
					onChange={formik.handleChange}
					error={formik.touched.listPlenaryAndKeynoteTalks && Boolean(formik.errors.listPlenaryAndKeynoteTalks)}
					helperText={formik.touched.listPlenaryAndKeynoteTalks && formik.errors.listPlenaryAndKeynoteTalks}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Liste as posições atuais e anteriores na editoria de revistas científicas:
				</FormLabel>
				<TextInput
					fullWidth
					id="listCurrentPositionsEditorshipInJournals"
					name="listCurrentPositionsEditorshipInJournals"
					multiline={true}
					value={formik.values.listCurrentPositionsEditorshipInJournals}
					onChange={formik.handleChange}
					error={formik.touched.listCurrentPositionsEditorshipInJournals && Boolean(formik.errors.listCurrentPositionsEditorshipInJournals)}
					helperText={formik.touched.listCurrentPositionsEditorshipInJournals && formik.errors.listCurrentPositionsEditorshipInJournals}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Liste os congressos nacionais e internacionais organizados como chairman:
				</FormLabel>
				<TextInput
					fullWidth
					id="listCongressesOrganizesAsChairman"
					name="listCongressesOrganizesAsChairman"
					multiline={true}
					value={formik.values.listCongressesOrganizesAsChairman}
					onChange={formik.handleChange}
					error={formik.touched.listCongressesOrganizesAsChairman && Boolean(formik.errors.listCongressesOrganizesAsChairman)}
					helperText={formik.touched.listCongressesOrganizesAsChairman && formik.errors.listCongressesOrganizesAsChairman}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Liste as posições de liderança atuais e passadas em sociedades e instituições científicas do Brasil e internacionais:
				</FormLabel>
				<TextInput
					fullWidth
					id="listLeadershipPositionsScientificSocieties"
					name="listLeadershipPositionsScientificSocieties"
					multiline={true}
					value={formik.values.listLeadershipPositionsScientificSocieties}
					onChange={formik.handleChange}
					error={formik.touched.listLeadershipPositionsScientificSocieties && Boolean(formik.errors.listLeadershipPositionsScientificSocieties)}
					helperText={formik.touched.listLeadershipPositionsScientificSocieties && formik.errors.listLeadershipPositionsScientificSocieties}

					minRows={4}
					maxRows={7}
					disabled
				/>



				<FormLabel className="textScienticProd formLabel">
					Número de artigos científicos publicados em periódicos com peer review indexados pelo Scopus:
				</FormLabel>
				<TextInput
					fullWidth
					id="scientificArticlesPublishedIndexedByScopus"
					name="scientificArticlesPublishedIndexedByScopus"
					multiline={true}
					value={formik.values.scientificArticlesPublishedIndexedByScopus}
					onChange={formik.handleChange}
					error={formik.touched.scientificArticlesPublishedIndexedByScopus && Boolean(formik.errors.scientificArticlesPublishedIndexedByScopus)}
					helperText={formik.touched.scientificArticlesPublishedIndexedByScopus && formik.errors.scientificArticlesPublishedIndexedByScopus}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">Tem quantas citações (Scopus)?</FormLabel>
				<TextInput
					fullWidth
					id="totalCitations"
					name="totalCitations"
					multiline={true}
					value={formik.values.totalCitations}
					onChange={formik.handleChange}
					error={formik.touched.totalCitations && Boolean(formik.errors.totalCitations)}
					helperText={formik.touched.totalCitations && formik.errors.totalCitations}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">Quantos alunos de iniciação científica, mestrado, doutorado e pós-doutorado já orientou?</FormLabel>
				<TextInput
					fullWidth
					id="totalScientificInitiationStudentsSupervised"
					name="totalScientificInitiationStudentsSupervised"
					multiline={true}
					value={formik.values.totalScientificInitiationStudentsSupervised}
					onChange={formik.handleChange}
					error={formik.touched.totalScientificInitiationStudentsSupervised && Boolean(formik.errors.totalScientificInitiationStudentsSupervised)}
					helperText={formik.touched.totalScientificInitiationStudentsSupervised && formik.errors.totalScientificInitiationStudentsSupervised}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Qual é o seu H-index (Scopus)? Compare com seus pares do Brasil e exterior.
				</FormLabel>
				<TextInput
					fullWidth
					id="hIndexScopusScore"
					name="hIndexScopusScore"
					multiline={true}
					value={formik.values.hIndexScopusScore}
					onChange={formik.handleChange}
					error={formik.touched.hIndexScopusScore && Boolean(formik.errors.hIndexScopusScore)}
					helperText={formik.touched.hIndexScopusScore && formik.errors.hIndexScopusScore}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">
					Qual é o seu Leadership Index (LI)? LI é a razão entre o número de artigos nos quais o seu H-index é maior que dos demais coautores e o número total de (seus) artigos em periódicos indexados pelo Scopus (LI {"<="}1).
				</FormLabel>
				<TextInput
					fullWidth
					id="leadershipIndex"
					name="leadershipIndex"
					multiline={true}
					value={formik.values.leadershipIndex}
					onChange={formik.handleChange}
					error={formik.touched.leadershipIndex && Boolean(formik.errors.leadershipIndex)}
					helperText={formik.touched.leadershipIndex && formik.errors.leadershipIndex}

					minRows={4}
					maxRows={7}
					disabled
				/>



				<FormLabel className="textScienticProd formLabel">Descreva outras informações relevantes:</FormLabel>
				<TextInput
					fullWidth
					id="otherRelevantInformation"
					name="otherRelevantInformation"
					multiline={true}
					value={formik.values.otherRelevantInformation}
					onChange={formik.handleChange}
					error={formik.touched.otherRelevantInformation && Boolean(formik.errors.otherRelevantInformation)}
					helperText={formik.touched.otherRelevantInformation && formik.errors.otherRelevantInformation}

					minRows={4}
					maxRows={7}
					disabled
				/>
				{


					typeof formik.values.recommendationLetters === 'string' ? (
						<InputFile
							label="Cartas de recomendação:*"
							name="recommendationLetters"
							value={formik.values.recommendationLetters}
							disabled
						/>
					) : (
						formik.values.recommendationLetters.map((recommendationLetter, index) => (
							<InputFile
								label={`Carta de recomendação (${index + 1}):*`}
								name={`recommendationLetters.${index}`}
								value={recommendationLetter}
								mode="download"
								disabled
							/>
						))
					)
				}

				<InputFile
					label="Currículo Vitae Lattes:*"
					name="curriculum"
					value={formik.values.curriculum}
					onChange={formik.handleChange}
					mode="download"
					error={formik.touched.curriculum && Boolean(formik.errors.curriculum)}
					helperText={formik.touched.curriculum && formik.errors.curriculum}
					disabled
				/>


				<div className='row-gap last' style={{ marginTop: 80 }}>
					<ConfirmPopup />

					<ButtonStagesBack
						type="outline"
						label="VOLTAR E EDITAR"
						color={baseColors.darkBlue}
						typeSubmit={false}
						onClick={() => navigate(-1)}
					/>

					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,

						}}

						label="SUBMETER"
						color={baseColors.lightIndigo} />
				</div>

			</form>

		</div>
	)
}
