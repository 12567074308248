import { useState, useEffect } from "react";
import "./ProgressBarTecnology.scss";

interface ProgressBarTecnologyProps {
  userData: Record<string, any>; // Agora espera user.ideias[anoAtual] diretamente
  requiredFields: string[];
}

export function ProgressBarTecnology({ userData, requiredFields }: ProgressBarTecnologyProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Juntar os dados que interessam dentro de userData
    const allData = {
      ...userData.technologicalContributions,
      ...userData.technologicalContributions2,
      ...userData.additionalInformation,
    };

    const filledFields = requiredFields.filter((field) => {
      const value = allData[field];
      return (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        (typeof value !== "object" || Object.keys(value).length > 0)
      );
    });

    const percentage =
      filledFields.length > 0
        ? Math.round((filledFields.length / requiredFields.length) * 100)
        : 0;

    setProgress(percentage);
  }, [userData, requiredFields]);

  return (
    <div className="progress-container">
      <h3 className="progress-title">Progresso de Preenchimento</h3>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}>
          <span className="progress-text">{progress}%</span>
        </div>
      </div>
      <p className="progress-info">
        *É necessário preencher pelo menos <strong>70%</strong> do formulário para continuar.
      </p>
    </div>
  );
}
