import { FormLabel } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { Button } from '../../../components/Button'
import { ConfirmPopup } from '../../../components/ConfirmPopup'
import { InputFile } from '../../../components/InputFile'
import { TextInput } from '../../../components/TextInput'
import { TextInputRevision } from '../../../components/TextInputRevision'
import { useAuth } from '../../../hooks/useAuth'

import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'


import './styles.scss'
import { User } from '../../../core/Controllers/User'
import axios from 'axios'




export function Revision() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const formik = useFormik({
		initialValues: {
			degreeOfInnovation: '',
			relevanceOfContributions: '',
			evidenceOfRecognition: '',
			formOfRecognition: '',

			technologyCompaniesCreated: '',
			listOfTechnologyCompaniesCreated: '',
			demonstrationOfCompaniesBenefited: '',
			patentsGrantedByINPI: '',
			patentsLicensed: '',
			licensingOfPatentsToCompanies: '',
			developmentOfProductsAndProcessesBeenPatented: '',
			developedProductsOnMarket: '',
			developedTechnologicalProcessesExecutedByCompanies: '',

			activitiesInTechnologicalSystems: '',
			consultancyToTechnologyCompanies: '',
			mainAwardsWithImportance: '',
			trainingHumanResourcesImpactedInnovation: '',
			familiarityWithModernTechnologies: '',
			mostRelevantTechnicalScientificPublication: '',
			otherRelevantInformation: '',
			recommendationLetters: [],
			curriculum: ''
		},
		onSubmit: (values) => {


			// e12a3579e02ba536d2b58bf8f12d66dd-us7

			let axiosConfig = {
				headers: {
					'authorization': "Basic e12a3579e02ba536d2b58bf8f12d66dd-us7",
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				}
			};

			let data = {
				email_address: 'uriel@lcomunicacao.com',
				status: "subscribed"
			};





			let urls = [

				'https://api.lcomunicacao.com/index.php?email=' + userContext.user.email + '&tag=2025-Completou cadastro&phone=' + userContext.user.phoneNumber + '&nome=' + userContext.user.name + '&atualizar=1',
			]



			// 'https://cbmm.lcomunicacao.com/?' + candidate_1.email_1 + '&tag=INSTITUIÇÕES_2024'


			const requests = urls.map((url) => axios.get(url));

			console.log(requests);

			axios.all(requests)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				})



			const obj = Object.entries(values)
			let result = 0;
			obj.forEach((item) => {

				if (item[1].length > 0) {
					result++;
				}

			});



			// console.log('values ', JSON.stringify(values, null, 2))

			if (result < 16) {
				console.log(result)
				alert('É necessário preencher pelo menos 70% do formulário para continuar.');
				return;
			} else {


				const anoAtual = new Date().getFullYear().toString();
				const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};

				userContext.updateUser({
				  ideias: {
					...userContext.user.ideias,
					[anoAtual]: {
					  ...dadosExistentes,
					  completed: true, // marca a ideia do ano atual como finalizada
					},
				  },
				});

				navigate(routesNames.thankYou.path)
			}

			// userContext.updateUser({
			// 	completed: true
			// })

			// navigate(routesNames.thankYou.path)
		},
	})


	useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};
		const dados = ideias[anoAtual] || {};
	  
		const allSections = [
		  dados.technologicalContributions,
		  dados.technologicalContributions2,
		  dados.additionalInformation,
		];
	  
		allSections.forEach((section) => {
		  if (section && typeof section === 'object') {
			Object.entries(section).forEach(([key, value]) => {
			  formik.setFieldValue(key, value);
			});
		  }
		});
	  }, [userContext?.user]);


	return (
		<div className='formContainerTecnologia'>
			<h2 className='textDadospessoais'>Revise todas as respostas antes de enviar.</h2>

			<form onSubmit={formik.handleSubmit}>

				<FormLabel className="textScienticProd formLabel">O grau de inovação introduzido:</FormLabel>
				<TextInput
					fullWidth
					id="degreeOfInnovation"
					name="degreeOfInnovation"
					multiline={true}
					value={formik.values.degreeOfInnovation}
					onChange={formik.handleChange}
					error={formik.touched.degreeOfInnovation && Boolean(formik.errors.degreeOfInnovation)}
					helperText={formik.touched.degreeOfInnovation && formik.errors.degreeOfInnovation}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">A relevância de tais contribuições, destacando os impactos econômicos, sociais ou ambientais. Nota: O item requer a comprovação inequívoca das contribuições.</FormLabel>
				<TextInput
					fullWidth
					id="relevanceOfContributions"
					name="relevanceOfContributions"
					multiline={true}
					value={formik.values.relevanceOfContributions}
					onChange={formik.handleChange}
					error={formik.touched.relevanceOfContributions && Boolean(formik.errors.relevanceOfContributions)}
					helperText={formik.touched.relevanceOfContributions && formik.errors.relevanceOfContributions}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">Apresente evidências do reconhecimento (por terceiros) da significância das contribuições tecnológicas:</FormLabel>
				<TextInput
					fullWidth
					id="evidenceOfRecognition"
					name="evidenceOfRecognition"
					type="evidenceOfRecognition"
					multiline={true}
					value={formik.values.evidenceOfRecognition}
					onChange={formik.handleChange}
					error={formik.touched.evidenceOfRecognition && Boolean(formik.errors.evidenceOfRecognition)}
					helperText={formik.touched.evidenceOfRecognition && formik.errors.evidenceOfRecognition}

					minRows={4}
					maxRows={7}
					disabled
				/>

				<FormLabel className="textScienticProd formLabel">Aponte a forma de reconhecimento da qualificação e contribuições do candidato pela manifestação por lideranças empresariais, administradores de órgãos públicos, outras instituições privadas e governamentais, comprovação de reconhecimento internacional e outras manifestações pertinentes:*</FormLabel>
				<TextInput
					fullWidth
					id="formOfRecognition"
					name="formOfRecognition"
					type="formOfRecognition"
					multiline={true}
					value={formik.values.formOfRecognition}
					onChange={formik.handleChange}
					error={formik.touched.formOfRecognition && Boolean(formik.errors.formOfRecognition)}
					helperText={formik.touched.formOfRecognition && formik.errors.formOfRecognition}

					minRows={4}
					maxRows={7}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">Quantas empresas de tecnologia foram criadas ou ajudou a criar?</FormLabel>
				<TextInput
					fullWidth
					id="technologyCompaniesCreated"
					name="technologyCompaniesCreated"
					multiline={true}
					value={formik.values.technologyCompaniesCreated}
					onChange={formik.handleChange}
					error={formik.touched.technologyCompaniesCreated && Boolean(formik.errors.technologyCompaniesCreated)}
					helperText={formik.touched.technologyCompaniesCreated && formik.errors.technologyCompaniesCreated}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">Lista das empresas de tecnologia criadas ou que ajudou a criar. Nota: O item requer a comprovação inequívoca das empresas criadas.</FormLabel>
				<TextInput
					fullWidth
					id="listOfTechnologyCompaniesCreated"
					name="listOfTechnologyCompaniesCreated"
					type="listOfTechnologyCompaniesCreated"
					multiline={true}
					value={formik.values.listOfTechnologyCompaniesCreated}
					onChange={formik.handleChange}
					error={formik.touched.listOfTechnologyCompaniesCreated && Boolean(formik.errors.listOfTechnologyCompaniesCreated)}
					helperText={formik.touched.listOfTechnologyCompaniesCreated && formik.errors.listOfTechnologyCompaniesCreated}
					disabled
					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Apresente demonstração de empresas que se beneficiaram de suas contribuições tecnológicas:</FormLabel>
				<TextInput
					fullWidth
					id="demonstrationOfCompaniesBenefited"
					name="demonstrationOfCompaniesBenefited"
					type="demonstrationOfCompaniesBenefited"
					multiline={true}
					value={formik.values.demonstrationOfCompaniesBenefited}
					onChange={formik.handleChange}
					error={formik.touched.demonstrationOfCompaniesBenefited && Boolean(formik.errors.demonstrationOfCompaniesBenefited)}
					helperText={formik.touched.demonstrationOfCompaniesBenefited && formik.errors.demonstrationOfCompaniesBenefited}
					disabled
					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Liste as patentes concedidas pelo INPI ou agências estrangeiras:</FormLabel>
				<TextInput
					fullWidth
					id="patentsGrantedByINPI"
					name="patentsGrantedByINPI"
					type="patentsGrantedByINPI"
					multiline={true}
					value={formik.values.patentsGrantedByINPI}
					onChange={formik.handleChange}
					error={formik.touched.patentsGrantedByINPI && Boolean(formik.errors.patentsGrantedByINPI)}
					helperText={formik.touched.patentsGrantedByINPI && formik.errors.patentsGrantedByINPI}
					disabled
					minRows={4}
					maxRows={7}
				/>

				<FormLabel className="textScienticProd formLabel">Quantas patentes foram licenciadas?</FormLabel>
				<TextInput
					fullWidth
					id="patentsLicensed"
					name="patentsLicensed"
					multiline={true}
					value={formik.values.patentsLicensed}
					onChange={formik.handleChange}
					error={formik.touched.patentsLicensed && Boolean(formik.errors.patentsLicensed)}
					helperText={formik.touched.patentsLicensed && formik.errors.patentsLicensed}
					disabled
				/>


				<FormLabel className="textScienticProd formLabel">Descreva o licenciamento de patentes a empresas no país e no exterior, destacando os impactos econômicos. Nota: O item requer a comprovação inequívoca dos impactos econômicos.</FormLabel>
				<TextInput
					fullWidth
					id="licensingOfPatentsToCompanies"
					name="licensingOfPatentsToCompanies"
					type="licensingOfPatentsToCompanies"
					multiline={true}
					value={formik.values.licensingOfPatentsToCompanies}
					onChange={formik.handleChange}
					error={formik.touched.licensingOfPatentsToCompanies && Boolean(formik.errors.licensingOfPatentsToCompanies)}
					helperText={formik.touched.licensingOfPatentsToCompanies && formik.errors.licensingOfPatentsToCompanies}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Descreva o desenvolvimento de produtos e processos que não foram patenteados:</FormLabel>
				<TextInput
					fullWidth
					id="developmentOfProductsAndProcessesBeenPatented"
					name="developmentOfProductsAndProcessesBeenPatented"
					type="developmentOfProductsAndProcessesBeenPatented"
					multiline={true}
					value={formik.values.developmentOfProductsAndProcessesBeenPatented}
					onChange={formik.handleChange}
					error={formik.touched.developmentOfProductsAndProcessesBeenPatented && Boolean(formik.errors.developmentOfProductsAndProcessesBeenPatented)}
					helperText={formik.touched.developmentOfProductsAndProcessesBeenPatented && formik.errors.developmentOfProductsAndProcessesBeenPatented}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Indique os produtos desenvolvidos que estão no mercado:</FormLabel>
				<TextInput
					fullWidth
					id="developedProductsOnMarket"
					name="developedProductsOnMarket"
					type="developedProductsOnMarket"
					multiline={true}
					value={formik.values.developedProductsOnMarket}
					onChange={formik.handleChange}
					error={formik.touched.developedProductsOnMarket && Boolean(formik.errors.developedProductsOnMarket)}
					helperText={formik.touched.developedProductsOnMarket && formik.errors.developedProductsOnMarket}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Indique processos tecnológicos desenvolvidos que estão em execução por empresas:</FormLabel>
				<TextInput
					fullWidth
					id="developedTechnologicalProcessesExecutedByCompanies"
					name="developedTechnologicalProcessesExecutedByCompanies"
					type="developedTechnologicalProcessesExecutedByCompanies"
					multiline={true}
					value={formik.values.developedTechnologicalProcessesExecutedByCompanies}
					onChange={formik.handleChange}
					error={formik.touched.developedTechnologicalProcessesExecutedByCompanies && Boolean(formik.errors.developedTechnologicalProcessesExecutedByCompanies)}
					helperText={formik.touched.developedTechnologicalProcessesExecutedByCompanies && formik.errors.developedTechnologicalProcessesExecutedByCompanies}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Inclua cargos ocupados relativos às atuações em sistemas tecnológicos:</FormLabel>
				<TextInput
					fullWidth
					id="activitiesInTechnologicalSystems"
					name="activitiesInTechnologicalSystems"
					multiline={true}
					value={formik.values.activitiesInTechnologicalSystems}
					onChange={formik.handleChange}
					error={formik.touched.activitiesInTechnologicalSystems && Boolean(formik.errors.activitiesInTechnologicalSystems)}
					helperText={formik.touched.activitiesInTechnologicalSystems && formik.errors.activitiesInTechnologicalSystems}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Inclua consultoria a empresas e organizações tecnológicas:</FormLabel>
				<TextInput
					fullWidth
					id="consultancyToTechnologyCompanies"
					name="consultancyToTechnologyCompanies"
					type="consultancyToTechnologyCompanies"
					value={formik.values.consultancyToTechnologyCompanies}
					multiline={true}
					onChange={formik.handleChange}
					error={formik.touched.consultancyToTechnologyCompanies && Boolean(formik.errors.consultancyToTechnologyCompanies)}
					helperText={formik.touched.consultancyToTechnologyCompanies && formik.errors.consultancyToTechnologyCompanies}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Descreva os principais prêmios explicitando a importância de cada um:</FormLabel>
				<TextInput
					fullWidth
					id="mainAwardsWithImportance"
					name="mainAwardsWithImportance"
					type="mainAwardsWithImportance"
					multiline={true}
					value={formik.values.mainAwardsWithImportance}
					onChange={formik.handleChange}
					error={formik.touched.mainAwardsWithImportance && Boolean(formik.errors.mainAwardsWithImportance)}
					helperText={formik.touched.mainAwardsWithImportance && formik.errors.mainAwardsWithImportance}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Descreva sua atuação na formação de recursos humanos que produziram impactos em inovação:</FormLabel>
				<TextInput
					fullWidth
					id="trainingHumanResourcesImpactedInnovation"
					name="trainingHumanResourcesImpactedInnovation"
					type="trainingHumanResourcesImpactedInnovation"
					multiline={true}
					value={formik.values.trainingHumanResourcesImpactedInnovation}
					onChange={formik.handleChange}
					error={formik.touched.trainingHumanResourcesImpactedInnovation && Boolean(formik.errors.trainingHumanResourcesImpactedInnovation)}
					helperText={formik.touched.trainingHumanResourcesImpactedInnovation && formik.errors.trainingHumanResourcesImpactedInnovation}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Descreva a sua familiaridade no uso e aplicação de tecnologias modernas (exemplos: transformação digital, inteligência artificial, nanotecnologia, internet das coisas, entre outros).</FormLabel>
				<TextInput
					fullWidth
					id="familiarityWithModernTechnologies"
					name="familiarityWithModernTechnologies"
					multiline={true}
					value={formik.values.familiarityWithModernTechnologies}
					onChange={formik.handleChange}
					error={formik.touched.familiarityWithModernTechnologies && Boolean(formik.errors.familiarityWithModernTechnologies)}
					helperText={formik.touched.familiarityWithModernTechnologies && formik.errors.familiarityWithModernTechnologies}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Descreva suas publicações técnico-científicas mais relevantes (textos, relatórios industriais, etc.):</FormLabel>
				<TextInput
					fullWidth
					id="mostRelevantTechnicalScientificPublication"
					name="mostRelevantTechnicalScientificPublication"
					type="mostRelevantTechnicalScientificPublication"
					multiline={true}
					value={formik.values.mostRelevantTechnicalScientificPublication}
					onChange={formik.handleChange}
					error={formik.touched.mostRelevantTechnicalScientificPublication && Boolean(formik.errors.mostRelevantTechnicalScientificPublication)}
					helperText={formik.touched.mostRelevantTechnicalScientificPublication && formik.errors.mostRelevantTechnicalScientificPublication}
					disabled

					minRows={4}
					maxRows={7}
				/>


				<FormLabel className="textScienticProd formLabel">Outras informações relevantes:</FormLabel>
				<TextInput
					fullWidth
					id="otherRelevantInformation"
					name="otherRelevantInformation"
					type="otherRelevantInformation"
					multiline={true}
					value={formik.values.otherRelevantInformation}
					onChange={formik.handleChange}
					error={formik.touched.otherRelevantInformation && Boolean(formik.errors.otherRelevantInformation)}
					helperText={formik.touched.otherRelevantInformation && formik.errors.otherRelevantInformation}
					disabled

					minRows={4}
					maxRows={100}
				/>

				{
					typeof formik.values.recommendationLetters === 'string' ? (
						<InputFile
							label="Cartas de recomendação:*"
							name="recommendationLetters"
							value={formik.values.recommendationLetters}
							disabled
						/>
					) : (
						formik.values.recommendationLetters.map((recommendationLetter, index) => (
							<InputFile
								label={`Carta de recomendação (${index + 1}):*`}
								name={`recommendationLetters.${index}`}
								value={recommendationLetter}
								mode="download"
								disabled
							/>
						))
					)
				}

				<InputFile
					label="Currículo Vitae Lattes:*"
					name="curriculum"
					value={formik.values.curriculum}
					onChange={formik.handleChange}
					mode="download"
					error={formik.touched.curriculum && Boolean(formik.errors.curriculum)}
					helperText={formik.touched.curriculum && formik.errors.curriculum}
					disabled
				/>

				<div className='row-gap last' style={{ marginTop: 80 }}>
					<ConfirmPopup />

					<ButtonStagesBack
						type="outline"
						label="VOLTAR E EDITAR"
						color={baseColors.darkBlue}
						typeSubmit={false}
						onClick={() => navigate(-1)}
					/>

					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,

						}}

						label="SUBMETER"
						color={baseColors.lightIndigo} />
				</div>

			</form>

		</div>
	)
}
