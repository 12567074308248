
import { FormLabel } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { ProgressBarTecnology } from '../../../components/ProgressBarTecnology/ProgressBarTecnology'
import { Button } from '../../../components/Button'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'


import './styles.scss'

// const validationSchema = yup.object({
// 	degreeOfInnovation: yup
// 		.string()
// 		.required('É necessário preencher um nome para continuar.'),
// 	relevanceOfContributions: yup
// 		.string()
// 		.required('É necessário preencher um nome para continuar.'),
// 	evidenceOfRecognition: yup
// 		.string()
// 		.required('É necessário preencher um nome para continuar.'),
// 	formOfRecognition: yup
// 		.string()
// 		.required('É necessário preencher um nome para continuar.'),

// })


export function TechnologicalContributions() {
	const navigate = useNavigate();
	const userContext = useAuth()


	const formik = useFormik({
		initialValues: {
		  degreeOfInnovation: '',
		  relevanceOfContributions: '',
		  evidenceOfRecognition: '',
		  formOfRecognition: '',
		},
		onSubmit: (values) => {
		  const anoAtual = new Date().getFullYear().toString();
	  
		  // Recupera dados existentes para o ano atual
		  const dadosExistentes = userContext.user.ideias?.[anoAtual] || {};
	  
		  // Atualiza apenas o bloco necessário com merge seguro
		  userContext.updateUser({
			ideias: {
			  ...userContext.user.ideias,
			  [anoAtual]: {
				...dadosExistentes,
				technologicalContributions: values,
			  },
			},
		  });
	  
		  window.scrollTo({ top: 0, behavior: 'smooth' });
		  navigate(routesNames.technologicalContributions2.path);
		},
	  });
	  useEffect(() => {
		const anoAtual = new Date().getFullYear().toString();
		const ideias = userContext?.user?.ideias || {};
	
		const bloco2025 = ideias[anoAtual]?.technologicalContributions;
		const bloco2024 = userContext?.user?.technologicalContributions3;
		const bloco2023 = userContext?.user?.technologicalContributions;
	
		let dataToLoad = null;
	
		if (bloco2025 && typeof bloco2025 === 'object' && Object.keys(bloco2025).length > 0) {
			dataToLoad = bloco2025;
		} else if (bloco2024 && typeof bloco2024 === 'object' && Object.keys(bloco2024).length > 0) {
			dataToLoad = bloco2024;
		} else if (bloco2023 && typeof bloco2023 === 'object' && Object.keys(bloco2023).length > 0) {
			dataToLoad = bloco2023;
		}
	
		if (dataToLoad) {
			Object.entries(dataToLoad).forEach(([key, value]) => {
				formik.setFieldValue(key, value);
			});
		}
	}, [userContext?.user]);


	const allRequiredFields = [
		"degreeOfInnovation",
		"relevanceOfContributions",
		"evidenceOfRecognition",
		"formOfRecognition",
		"technologyCompaniesCreated",
		"listOfTechnologyCompaniesCreated",
		"demonstrationOfCompaniesBenefited",
		"patentsGrantedByINPI",
		"patentsLicensed",
		"licensingOfPatentsToCompanies",
		"developmentOfProductsAndProcessesBeenPatented",
		"developedProductsOnMarket",
		"developedTechnologicalProcessesExecutedByCompanies",
		"activitiesInTechnologicalSystems",
		"consultancyToTechnologyCompanies",
		"mainAwardsWithImportance",
		"trainingHumanResourcesImpactedInnovation",
		"familiarityWithModernTechnologies",
		"mostRelevantTechnicalScientificPublication",
		"otherRelevantInformation"
	];



	return (
		<><div className='formContainerTecnologia'>
			<h2 className='textDadospessoais'>Descreva suas contribuições tecnológicas mais<br /> relevantes já desenvolvidas, destacando:</h2>

			<form onSubmit={formik.handleSubmit}>


				{/* <Button
        label="Salvar e sair"
        type="outline"
        style={{position: 'absolute', top: 0, right: 0}}
        onClick={() => {
            setTimeout(() => {
                userContext?.signOutUser()
            }, 200);
        }}
    /> */}

				<FormLabel className="textScienticProd formLabel">O grau de inovação introduzido:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="degreeOfInnovation"
						name="degreeOfInnovation"
						value={formik.values.degreeOfInnovation}
						onChange={formik.handleChange}
						error={formik.touched.degreeOfInnovation && Boolean(formik.errors.degreeOfInnovation)}
						helperText={formik.touched.degreeOfInnovation && formik.errors.degreeOfInnovation}
						multiline
						minRows={4}
						maxRows={7} />
				</div>
				<FormLabel className="textScienticProd formLabel">A relevância de tais contribuições, destacando os impactos econômicos, sociais ou ambientais. Nota: O item requer a comprovação inequívoca das contribuições.</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="relevanceOfContributions"
						name="relevanceOfContributions"
						value={formik.values.relevanceOfContributions}
						onChange={formik.handleChange}
						error={formik.touched.relevanceOfContributions && Boolean(formik.errors.relevanceOfContributions)}
						helperText={formik.touched.relevanceOfContributions && formik.errors.relevanceOfContributions}
						multiline
						minRows={4}
						maxRows={7} />
				</div>
				<FormLabel className="textScienticProd formLabel">Apresente evidências do reconhecimento (por terceiros) da significância das contribuições tecnológicas:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="evidenceOfRecognition"
						name="evidenceOfRecognition"
						type="evidenceOfRecognition"
						value={formik.values.evidenceOfRecognition}
						onChange={formik.handleChange}
						error={formik.touched.evidenceOfRecognition && Boolean(formik.errors.evidenceOfRecognition)}
						helperText={formik.touched.evidenceOfRecognition && formik.errors.evidenceOfRecognition}
						multiline
						minRows={4}
						maxRows={7} />
				</div>
				<FormLabel className="textScienticProd formLabel">Aponte a forma de reconhecimento da qualificação das contribuições do candidato pela manifestação por lideranças empresariais, administradores de órgãos públicos, outras instituições privadas e governamentais, comprovação de reconhecimento internacional e outras manifestações pertinentes:</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="formOfRecognition"
						name="formOfRecognition"
						type="formOfRecognition"
						value={formik.values.formOfRecognition}
						onChange={formik.handleChange}
						error={formik.touched.formOfRecognition && Boolean(formik.errors.formOfRecognition)}
						helperText={formik.touched.formOfRecognition && formik.errors.formOfRecognition}
						multiline
						minRows={4}
						maxRows={7} />
				</div>
				<div className='row-gap last'>
					<ButtonStages
						type="outline"
						label="SALVAR E SAIR"
						color={baseColors.lightIndigo}
						onClick={() => {
							setTimeout(() => {
								userContext?.signOutUser()
							}, 200)
						}} />

					<ButtonStagesBack
						type="outline"
						label="VOLTAR"
						color={baseColors.lightIndigo}
						typeSubmit
						onClick={() => navigate(-1)} />



					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,
						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />

					{/* <Button
        label="Salvar e avançar"
        color={baseColors.darkBlue}
    // style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
    /> */}
				</div>

				<ConfirmPopupBack />
				<ConfirmPopupSaveback />

			</form>

		</div><div className='divProgress'>
		<ProgressBarTecnology
  userData={userContext?.user?.ideias?.[new Date().getFullYear().toString()] || {}}
  requiredFields={allRequiredFields}
/>
			</div></>

	)
}
