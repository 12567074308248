
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useNavigate, } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../components/Button'
import { ButtonChildren } from '../../components/ButtonChildren'
import { TextInput } from '../../components/TextInput'
import { TextInputMask } from '../../components/TextInputMask'
import { TextInputMaskNew } from '../../components/TextInputMaskNew'
import { User } from '../../core/Controllers/User'
import { useAuth } from '../../hooks/useAuth'
import { routesNames } from '../../routes/routesNames'
import { baseColors } from '../../styles'


import './styles.scss'
import axios from 'axios'

const validationSchema = yup.object({
	name: yup
		.string()
		.required('É necessário preencher um nome para continuar.'),
	email: yup
		.string()
		.email('É preciso que o e-mail seja válido.')
		.required('É necessário preencher um e-mail válido para continuar.'),
	password: yup
		.string()
		.min(8, 'A senha precisa ter no mínimo 8 caracteres.')
		.required('É necessário preencher uma senha válida para continuar.'),
	passwordConfirm: yup
		.string()
		.min(8, 'A senha precisa ter no mínimo 8 caracteres.')
		.required('É necessário preencher uma senha válida para continuar.')
		.oneOf([yup.ref('password')], 'As senhas não são iguais.'),
	phoneNumber: yup
		.string()
		.min(6, 'O número de telefone precisa ter no mínimo 6 caracteres.'),
	category: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	acceptTerms: yup
		.boolean()
		.required('É necessário concordar com os termos do regulamento para continuar.')
		.test('Foi aceito', 'É necessário concordar com os termos do regulamento para continuar.', (value: boolean) => value)
})


const expired = false;

// const nowData = new Date();
// const expiredData = new Date("Feb 28, 2024 15:55:00");

// console.log('data', nowData);


// if (nowData < expiredData) {
// 	expired = false;

// } else {
// 	expired = true;
// }

export function Home() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const [showLoading, setShowLoading] = useState(false)

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			password: '',
			passwordConfirm: '',
			phoneNumber: '',
			category: '',
			acceptTerms: false,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {



			setShowLoading(true)




			// e12a3579e02ba536d2b58bf8f12d66dd-us7

			let axiosConfig = {
				headers: {
					'authorization': "Basic e12a3579e02ba536d2b58bf8f12d66dd-us7",
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				}
			};

			let data = {
				email_address: 'uriel@lcomunicacao.com',
				status: "subscribed"
			};





			let urls = [
			
				'https://api.lcomunicacao.com/index.php?email=' + values.email + '&tag=2025-Fez inscrição&phone=' + values.phoneNumber + '&nome=' + values.name,
			]

			// 'https://cbmm.lcomunicacao.com/?' + candidate_1.email_1 + '&tag=INSTITUIÇÕES_2024'


			const requests = urls.map((url) => axios.get(url));

			console.log(requests);

			axios.all(requests)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				})



			// 			axios.post('https://cbmm.lcomunicacao.com/?' + candidate_1.email_1 + '&tag=INSTITUIÇÕES_2024',  data, axiosConfig)
			//   .then((response) => {
			//     console.log(response);
			//   })
			//   .catch((error) => {
			//     console.log(error);
			//   })



			User().create({
				email: values.email,
				password: values.password,
			})
				.then((data) => {
					const dataToSave = { ...values, ...data } as any

					delete dataToSave?.password
					delete dataToSave?.passwordConfirm

					userContext.updateUser({
						...dataToSave
					})

					return new Promise<any>((resolve, reject) => { setTimeout(resolve, 400) })
				})
				.then(() => {
					setShowLoading(false)
					navigate(routesNames.confirmEmail.path)
				})
				.catch((error) => {
				
					setShowLoading(false);
				
					if (error == "Error: FirebaseError: Firebase: Error (auth/email-already-in-use).") {
						alert("Esse endereço de email já está em uso.");
					} else if (error == "auth/invalid-email") {
						alert("Email inválido.");
					} else if (error == "auth/operation-not-allowed") {
						alert("Operation not allowed.");
					}  else if (error == "auth/invalid-login-credentials") {
						alert("A sua senha está incorreta.");
					}
						})
		},
	})


	useEffect(() => {
		if (expired) {
			navigate(routesNames.login.path)
		}
	}, [navigate])

	return expired ? (
		<div>
			<h2>Preencha os dados e escolha qual prêmio você deseja concorrer:</h2>
		</div>
	) : (
		<><h1 className='textInscrevaseTitle'>Bem-vindo ao sistema de inscrição do Prêmio CBMM 2025.</h1><div className='formContainerHome'>
			<h2 className='textInscrevase'>Preencha os dados e escolha sua categoria:</h2>

			<form onSubmit={formik.handleSubmit}>

				<div className='divInsc'>
					<p className="homeText">Nome:</p><TextInput
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name} />
				</div>
				<div className='divInsc'>
					<p className="homeText">Email:</p><TextInput
						fullWidth
						id="email"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email} />
				</div>
				<div className='divInsc'>
					<p className="homeText">Senha:</p><TextInput
						fullWidth
						id="password"
						name="password"
						type="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={formik.touched.password && formik.errors.password} />
				</div>
				<div className='divInsc'>
					<p className="homeText confirm-senha">Confirme a senha:</p><TextInput
						fullWidth
						id="passwordConfirm"
						name="passwordConfirm"
						type="password"
						value={formik.values.passwordConfirm}
						onChange={formik.handleChange}
						error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
						helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm} />
				</div>
				<div className='divInsc'>
					<p className="homeText">Telefone:</p><TextInputMaskNew
						fullWidth
						id="phoneNumber"
						name="phoneNumber"
						value={formik.values.phoneNumber}
						onChange={formik.handleChange}
						error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
						helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
						mask="(##) # ####-####"
						definitions={{
							'#': /[0-9]/,
						}} />
				</div>
				<FormGroup>
					<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
					<div className='divRadio'>
						<RadioGroup
							aria-labelledby="controlled-radio-buttons-group"
							value={formik.values.category}
							onChange={formik.handleChange}
						>
							<FormControlLabel className='radioHome' value="Ciência" control={<Radio name="category" />} label="Ciência" />
							<FormControlLabel className='radioHome' value="Tecnologia" control={<Radio name="category" />} label="Tecnologia" />
						</RadioGroup>
					</div>
					{formik.touched.category && Boolean(formik.errors.category) && <p className='errorHelperText'>{formik.errors.category}</p>}
				</FormGroup>

				<div>
					<span className='textEdital'>
						<strong>
							<a
								href="https://premiocbmm.com.br/wp-content/themes/lp-cbmm/assets/images/RegulamentoAutorizado2025.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Leia o edital
							</a>
						</strong>
						{' '}antes de se inscrever.
					</span>
				</div>



				<div className='row-gap' style={{ marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40, justifyContent: 'flex-end' }}>

					<div className='divTermos'>
						<FormGroup>
							<FormControlLabel
								control={<Checkbox
									checked={!!formik.values.acceptTerms}
									name="acceptTerms"
									color='primary'
									onChange={formik.handleChange}
									style={{
										borderRadius: 50,
										marginBottom: 68
									}}
									inputProps={{ 'aria-label': 'Li o edital e concordo com as regras para participação no concurso. Concordo também em ceder os meus dados pessoais para uso pelo Prêmio CBMM de Ciência e Tecnologia.' }} />}
								label="Li o edital e concordo com as regras para participação no concurso. Concordo também em ceder os meus dados pessoais para uso pelo Prêmio CBMM de Ciência e Tecnologia."
								className='termosText' />
							{formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) && <p className='errorHelperText'>{formik.errors.acceptTerms}</p>}
						</FormGroup>
					</div>
					<div className=''>
						<ButtonChildren
							label="INSCREVA-SE"
							color={baseColors.lightIndigo}

						/>
					</div>

				</div>

			</form>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={showLoading}
				onClick={() => { }}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

		</div>

			<div className='divEnd'>
				<h2 className='textLogin'>
					Caso já tenha uma inscrição válida, {' '}
					<strong>
						<span
							className='btnLogin'
							onClick={() => navigate(routesNames.login.path)}
						>
							FAÇA O LOGIN AQUI.
						</span>
					</strong>
				</h2>

			</div>

		</>
	)
}
