import { ButtonProps, FormLabel } from "@mui/material";
import { TextInput } from "../TextInput";
import {
    getStorage,
    ref,
    getDownloadURL,
    listAll
} from "firebase/storage";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";

export function ButtonDownload({ onClick }: ButtonProps) {
    return (
        <button className='btn-Download' onClick={onClick && onClick}>
            Download
        </button>
    );
}

export function ViewRegisterData2024({ data }: { data: any }) {
    const storage = getStorage();
    const userContext = useAuth();
    const [curriculumUrl, setCurriculumUrl] = useState<string | null>(null);
    const [recommendationFiles, setRecommendationFiles] = useState<{ url: string; name: string }[]>([]);

    useEffect(() => {
        if (!data?.uid) return;

        const fetchFiles = async () => {
            try {
                const folderRef = ref(storage, `uploads/${data.uid}`);
                const fileList = await listAll(folderRef);

                // Busca e define o currículo
                const curriculumFile = fileList.items.find((item) => item.name.startsWith("curriculum"));
                if (curriculumFile) {
                    const url = await getDownloadURL(curriculumFile);
                    setCurriculumUrl(url);
                } else {
                    setCurriculumUrl(null);
                }

                // Busca e define as cartas de recomendação
                const recommendationUrls = await Promise.all(
                    fileList.items
                        .filter((item) => item.name.startsWith("recommendationLetters"))
                        .map(async (file) => {
                            const fileUrl = await getDownloadURL(file);
                            return { url: fileUrl, name: file.name.replace("recommendationLetters-", "") };
                        })
                );

                setRecommendationFiles(recommendationUrls);
            } catch (error) {
                console.warn("Erro ao listar arquivos do Storage:", error);
            }
        };

        fetchFiles();
    }, [data?.uid]);


    const personalData = data.category === 'Ciência'
        ? { ...data.personalData, ...data.evidenceOfRecognition1, ...data.personalData, ...data.scientificProduction1, ...data.bibliometricInformation1, ...data.extraData1 }
        : { ...data.personalData, ...data.additionalInformation1, ...data.technologicalContributions3, ...data.technologicalContributions4, }


    console.log('here', personalData.otherRelevantInformation)


    return data.category === 'Ciência' ? (
        <>
            <FormLabel className="formLabel">
                Liste os 10 artigos de maior importância na sua área de pesquisa pulicado em revistas:
            </FormLabel>
            <TextInput
                fullWidth
                id="mostImportantArticlesInYourResearch"
                name="mostImportantArticlesInYourResearch"
                type="mostImportantArticlesInYourResearch"
                value={personalData.mostImportantArticlesInYourResearch}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste os seus 10 artigos mais citados e os respectivos números de citações:
            </FormLabel>
            <TextInput
                fullWidth
                id="mostCitedArticlesAndCitationNumbers"
                name="mostCitedArticlesAndCitationNumbers"
                value={personalData.mostCitedArticlesAndCitationNumbers}
                type="mostCitedArticlesAndCitationNumbers"
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste os capítulos de livros e artigos publicados a convite de editores de revistas:
            </FormLabel>
            <TextInput
                fullWidth
                id="publishedBookChaptersAndArticles"
                name="publishedBookChaptersAndArticles"
                value={personalData.publishedBookChaptersAndArticles}
                type="publishedBookChaptersAndArticles"
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste os livros publicados:
            </FormLabel>
            <TextInput
                fullWidth
                id="listPublishedBooks"
                name="listPublishedBooks"
                type="listPublishedBooks"
                value={personalData.listPublishedBooks}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste as patentes concedidas pelo INPI e instituições estrangeiras:
            </FormLabel>
            <TextInput
                fullWidth
                id="patentsGrantedByINPI"
                name="patentsGrantedByINPI"
                type="patentsGrantedByINPI"
                value={personalData.patentsGrantedByINPI}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />




            <FormLabel className="formLabel">
                Liste as academias de Ciência nas quais é membro:
            </FormLabel>
            <TextInput
                fullWidth
                id="scienceAcademiesWhichYouAreMember"
                name="scienceAcademiesWhichYouAreMember"
                type="scienceAcademiesWhichYouAreMember"
                value={personalData.scienceAcademiesWhichYouAreMember}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste seus prêmios nacionais e internacionais mais relevantes, descrevendo brevemente a importância de cada um:
            </FormLabel>
            <TextInput
                fullWidth
                id="listOfMostRelevantAwardsWithDescription"
                name="listOfMostRelevantAwardsWithDescription"
                type="listOfMostRelevantAwardsWithDescription"
                value={personalData.listOfMostRelevantAwardsWithDescription}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste suas plenary e keynote talks em eventos no Brasil e no exterior:
            </FormLabel>
            <TextInput
                fullWidth
                id="listPlenaryAndKeynoteTalks"
                name="listPlenaryAndKeynoteTalks"
                type="listPlenaryAndKeynoteTalks"
                value={personalData.listPlenaryAndKeynoteTalks}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste as posições atuais e anteriores na editoria de revistas científicas:
            </FormLabel>
            <TextInput
                fullWidth
                id="listCurrentPositionsEditorshipInJournals"
                name="listCurrentPositionsEditorshipInJournals"
                type="listCurrentPositionsEditorshipInJournals"
                value={personalData.listCurrentPositionsEditorshipInJournals}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste os congressos nacionais e internacionais organizados como chairman:
            </FormLabel>
            <TextInput
                fullWidth
                id="listCongressesOrganizesAsChairman"
                name="listCongressesOrganizesAsChairman"
                type="listCongressesOrganizesAsChairman"
                value={personalData.listCongressesOrganizesAsChairman}
                minRows={4}
                maxRows={7}
                multiline
                disabled
            />


            <FormLabel className="formLabel">
                Liste as posições de liderança atuais e passadas em sociedades e instituições científicas do Brasil e internacionais:
            </FormLabel>
            <TextInput
                fullWidth
                id="listLeadershipPositionsScientificSocieties"
                name="listLeadershipPositionsScientificSocieties"
                type="listLeadershipPositionsScientificSocieties"
                value={personalData.listLeadershipPositionsScientificSocieties}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />



            <FormLabel className="formLabel">
                Número de artigos científicos publicados em periódicos com peer review indexados pelo Scopus:
            </FormLabel>
            <TextInput
                fullWidth
                id="scientificArticlesPublishedIndexedByScopus"
                name="scientificArticlesPublishedIndexedByScopus"
                type="scientificArticlesPublishedIndexedByScopus"
                value={personalData.scientificArticlesPublishedIndexedByScopus}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Tem quantas citações (Scopus)?</FormLabel>
            <TextInput
                fullWidth
                id="totalCitations"
                name="totalCitations"
                type="totalCitations"
                value={personalData.totalCitations}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Quantos alunos de iniciação científica, mestrado, doutorado e pós-doutorado já orientou?</FormLabel>
            <TextInput
                fullWidth
                id="totalScientificInitiationStudentsSupervised"
                name="totalScientificInitiationStudentsSupervised"
                type="totalScientificInitiationStudentsSupervised"
                value={personalData.totalScientificInitiationStudentsSupervised}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Qual é o seu H-index (Scopus)? Compare com seus pares do Brasil e exterior.
            </FormLabel>
            <TextInput
                fullWidth
                id="hIndexScopusScore"
                name="hIndexScopusScore"
                value={personalData.hIndexScopusScore}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Qual é o seu Leadership Index (LI)? LI é a razão entre o número de artigos nos quais o seu H-index é maior que dos demais coautores e o número total de (seus) artigos em periódicos indexados pelo Scopus (LI {"<="}1).
            </FormLabel>
            <TextInput
                fullWidth
                id="leadershipIndex"
                name="leadershipIndex"
                value={personalData.leadershipIndex}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />



            <FormLabel className="formLabel">Descreva outras informações relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="otherRelevantInformation"
                name="otherRelevantInformation"
                type="otherRelevantInformation"
                value={personalData?.otherRelevantInformation}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">Descreva em uma uma página, suas descobertas científicas mais relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="shortRelevantScientificDiscoveries"
                name="shortRelevantScientificDiscoveries"
                type="otherRelevantInformation"
                value={personalData.shortRelevantScientificDiscoveries}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />




            <div className="section-one">
                <div>
                    <TextInput
                        label="Cartas de recomendação:*"
                        id="Cartas de recomendação:*"
                        name="recommendationLetters"
                        value={recommendationFiles.length > 0 ? `Foram enviadas ${recommendationFiles.length} cartas.` : "Nenhuma carta enviada."}
                        disabled
                    />
                </div>
                <div className="div-Recommendation">

                    <div>
                        {recommendationFiles.length > 0 ? (
                            recommendationFiles.map(({ url, name }, index) => (
                                <div key={index} className="downloadItem">
                                    <div>
                                        <span className="fileName">{name}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="downloadItem">
                            </div>
                        )}
                    </div>

                    <div>
                        {recommendationFiles.length > 0 ? (
                            recommendationFiles.map(({ url, name }, index) => (
                                <div key={index} className="downloadItem">
                                    <div>
                                        <a href={url} target="_blank" rel="noopener noreferrer" className="btn-Download">
                                            Download
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (

                            <p>Nenhuma carta disponível.</p>

                        )}
                    </div>
                </div>

                <div className="div-Lattes">
                    <div>
                        <TextInput
                            label="Currículo Vitae Lattes:*"
                            id="Currículo Vitae Lattes:*"
                            name="curriculum"
                            value={curriculumUrl ? "Disponível para download" : "Nenhum currículo enviado."}
                            disabled
                        />
                    </div>
                    <div>
                        {curriculumUrl && (
                            <a className="btn-Download" target="_blank" href={curriculumUrl}>
                                Download
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <FormLabel className="formLabel">O grau de inovação introduzido:</FormLabel>
            <TextInput
                fullWidth
                id="degreeOfInnovation"
                name="degreeOfInnovation"
                type="degreeOfInnovation"
                value={personalData.degreeOfInnovation}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">A relevância de tais contribuições, destacando os impactos econômicos, sociais ou ambientais. Nota: O item requer a comprovação inequívoca das contribuições.</FormLabel>
            <TextInput
                fullWidth
                id="relevanceOfContributions"
                name="relevanceOfContributions"
                type="relevanceOfContributions"
                value={personalData.relevanceOfContributions}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">Apresente evidências do reconhecimento (por terceiros) da significância das contribuições tecnológicas:</FormLabel>
            <TextInput
                fullWidth
                id="evidenceOfRecognition"
                name="evidenceOfRecognition"
                type="evidenceOfRecognition"
                value={personalData.evidenceOfRecognition}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">Aponte a forma de reconhecimento da qualificação e contribuições do candidato pela manifestação por lideranças empresariais, administradores de órgãos públicos, outras instituições privadas e governamentais, comprovação de reconhecimento internacional e outras manifestações pertinentes:*</FormLabel>
            <TextInput
                fullWidth
                id="formOfRecognition"
                name="formOfRecognition"
                type="formOfRecognition"
                value={personalData.formOfRecognition}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Quantas empresas de tecnologia foram criadas ou ajudou a criar?</FormLabel>
            <TextInput
                fullWidth
                id="technologyCompaniesCreated"
                name="technologyCompaniesCreated"
                type="technologyCompaniesCreated"
                value={personalData.technologyCompaniesCreated}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Lista das empresas de tecnologia criadas ou que ajudou a criar. Nota: O item requer a comprovação inequívoca das empresas criadas.</FormLabel>
            <TextInput
                fullWidth
                id="listOfTechnologyCompaniesCreated"
                name="listOfTechnologyCompaniesCreated"
                type="listOfTechnologyCompaniesCreated"
                value={personalData.listOfTechnologyCompaniesCreated}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Apresente demonstração de empresas que se beneficiaram de suas contribuições tecnológicas:</FormLabel>
            <TextInput
                fullWidth
                id="demonstrationOfCompaniesBenefited"
                name="demonstrationOfCompaniesBenefited"
                type="demonstrationOfCompaniesBenefited"
                value={personalData.demonstrationOfCompaniesBenefited}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Liste as patentes concedidas pelo INPI ou agências estrangeiras:</FormLabel>
            <TextInput
                fullWidth
                id="patentsGrantedByINPI"
                name="patentsGrantedByINPI"
                type="patentsGrantedByINPI"
                value={personalData.patentsGrantedByINPI}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Quantas patentes foram licenciadas?</FormLabel>
            <TextInput
                fullWidth
                id="patentsLicensed"
                name="patentsLicensed"
                type="patentsLicensed"
                value={personalData.patentsLicensed}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Descreva o licenciamento de patentes a empresas no país e no exterior, destacando os impactos econômicos. Nota: O item requer a comprovação inequívoca dos impactos econômicos.</FormLabel>
            <TextInput
                fullWidth
                id="licensingOfPatentsToCompanies"
                name="licensingOfPatentsToCompanies"
                type="licensingOfPatentsToCompanies"
                value={personalData.licensingOfPatentsToCompanies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva o desenvolvimento de produtos e processos que não foram patenteados:</FormLabel>
            <TextInput
                fullWidth
                id="developmentOfProductsAndProcessesBeenPatented"
                name="developmentOfProductsAndProcessesBeenPatented"
                type="developmentOfProductsAndProcessesBeenPatented"
                value={personalData.developmentOfProductsAndProcessesBeenPatented}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Indique os produtos desenvolvidos que estão no mercado:</FormLabel>
            <TextInput
                fullWidth
                id="developedProductsOnMarket"
                name="developedProductsOnMarket"
                type="developedProductsOnMarket"
                value={personalData.developedProductsOnMarket}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Indique processos tecnológicos desenvolvidos que estão em execução por empresas:</FormLabel>
            <TextInput
                fullWidth
                id="developedTechnologicalProcessesExecutedByCompanies"
                name="developedTechnologicalProcessesExecutedByCompanies"
                type="developedTechnologicalProcessesExecutedByCompanies"
                value={personalData.developedTechnologicalProcessesExecutedByCompanies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Inclua cargos ocupados relativos às atuações em sistemas tecnológicos:</FormLabel>
            <TextInput
                fullWidth
                id="activitiesInTechnologicalSystems"
                name="activitiesInTechnologicalSystems"
                type="activitiesInTechnologicalSystems"
                value={personalData.activitiesInTechnologicalSystems}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Inclua consultoria a empresas e organizações tecnológicas:</FormLabel>
            <TextInput
                fullWidth
                id="consultancyToTechnologyCompanies"
                name="consultancyToTechnologyCompanies"
                type="consultancyToTechnologyCompanies"
                value={personalData.consultancyToTechnologyCompanies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva os principais prêmios descrevendo a importância de cada um:</FormLabel>
            <TextInput
                fullWidth
                id="mainAwardsWithImportance"
                name="mainAwardsWithImportance"
                type="mainAwardsWithImportance"
                value={personalData.mainAwardsWithImportance}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva sua atuação na formação de recursos humanos que produziram impactos em inovação:</FormLabel>
            <TextInput
                fullWidth
                id="trainingHumanResourcesImpactedInnovation"
                name="trainingHumanResourcesImpactedInnovation"
                type="trainingHumanResourcesImpactedInnovation"
                value={personalData.trainingHumanResourcesImpactedInnovation}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva a sua familiaridade no uso e aplicação de tecnologias modernas (exemplos: transformação digital, inteligência artificial, nanotecnologia, internet das coisas, entre outros).</FormLabel>
            <TextInput
                fullWidth
                id="familiarityWithModernTechnologies"
                name="familiarityWithModernTechnologies"
                value={personalData.familiarityWithModernTechnologies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva suas publicações técnico-científicas mais relevantes (textos, relatórios industriais, etc.):</FormLabel>
            <TextInput
                fullWidth
                id="mostRelevantTechnicalScientificPublication"
                name="mostRelevantTechnicalScientificPublication"
                type="mostRelevantTechnicalScientificPublication"
                value={personalData.mostRelevantTechnicalScientificPublication}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Outras informações relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="otherRelevantInformation"
                name="otherRelevantInformation"
                type="otherRelevantInformation"
                value={personalData.otherRelevantInformation}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />

            <FormLabel className="formLabel">Descreva em uma uma página, suas descobertas científicas mais relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="shortCurriculum"
                name="shortCurriculum"
                type="shortCurriculum"
                value={personalData.shortCurriculum}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <div className="section-one">
                <div>
                    <TextInput
                        label="Cartas de recomendação:*"
                        id="Cartas de recomendação:*"
                        name="recommendationLetters"
                        value={recommendationFiles.length > 0 ? `Foram enviadas ${recommendationFiles.length} cartas.` : "Nenhuma carta enviada."}
                        disabled
                    />
                </div>
                <div className="div-Recommendation">

                    <div>
                        {recommendationFiles.length > 0 ? (
                            recommendationFiles.map(({ url, name }, index) => (
                                <div key={index} className="downloadItem">
                                    <div>
                                        <span className="fileName">{name}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="downloadItem">
                            <p></p>
                            </div>
                        )}
                    </div>

                    <div>
                        {recommendationFiles.length > 0 ? (
                            recommendationFiles.map(({ url, name }, index) => (
                                <div key={index} className="downloadItem">
                                    <div>
                                        <a href={url} target="_blank" rel="noopener noreferrer" className="btn-Download">
                                            Download
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>Nenhuma carta disponível.</p>
                        )}
                    </div>
                </div>

                <div className="div-Lattes">
                    <div>
                        <TextInput
                            label="Currículo Vitae Lattes:*"
                            id="Currículo Vitae Lattes:*"
                            name="curriculum"
                            value={curriculumUrl ? "Disponível para download" : "Nenhum currículo enviado."}
                            disabled
                        />
                    </div>
                    <div>
                        {curriculumUrl && (
                            <a className="btn-Download" target="_blank" href={curriculumUrl}>
                                Download
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

