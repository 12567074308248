import { useState } from "react";
import "./styles.scss";

interface ConfirmPopupNavigationProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onNext: () => Promise<void>; 
  typeSubmit?: boolean;
}

export function ConfirmPopupNavigation({ 
  isOpen, 
  onClose, 
  onBack, 
  onNext, 
  typeSubmit = true
}: ConfirmPopupNavigationProps) {
  
  const [isLoading, setIsLoading] = useState(false); 

  if (!isOpen) return null;

  const handleConfirm = async () => {
    setIsLoading(true); 
    try {
      await onNext(); 
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div className="overlay">
      <div className="modal">
        <p className="alert-text">
          <strong>Atenção:</strong> Uma vez que o formulário é submetido, não é possível modificar as informações dos candidatos já indicados. Revise com cautela.
        </p>

        <div className="buttons">
          {/* 🔹 Botão de voltar */}
          <button className="backButton" onClick={onClose}>
            <strong>VOLTAR</strong>
          </button>
          
          <button 
            className={`nextButton ${isLoading ? "loading" : ""}`} 
            type={typeSubmit ? "submit" : "button"} 
            onClick={handleConfirm} 
            disabled={isLoading} 
          >
            {isLoading ? "ENVIANDO..." : "CONFIRMAR ENVIO"}
          </button>
        </div>
      </div>
    </div>
  );
}
