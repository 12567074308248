import { useEffect, useState } from "react";
import { SideBarNavigator } from "../../components/SideBar";
import { Admin } from "../../core/Controllers/Admin";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ViewRegisterData } from "../../components/ViewRegisterData";
import { User } from "../../core/Controllers/User";

// import { serverTimestamp, addDoc, collection } from "firebase/firestore";
// import { firestoreDatabase } from '../../services/firebase';

// import data from './export-tecnologia-2022.json'

export function AdminPage() {

    const [data, setData] = useState<any>([])
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState<any>([])

    useEffect(() => {
        Admin().getUsersData().then((users: any) => setData(users))
    }, [])


    // useEffect(() => {
    //     data.forEach((item) => {
    //         addDoc(collection(firestoreDatabase, 'oldData'), {
    //             ...item, createdAt: serverTimestamp()
    //         })
    //     })
    // }, [])

    useEffect(() => {
        // User().getAllUsersFinalReport()
        // User().getAllInstitutionsDataForReport().then((data) => { console.table(data) })
        //User().getAllIndicationsDataForReport().then((data) => { console.table(data) })
    }, [])

    // useEffect(() => {
    //     User().exportAllUsersToCSV('Ciência').then((csvContent : any) => {
    //         if (!csvContent) return

    //         var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //         var url = URL.createObjectURL(blob);

    //         const link = document.createElement("a");

    //         link.setAttribute("href", url);
    //         link.setAttribute("download", "customers.csv");
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link); 
    //     })

    // }, [])


    async function handleEnframe(data: any) {
        const newData = { ...data, enframe: !data.enframe }

        Admin().enframe(newData)

        setData((old: any) => old.map((dataToUpdate: any) => dataToUpdate.uid === data.uid ? newData : dataToUpdate))

        setOpen(false)
    }

    return (
        <>
            <SideBarNavigator />

            <div style={{ marginLeft: 220 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, maxWidth: 1100 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Telefone</TableCell>
                                <TableCell align="right">Cadastro completo</TableCell>
                                <TableCell align="right">Enquadramento</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row: any) => {
                                let createdAtDate: any;

                                // Verifica se row.createdAt está definido antes de acessar suas propriedades
                                if (row.createdAt && row.createdAt.seconds !== undefined && row.createdAt.nanoseconds !== undefined) {
                                    createdAtDate = new Date(row.createdAt.seconds * 1000 + row.createdAt.nanoseconds / 1000000);
                                } else {
                                    createdAtDate = row.createdAt ? new Date(row.createdAt) : null;
                                }

                                if (createdAtDate && createdAtDate.getFullYear() === 2023) {
                                    return (
                                        <TableRow
                                            key={row.uid}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setRowData(row);
                                                setOpen(true);
                                            }}
                                        >
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.category}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell align="right">
                                                <Chip
                                                    label={row.completed ? 'Sim' : 'Não'}
                                                    color={row.completed ? 'primary' : 'warning'}
                                                    variant={row.completed ? 'filled' : 'outlined'}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.enframe
                                                    ? <Chip label={'Sim'} color={'success'} variant='filled' />
                                                    : row.enframe === false ? <Chip label={'Não'} color={'error'} variant='filled' /> : '-'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>


                <Dialog
                    maxWidth={'lg'}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>Dados da inscrição</DialogTitle>

                    <DialogContent>
                        {/* <DialogContentText>
                            You can set my maximum width and whether to adapt or not.
                        </DialogContentText> */}

                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >

                            <ViewRegisterData data={{ ...rowData }} />
                        </Box>
                    </DialogContent>

                    <DialogActions style={{ gap: 20, marginRight: 30 }}>
                        <Button onClick={() => handleEnframe(rowData)} color={!rowData.enframe ? "success" : "error"} variant="contained">{!rowData.enframe ? 'Enquadrar' : 'Retirar enquadro'}</Button>
                        <Button onClick={() => setOpen(false)} variant="contained" >Fechar</Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}

