
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../components/Button'
import { TextInput } from '../../components/TextInput'
import { User } from '../../core/Controllers/User'
import { useAuth } from '../../hooks/useAuth'
import { routesNames } from '../../routes/routesNames'
import { baseColors } from '../../styles'


import './styles.scss'

const validationSchema = yup.object({
	email: yup
		.string()
		.email('É preciso que o e-mail seja válido.')
		.required('É necessário preencher um e-mail válido para continuar.'),
	password: yup
		.string()
		.min(8, 'A senha precisa ter no mínimo 8 caracteres.')
		.required('É necessário preencher uma senha válida para continuar.'),
})


export function Login() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const [email, setEmail] = useState('')

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {


			User().getUsersDataByEmail(email)
				.then((dados) => {

					console.log(dados);

					if (dados[0] != 'user') {

						alert('Usuário não encontrado!.')

						return null;

					}

			User().login(values.email, values.password)
				.then(uid => User().getData(uid))
				.then((data) => {

					const dataToSave = { ...data } as any

					// console.log('dataToSave ', dataToSave)
					// console.log('dataToSave?.uid ', dataToSave?.uid)

					// if (!dataToSave?.uid) {
					// 	alert('Usuário não encontrado! Talvez você queira entrar pela sua instituição.')
					
					// 	throw new Error("Usuário não encontrado! Talvez você queira entrar pela sua instituição.");
					// }

					delete dataToSave?.password

					userContext.updateUser({
						...dataToSave
					})

					sessionStorage.setItem('dados[0]', 'user');

					return new Promise<any>((resolve, reject) => { setTimeout(resolve, 400) })

				})
				.then(() => navigate(routesNames.technologyPersonalData.path))
			})
		},
	})

	function handleResetPassword() {
		if (email) {
			alert('Enviamos um e-mail de alteração de senha para você.')
			User().sendPasswordReset(email)
		} else {
			alert('Preencha seu email para continuar.')
		}
	}

	return (

		<><><h1 className='textLoginTitle'>Bem-vindo ao sistema de inscrição do Prêmio CBMM 2025.</h1>
			<div className='formContainer'>

				<div>

					<h2 className='textLoginOne'>Já se cadastrou? <strong>Faça login e continue de onde parou.</strong></h2>

					<h2 className='textLogin'>Caso você tenha participado de edições anteriores, poderá usar o mesmo login e senha para acessar a plataforma.</h2>

				</div>

				<form onSubmit={formik.handleSubmit}>
					<div className='divInsc'>
					<span className="homeText">E-mail</span><TextInput
							fullWidth
							id="email"
							name="email"
							value={formik.values.email}
							onChange={(e: any) => {
								formik.handleChange(e)
								setEmail(e.target?.value)
							}}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email} />
					</div>
					<div className='divInsc'>
					<span className="homeText">Senha</span><TextInput
							fullWidth
							id="password"
							name="password"
							type="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password} />
					</div>


					<div className='row-gap' style={{ marginTop: formik.touched.password && Boolean(formik.errors.password) ? 20 : 40, justifyContent: 'flex-end' }}>

						<button className='resetPassword' onClick={handleResetPassword} type="button">
							Esqueceu sua senha?
						</button>

						<Button
							style={{
								cursor: 'pointer',
								padding: '10px 100px 10px 100px',
								fontWeight: 800,
								fontSize: 16,
								fontFamily: "Open Sans",
								textAlign: 'center',
							}}

							label="LOGIN"
							color={baseColors.lightIndigo} />

					</div>
				</form>
			</div>

			<div className='divEnd'>
				<h2 className='textLogin'>
					Se ainda não for cadastrado,{' '}
					<strong>
						<span
							className='btnInscrevase'
							onClick={() => navigate(routesNames.home.path)}
						>
							INSCREVA-SE
						</span>
					</strong>
				</h2>

			</div>
		</>

		</>
	)
}
