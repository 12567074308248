import { Navigate, Outlet } from "react-router-dom";
import { routesNames } from '../../routes/routesNames';

export const ProtectedInstitutionRoute = () => {
    
    const isInstitution = sessionStorage.getItem('dados[0]') === 'institution';
    
    if (!isInstitution) {
      console.log("Redirecionando para:", routesNames.login.path);
      return <Navigate to={routesNames.login.path} replace />;
    }
    
    return <Outlet />;
  };