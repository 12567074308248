
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import * as yup from 'yup'



import { Button } from '../../components/Button'
import { ButtonNewCand } from '../../components/ButtonNewCand'
import { ButtonRemoverCand } from '../../components/ButtonRemoverCand'
import { ButtonVoltar } from '../../components/ButtonVoltar'
import { ButtonSalvar } from '../../components/ButtonSalvar'
import { ConfirmPopupNavigation } from '../../components/ConfirmPopupNavigation/ConfirmPopupNavigation'
import { ButtonStages } from '../../components/ButtonStages'
import { ButtonSubmeter } from '../../components/ButtonSubmeter'
import { ConfirmPopupIndication } from '../../components/ConfirmPopupIndication'
import { EditableTextInput } from '../../components/EditableTextInput'
import { EditableTextInputCnpj } from '../../components/EditableTextInputCnpj'
import { TextInput } from '../../components/TextInput'
import { TextInputMask } from '../../components/TextInputMask'
import { TextInputMaskNew } from '../../components/TextInputMaskNew'
import { Institution } from '../../core/Controllers/Institution'
import { User } from '../../core/Controllers/User'
import { ConfirmPopupInst } from '../../components/ConfirmPopupInst'
import { useAuth } from '../../hooks/useAuth'
import { routesNames } from '../../routes/routesNames'
import { CepService } from '../../services/cep'
import { baseColors } from '../../styles'


import './styles.scss'




const estadosBrasil = [
	{
		id: 12,
		sigla: 'AC',
		nome: 'Acre',
	},
	{
		id: 27,
		sigla: 'AL',
		nome: 'Alagoas',
	},
	{
		id: 16,
		sigla: 'AP',
		nome: 'Amapá',
	},
	{
		id: 13,
		sigla: 'AM',
		nome: 'Amazonas',
	},
	{
		id: 29,
		sigla: 'BA',
		nome: 'Bahia',
	},
	{
		id: 23,
		sigla: 'CE',
		nome: 'Ceará',
	},
	{
		id: 53,
		sigla: 'DF',
		nome: 'Distrito Federal',
	},
	{
		id: 32,
		sigla: 'ES',
		nome: 'Espírito Santo',
	},
	{
		id: 52,
		sigla: 'GO',
		nome: 'Goiás',
	},
	{
		id: 21,
		sigla: 'MA',
		nome: 'Maranhão',
	},
	{
		id: 51,
		sigla: 'MT',
		nome: 'Mato Grosso',
	},
	{
		id: 50,
		sigla: 'MS',
		nome: 'Mato Grosso do Sul',
	},
	{
		id: 31,
		sigla: 'MG',
		nome: 'Minas Gerais',
	},
	{
		id: 15,
		sigla: 'PA',
		nome: 'Pará',
	},
	{
		id: 25,
		sigla: 'PB',
		nome: 'Paraíba',
	},
	{
		id: 41,
		sigla: 'PR',
		nome: 'Paraná',
	},
	{
		id: 26,
		sigla: 'PE',
		nome: 'Pernambuco',
	},
	{
		id: 22,
		sigla: 'PI',
		nome: 'Piauí',
	},
	{
		id: 33,
		sigla: 'RJ',
		nome: 'Rio de Janeiro',
	},
	{
		id: 24,
		sigla: 'RN',
		nome: 'Rio Grande do Norte',
	},
	{
		id: 43,
		sigla: 'RS',
		nome: 'Rio Grande do Sul',
	},
	{
		id: 11,
		sigla: 'RO',
		nome: 'Rondônia',
	},
	{
		id: 14,
		sigla: 'RR',
		nome: 'Roraima',
	},
	{
		id: 42,
		sigla: 'SC',
		nome: 'Santa Catarina',
	},
	{
		id: 35,
		sigla: 'SP',
		nome: 'São Paulo',
	},
	{
		id: 28,
		sigla: 'SE',
		nome: 'Sergipe',
	},
	{
		id: 17,
		sigla: 'TO',
		nome: 'Tocantins',
	},
]


const validationSchema_1 = yup.object({
	name_1: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_1: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_1: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_1: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_1: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})

const validationSchema_2 = yup.object({
	name_2: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_2: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_2: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_2: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_2: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')
})

const validationSchema_3 = yup.object({
	name_3: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_3: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_3: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_3: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_3: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')
})

const validationSchema_4 = yup.object({
	name_4: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_4: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_4: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_4: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_4: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})

const validationSchema_5 = yup.object({
	name_5: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_5: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_5: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_5: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_5: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')
})

const validationSchema_6 = yup.object({
	name_6: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_6: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_6: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_6: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_6: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})


const validationSchema_7 = yup.object({
	name_7: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_7: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_7: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_7: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_7: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})

const validationSchema_8 = yup.object({
	name_8: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_8: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_8: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_8: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_8: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})

const validationSchema_9 = yup.object({
	name_9: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_9: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_9: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_9: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_9: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})


const validationSchema_10 = yup.object({
	name_10: yup
		.string()
		.required('É necessário preencher o Nome do candidato para continuar.'),
	email_10: yup
		.string()
		.required('É necessário preencher o e-mail do candidato para continuar.'),
	phoneNumber_10: yup
		.string()
		.required('É necessário preencher o telefone do candidato para continuar.'),
	category_10: yup
		.string()
		.required('É necessário selecionar uma categoria para continuar.')
		.oneOf(['Ciência', 'Tecnologia']),
	cotitularidade_10: yup
		.string()
	// .required('É necessário preencher o campo do candidato para continuar.')

})


const validationSchema = (totalIndic: number) => {
	if (totalIndic === 0) {
		return validationSchema_1
	}

	if (totalIndic === 1) {
		return validationSchema_1.concat(validationSchema_2)
	}

	if (totalIndic === 2) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3)
	}

	if (totalIndic === 3) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4)
	}

	if (totalIndic === 4) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4).concat(validationSchema_5)
	}

	if (totalIndic === 5) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4).concat(validationSchema_5).concat(validationSchema_6)
	}

	if (totalIndic === 6) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4).concat(validationSchema_5).concat(validationSchema_6).concat(validationSchema_7)
	}

	if (totalIndic === 7) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4).concat(validationSchema_5).concat(validationSchema_6).concat(validationSchema_7).concat(validationSchema_8)
	}

	if (totalIndic === 8) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4).concat(validationSchema_5).concat(validationSchema_6).concat(validationSchema_7).concat(validationSchema_8).concat(validationSchema_9)
	}

	if (totalIndic === 9) {
		return validationSchema_1.concat(validationSchema_2).concat(validationSchema_3).concat(validationSchema_4).concat(validationSchema_5).concat(validationSchema_6).concat(validationSchema_7).concat(validationSchema_8).concat(validationSchema_9).concat(validationSchema_10)
	}
}

export function InstitutionIndication() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const ReloadButton = () => {
		const reloadPage = () => {
			window.location.reload();
		};
	}

	const UserLogOut = async function () {
		await User().logout();
		return window.location.href = '/entrar-instituicao';
	};

	const [totalIndications, setTotalIndications] = useState(0)
	const [userPersonalData, setUserPersonalData] = useState<any>({})

	const [indicationsData, setIndicationsData] = useState<any>({})
	const [show, setShow] = useState<null | true | false>(null)

	const formik = useFormik({
		initialValues: {

			name_1: '',
			email_1: '',
			phoneNumber_1: '',
			category_1: '',
			cotitularidade_1: '',

			name_2: '',
			email_2: '',
			phoneNumber_2: '',
			category_2: '',
			cotitularidade_2: '',

			name_3: '',
			email_3: '',
			phoneNumber_3: '',
			category_3: '',
			cotitularidade_3: '',

			name_4: '',
			email_4: '',
			phoneNumber_4: '',
			category_4: '',
			cotitularidade_4: '',

			name_5: '',
			email_5: '',
			phoneNumber_5: '',
			category_5: '',
			cotitularidade_5: '',

			name_6: '',
			email_6: '',
			phoneNumber_6: '',
			category_6: '',
			cotitularidade_6: '',


			name_7: '',
			email_7: '',
			phoneNumber_7: '',
			category_7: '',
			cotitularidade_7: '',


			name_8: '',
			email_8: '',
			phoneNumber_8: '',
			category_8: '',
			cotitularidade_8: '',


			name_9: '',
			email_9: '',
			phoneNumber_9: '',
			category_9: '',
			cotitularidade_9: '',


			name_10: '',
			email_10: '',
			phoneNumber_10: '',
			category_10: '',
			cotitularidade_10: '',

		},
		validationSchema: validationSchema(totalIndications),
		onSubmit: async (values) => {

			const candidate_1 = (({ name_1, email_1, phoneNumber_1, category_1, cotitularidade_1
			}) => ({
				name_1, email_1, phoneNumber_1, category_1, cotitularidade_1
			}))(values);

			const candidate_2 = (({ name_2, email_2, phoneNumber_2, category_2, cotitularidade_2
			}) => ({
				name_2, email_2, phoneNumber_2, category_2, cotitularidade_2
			}))(values);

			const candidate_3 = (({ name_3, email_3, phoneNumber_3, category_3, cotitularidade_3
			}) => ({
				name_3, email_3, phoneNumber_3, category_3, cotitularidade_3
			}))(values);

			const candidate_4 = (({ name_4, email_4, phoneNumber_4, category_4, cotitularidade_4
			}) => ({
				name_4, email_4, phoneNumber_4, category_4, cotitularidade_4
			}))(values);

			const candidate_5 = (({ name_5, email_5, phoneNumber_5, category_5, cotitularidade_5
			}) => ({
				name_5, email_5, phoneNumber_5, category_5, cotitularidade_5
			}))(values);

			const candidate_6 = (({ name_6, email_6, phoneNumber_6, category_6, cotitularidade_6
			}) => ({
				name_6, email_6, phoneNumber_6, category_6, cotitularidade_6
			}))(values);

			const candidate_7 = (({ name_7, email_7, phoneNumber_7, category_7, cotitularidade_7
			}) => ({
				name_7, email_7, phoneNumber_7, category_7, cotitularidade_7
			}))(values);

			const candidate_8 = (({ name_8, email_8, phoneNumber_8, category_8, cotitularidade_8
			}) => ({
				name_8, email_8, phoneNumber_8, category_8, cotitularidade_8
			}))(values);

			const candidate_9 = (({ name_9, email_9, phoneNumber_9, category_9, cotitularidade_9
			}) => ({
				name_9, email_9, phoneNumber_9, category_9, cotitularidade_9
			}))(values);

			const candidate_10 = (({ name_10, email_10, phoneNumber_10, category_10, cotitularidade_10
			}) => ({
				name_10, email_10, phoneNumber_10, category_10, cotitularidade_10
			}))(values);




			// e12a3579e02ba536d2b58bf8f12d66dd-us7

			let axiosConfig = {
				headers: {
					'authorization': "Basic e12a3579e02ba536d2b58bf8f12d66dd-us7",
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				}
			};

			let data = {
				email_address: 'uriel@lcomunicacao.com',
				status: "subscribed"
			};





			let urls = [
				'https://api.lcomunicacao.com/index.php?email=' + userContext.user.email + '&tag=2025-Fez indicação&atualizar=1',
				'https://api.lcomunicacao.com/index.php?email=' + candidate_1.email_1 + '&tag=2025-Foi indicado&phone=' + candidate_1.phoneNumber_1 + '&nome=' + candidate_1.name_1,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_2.email_2 + '&tag=2025-Foi indicado&phone=' + candidate_2.phoneNumber_2 + '&nome=' + candidate_2.name_2,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_3.email_3 + '&tag=2025-Foi indicado&phone=' + candidate_3.phoneNumber_3 + '&nome=' + candidate_3.name_3,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_4.email_4 + '&tag=2025-Foi indicado&phone=' + candidate_4.phoneNumber_4 + '&nome=' + candidate_4.name_4,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_5.email_5 + '&tag=2025-Foi indicado&phone=' + candidate_5.phoneNumber_5 + '&nome=' + candidate_5.name_5,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_6.email_6 + '&tag=2025-Foi indicado&phone=' + candidate_6.phoneNumber_6 + '&nome=' + candidate_6.name_6,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_7.email_7 + '&tag=2025-Foi indicado&phone=' + candidate_7.phoneNumber_7 + '&nome=' + candidate_7.name_7,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_8.email_8 + '&tag=2025-Foi indicado&phone=' + candidate_8.phoneNumber_8 + '&nome=' + candidate_8.name_8,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_9.email_9 + '&tag=2025-Foi indicado&phone=' + candidate_9.phoneNumber_9 + '&nome=' + candidate_9.name_9,
				'https://api.lcomunicacao.com/index.php?email=' + candidate_10.email_10 + '&tag=2025-Foi indicado&phone=' + candidate_10.phoneNumber_10 + '&nome=' + candidate_10.name_10,
			]

			// 'https://cbmm.lcomunicacao.com/?' + candidate_1.email_1 + '&tag=INSTITUIÇÕES_2024'


			const requests = urls.map((url) => axios.get(url));

			console.log(requests);

			axios.all(requests)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				})



			// 			axios.post('https://cbmm.lcomunicacao.com/?' + candidate_1.email_1 + '&tag=INSTITUIÇÕES_2024',  data, axiosConfig)
			//   .then((response) => {
			//     console.log(response);
			//   })
			//   .catch((error) => {
			//     console.log(error);
			//   })

			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_1_2025`, institutionId: userContext?.user?.uid, ...candidate_1, completed_1: !!candidate_1.email_1, completed: !!candidate_1.email_1 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_2_2025`, institutionId: userContext?.user?.uid, ...candidate_2, completed_2: !!candidate_2.email_2, completed: !!candidate_2.email_2 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_3_2025`, institutionId: userContext?.user?.uid, ...candidate_3, completed_3: !!candidate_3.email_3, completed: !!candidate_3.email_3 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_4_2025`, institutionId: userContext?.user?.uid, ...candidate_4, completed_4: !!candidate_4.email_4, completed: !!candidate_4.email_4 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_5_2025`, institutionId: userContext?.user?.uid, ...candidate_5, completed_5: !!candidate_5.email_5, completed: !!candidate_5.email_5 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_6_2025`, institutionId: userContext?.user?.uid, ...candidate_6, completed_6: !!candidate_6.email_6, completed: !!candidate_6.email_6 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_7_2025`, institutionId: userContext?.user?.uid, ...candidate_7, completed_7: !!candidate_7.email_7, completed: !!candidate_7.email_7 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_8_2025`, institutionId: userContext?.user?.uid, ...candidate_8, completed_8: !!candidate_8.email_8, completed: !!candidate_8.email_8 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_9_2025`, institutionId: userContext?.user?.uid, ...candidate_9, completed_9: !!candidate_9.email_9, completed: !!candidate_9.email_9 })
			await Institution().createIndicated({ docId: `${userContext?.user?.uid}_10_2025`, institutionId: userContext?.user?.uid, ...candidate_10, completed_10: !!candidate_10.email_10, completed: !!candidate_10.email_10 })




			setTimeout(() => {
				window.location.href = '/obrigado-pela-indicacao';
			}, 500);
		},
	})




	useEffect(() => {
		if (userContext?.user) {
			Object.keys(userContext?.user?.personalData || {}).forEach(key => {
				formik.setFieldValue(key, userContext?.user?.personalData[key])
			})
		}
		if (userContext?.user) {
			setUserPersonalData(userContext?.user)
		}

		Institution().getIndications(userContext?.user.uid).then((indications) => {

			if (indications._total) {
				Object.keys(indications || {}).forEach(key => {
					formik.setFieldValue(key, (indications as any)[key])
				})
				setIndicationsData(indications)
				setTotalIndications(indications._total)
			}
		})
	}, [userContext?.user])

	const handleRadioChange = (event: { target: { value: any } }) => {
		const category = event.target.value;

		// Contar o número de seleções para a categoria específica
		const selectedCountForCategory = Object.values(formik.values)
			.filter((value) => value === category && value !== '')
			.length;

		// Limitar a seleção apenas se o número atual de seleções for menor que 5
		if (selectedCountForCategory < 5) {
			formik.handleChange(event);
		}
	};

	const handleRemoveCandidate = () => {
		if (totalIndications > 0) {
			// Clona os valores existentes
			const updatedValues = { ...formik.values };

			// Obtém o índice do último candidato visível
			let candidateIndex = totalIndications;

			// Verifica se o candidato pode ser removido
			if (indicationsData[`completed_${candidateIndex + 1}` as keyof typeof indicationsData]) {
				console.log(`Candidato ${candidateIndex} já foi enviado. Remoção bloqueada.`);
				return;
			}

			console.log(`Removendo candidato ${candidateIndex}, pois ainda não foi enviado.`);

			// Removendo apenas os dados do candidato excluído sem alterar os anteriores
			([
				`name_${candidateIndex + 1}`,
				`email_${candidateIndex + 1}`,
				`phoneNumber_${candidateIndex + 1}`,
				`category_${candidateIndex + 1}`,
				`cotitularidade_${candidateIndex + 1}`
			] as (keyof typeof formik.values)[]).forEach(field => {
				updatedValues[field] = ''; // Zera os valores sem repassar para o candidato anterior
			});

			// Atualiza os valores no Formik garantindo que os anteriores não sejam sobrescritos
			formik.setValues(updatedValues);

			// Reduz o número de candidatos
			setTotalIndications(old => Math.max(0, old - 1));
		}
	};






	function updateUserInfo(name: string, value: string) {
		userContext.updateUser({
			[name]: value
		})
	}

	return (


		<><h1 className='textLoginTitleCand'>Formulário de indicação</h1><div className='formContainerCand'>

			<div className="personalDataEditContainer">


				<div className='bgDivDados instituicao'><EditableTextInput label={'Nome'} value={userPersonalData.name} onChange={(value) => updateUserInfo('name', value)} /></div>

				<div className='bgDivDados instituicao'><EditableTextInput label={'Email'} value={userPersonalData.email} onChange={(value) => updateUserInfo('email', value)} /></div>

				<div className='bgDivDados instituicao'><EditableTextInputCnpj
					label={'CNPJ'}
					value={userPersonalData.cnpj}
					onChange={(value) => updateUserInfo('cnpj', value)}
					mask="##.###.###/####-##" // 42.698.503/0001-22
					definitions={{
						'#': /[0-9]/,
					}}
				/>
				</div>

				<div className='bgDivDados instituicao'><EditableTextInput
					label={'Telefone'}
					value={userPersonalData.phoneNumber}
					onChange={(value) => updateUserInfo('phoneNumber', value)}
					mask="(##) # ####-####"
					definitions={{
						'#': /[0-9]/,
					}} /></div>


				<div className='bgDivDados instituicao'><EditableTextInput label={'Nome da instituição'} value={userPersonalData.institutionName} onChange={(value) => updateUserInfo('institutionName', value)} /></div>

				{/* <div className='bgDivDados instituicao'><p className="personalDataEditRowCand">Instituição:<EditableTextInput label={'Nome da instituição'} value={userPersonalData.institutionName} onChange={(value) => updateUserInfo('institutionName', value)} /></p></div> */}


				{/* <p className="personalDataEditRow"><strong>Email:</strong> {userPersonalData.email} </p>
    			<p className="personalDataEditRow"><strong>CNPJ:</strong> {userPersonalData.cnpj} </p>
    			p className="personalDataEditRow"><strong>Nome da instituição:</strong> {userPersonalData.institutionName} </p> */}

				{/* <EditableTextInput
        label={'Telefone'}
        value={userPersonalData.phoneNumber}
        onChange={(value) => updateUserInfo('phoneNumber', value)}
        mask="(##) # ####-####"
        definitions={{
            '#': /[0-9]/,
        }}
    />

     <EditableTextInput
        select={['Ciência', 'Tecnologia']}
        label={'Categoria'}
        value={userPersonalData.category}
        onChange={(value) => updateUserInfo('category', value)}
    />  */}
			</div>

			<div className='divtextCand'>
				<h2 className='textRegisterIndic'>Você poderá indicar até <strong>cinco candidatos</strong> por categoria – Ciência ou Tecnologia – para concorrer ao prêmio, <strong>totalizando dez indicações.</strong></h2>
			</div>

			<form onSubmit={formik.handleSubmit}>
				{/* <Button
        label="Salvar e sair"
        type="outline"
        style={{position: 'absolute', top: 0, right: 0}}
        onClick={() => {
            setTimeout(() => {
                userContext?.signOutUser()
            }, 200);
            setTimeout(() => {
                window.location.replace("https://premiocbmm.com.br/")
            }, 300);
        }}
    /> */}


				{totalIndications >= 0 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 1</h4>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_1"
							name="name_1"
							value={formik.values.name_1}
							onChange={formik.handleChange}
							error={formik.touched.name_1 && Boolean(formik.errors.name_1)}
							helperText={formik.touched.name_1 && formik.errors.name_1}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_1"
							name="cotitularidade_1"
							value={formik.values.cotitularidade_1}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_1 && Boolean(formik.errors.cotitularidade_1)}
							helperText={formik.touched.cotitularidade_1 && formik.errors.cotitularidade_1}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span><TextInput
							fullWidth
							id="email_1"
							name="email_1"
							value={formik.values.email_1}
							onChange={formik.handleChange}
							error={formik.touched.email_1 && Boolean(formik.errors.email_1)}
							helperText={formik.touched.email_1 && formik.errors.email_1}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div>

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span><TextInputMaskNew
							fullWidth
							id="phoneNumber_1"
							name="phoneNumber_1"
							value={formik.values.phoneNumber_1}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_1 && Boolean(formik.errors.phoneNumber_1)}
							helperText={formik.touched.phoneNumber_1 && formik.errors.phoneNumber_1}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}} />
					</div>

					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_1}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_1" disabled={indicationsData.completed_1} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_1" disabled={indicationsData.completed_1} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_1 && Boolean(formik.errors.category_1) && <p className='errorHelperText'>{formik.errors.category_1}</p>}
					</FormGroup>

					<ConfirmPopupInst />



				</div>}



				{totalIndications >= 1 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 2</h4>

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_2"
							name="name_2"
							value={formik.values.name_2}
							onChange={formik.handleChange}
							error={formik.touched.name_2 && Boolean(formik.errors.name_2)}
							helperText={formik.touched.name_2 && formik.errors.name_2}
							disabled={indicationsData.completed_2}
							InputLabelProps={{ shrink: indicationsData.completed_2 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_2"
							name="cotitularidade_2"
							value={formik.values.cotitularidade_2}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_2 && Boolean(formik.errors.cotitularidade_2)}
							helperText={formik.touched.cotitularidade_2 && formik.errors.cotitularidade_2}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span><TextInput
							fullWidth
							id="email_2"
							name="email_2"
							value={formik.values.email_2}
							onChange={formik.handleChange}
							error={formik.touched.email_2 && Boolean(formik.errors.email_2)}
							helperText={formik.touched.email_2 && formik.errors.email_2}
							disabled={indicationsData.completed_2}
							InputLabelProps={{ shrink: indicationsData.completed_2 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span><TextInputMaskNew
							fullWidth
							id="phoneNumber_2"
							name="phoneNumber_2"
							value={formik.values.phoneNumber_2}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_2 && Boolean(formik.errors.phoneNumber_2)}
							helperText={formik.touched.phoneNumber_2 && formik.errors.phoneNumber_2}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_2}
							InputLabelProps={{ shrink: indicationsData.completed_2 }} />
					</div>


					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_2}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_2" disabled={indicationsData.completed_2} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_2" disabled={indicationsData.completed_2} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_2 && Boolean(formik.errors.category_2) && <p className='errorHelperText'>{formik.errors.category_2}</p>}
					</FormGroup>



				</div>}



				{totalIndications >= 2 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 3</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_3"
							name="name_3"
							value={formik.values.name_3}
							onChange={formik.handleChange}
							error={formik.touched.name_3 && Boolean(formik.errors.name_3)}
							helperText={formik.touched.name_3 && formik.errors.name_3}
							disabled={indicationsData.completed_3}
							InputLabelProps={{ shrink: indicationsData.completed_3 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_3"
							name="cotitularidade_3"
							value={formik.values.cotitularidade_3}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_3 && Boolean(formik.errors.cotitularidade_3)}
							helperText={formik.touched.cotitularidade_3 && formik.errors.cotitularidade_3}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span><TextInput
							fullWidth
							id="email_3"
							name="email_3"
							value={formik.values.email_3}
							onChange={formik.handleChange}
							error={formik.touched.email_3 && Boolean(formik.errors.email_3)}
							helperText={formik.touched.email_3 && formik.errors.email_3}
							disabled={indicationsData.completed_3}
							InputLabelProps={{ shrink: indicationsData.completed_3 }} />
					</div>

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span><TextInputMaskNew
							fullWidth
							id="phoneNumber_3"
							name="phoneNumber_3"

							value={formik.values.phoneNumber_3}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_3 && Boolean(formik.errors.phoneNumber_3)}
							helperText={formik.touched.phoneNumber_3 && formik.errors.phoneNumber_3}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_3}
							InputLabelProps={{ shrink: indicationsData.completed_3 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_3}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_3" disabled={indicationsData.completed_3} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_3" disabled={indicationsData.completed_3} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_3 && Boolean(formik.errors.category_3) && <p className='errorHelperText'>{formik.errors.category_3}</p>}
					</FormGroup>

				</div>}



				{totalIndications >= 3 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 4</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_4"
							name="name_4"
							value={formik.values.name_4}
							onChange={formik.handleChange}
							error={formik.touched.name_4 && Boolean(formik.errors.name_4)}
							helperText={formik.touched.name_4 && formik.errors.name_4}
							disabled={indicationsData.completed_4}
							InputLabelProps={{ shrink: indicationsData.completed_4 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_4"
							name="cotitularidade_4"
							value={formik.values.cotitularidade_4}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_4 && Boolean(formik.errors.cotitularidade_4)}
							helperText={formik.touched.cotitularidade_4 && formik.errors.cotitularidade_4}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span><TextInput
							fullWidth
							id="email_4"
							name="email_4"
							value={formik.values.email_4}
							onChange={formik.handleChange}
							error={formik.touched.email_4 && Boolean(formik.errors.email_4)}
							helperText={formik.touched.email_4 && formik.errors.email_4}
							disabled={indicationsData.completed_4}
							InputLabelProps={{ shrink: indicationsData.completed_4 }} />
					</div>

					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span><TextInputMaskNew
							fullWidth
							id="phoneNumber_4"
							name="phoneNumber_4"
							value={formik.values.phoneNumber_4}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_4 && Boolean(formik.errors.phoneNumber_4)}
							helperText={formik.touched.phoneNumber_4 && formik.errors.phoneNumber_4}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_4}
							InputLabelProps={{ shrink: indicationsData.completed_4 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_4}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_4" disabled={indicationsData.completed_4} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_4" disabled={indicationsData.completed_4} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_4 && Boolean(formik.errors.category_4) && <p className='errorHelperText'>{formik.errors.category_4}</p>}
					</FormGroup>

				</div>}



				{totalIndications >= 4 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 5</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_5"
							name="name_5"
							value={formik.values.name_5}
							onChange={formik.handleChange}
							error={formik.touched.name_5 && Boolean(formik.errors.name_5)}
							helperText={formik.touched.name_5 && formik.errors.name_5}
							disabled={indicationsData.completed_5}
							InputLabelProps={{ shrink: indicationsData.completed_5 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_5"
							name="cotitularidade_5"
							value={formik.values.cotitularidade_5}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_5 && Boolean(formik.errors.cotitularidade_5)}
							helperText={formik.touched.cotitularidade_5 && formik.errors.cotitularidade_5}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span>
						<TextInput
							fullWidth
							id="email_5"
							name="email_5"
							value={formik.values.email_5}
							onChange={formik.handleChange}
							error={formik.touched.email_5 && Boolean(formik.errors.email_5)}
							helperText={formik.touched.email_5 && formik.errors.email_5}
							disabled={indicationsData.completed_5}
							InputLabelProps={{ shrink: indicationsData.completed_5 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span>
						<TextInputMaskNew
							fullWidth
							id="phoneNumber_5"
							name="phoneNumber_5"
							value={formik.values.phoneNumber_5}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_5 && Boolean(formik.errors.phoneNumber_5)}
							helperText={formik.touched.phoneNumber_5 && formik.errors.phoneNumber_5}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_5}
							InputLabelProps={{ shrink: indicationsData.completed_5 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_5}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_5" disabled={indicationsData.completed_5} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_5" disabled={indicationsData.completed_5} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_5 && Boolean(formik.errors.category_5) && <p className='errorHelperText'>{formik.errors.category_5}</p>}
					</FormGroup>

				</div>}


				{totalIndications >= 5 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 6</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_6"
							name="name_6"
							value={formik.values.name_6}
							onChange={formik.handleChange}
							error={formik.touched.name_6 && Boolean(formik.errors.name_6)}
							helperText={formik.touched.name_6 && formik.errors.name_6}
							disabled={indicationsData.completed_6}
							InputLabelProps={{ shrink: indicationsData.completed_6 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_6"
							name="cotitularidade_6"
							value={formik.values.cotitularidade_6}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_6 && Boolean(formik.errors.cotitularidade_6)}
							helperText={formik.touched.cotitularidade_6 && formik.errors.cotitularidade_6}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span>
						<TextInput
							fullWidth
							id="email_6"
							name="email_6"
							value={formik.values.email_6}
							onChange={formik.handleChange}
							error={formik.touched.email_6 && Boolean(formik.errors.email_6)}
							helperText={formik.touched.email_6 && formik.errors.email_6}
							disabled={indicationsData.completed_6}
							InputLabelProps={{ shrink: indicationsData.completed_6 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span>
						<TextInputMaskNew
							fullWidth
							id="phoneNumber_6"
							name="phoneNumber_6"
							value={formik.values.phoneNumber_6}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_6 && Boolean(formik.errors.phoneNumber_6)}
							helperText={formik.touched.phoneNumber_6 && formik.errors.phoneNumber_6}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_6}
							InputLabelProps={{ shrink: indicationsData.completed_6 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_6}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_6" disabled={indicationsData.completed_6} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_6" disabled={indicationsData.completed_6} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_6 && Boolean(formik.errors.category_6) && <p className='errorHelperText'>{formik.errors.category_6}</p>}
					</FormGroup>

				</div>}


				{totalIndications >= 6 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 7</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_7"
							name="name_7"
							value={formik.values.name_7}
							onChange={formik.handleChange}
							error={formik.touched.name_7 && Boolean(formik.errors.name_7)}
							helperText={formik.touched.name_7 && formik.errors.name_7}
							disabled={indicationsData.completed_7}
							InputLabelProps={{ shrink: indicationsData.completed_7 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_7"
							name="cotitularidade_7"
							value={formik.values.cotitularidade_7}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_7 && Boolean(formik.errors.cotitularidade_7)}
							helperText={formik.touched.cotitularidade_7 && formik.errors.cotitularidade_7}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span>
						<TextInput
							fullWidth
							id="email_7"
							name="email_7"
							value={formik.values.email_7}
							onChange={formik.handleChange}
							error={formik.touched.email_7 && Boolean(formik.errors.email_7)}
							helperText={formik.touched.email_7 && formik.errors.email_7}
							disabled={indicationsData.completed_7}
							InputLabelProps={{ shrink: indicationsData.completed_7 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span>
						<TextInputMaskNew
							fullWidth
							id="phoneNumber_7"
							name="phoneNumber_7"
							value={formik.values.phoneNumber_7}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_7 && Boolean(formik.errors.phoneNumber_7)}
							helperText={formik.touched.phoneNumber_7 && formik.errors.phoneNumber_7}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_7}
							InputLabelProps={{ shrink: indicationsData.completed_7 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_7}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_7" disabled={indicationsData.completed_7} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_7" disabled={indicationsData.completed_7} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_7 && Boolean(formik.errors.category_7) && <p className='errorHelperText'>{formik.errors.category_7}</p>}
					</FormGroup>

				</div>}


				{totalIndications >= 7 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 8</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_8"
							name="name_8"
							value={formik.values.name_8}
							onChange={formik.handleChange}
							error={formik.touched.name_8 && Boolean(formik.errors.name_8)}
							helperText={formik.touched.name_8 && formik.errors.name_8}
							disabled={indicationsData.completed_8}
							InputLabelProps={{ shrink: indicationsData.completed_8 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_8"
							name="cotitularidade_8"
							value={formik.values.cotitularidade_8}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_8 && Boolean(formik.errors.cotitularidade_8)}
							helperText={formik.touched.cotitularidade_8 && formik.errors.cotitularidade_8}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span>
						<TextInput
							fullWidth
							id="email_8"
							name="email_8"
							value={formik.values.email_8}
							onChange={formik.handleChange}
							error={formik.touched.email_8 && Boolean(formik.errors.email_8)}
							helperText={formik.touched.email_8 && formik.errors.email_8}
							disabled={indicationsData.completed_8}
							InputLabelProps={{ shrink: indicationsData.completed_8 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span>
						<TextInputMaskNew
							fullWidth
							id="phoneNumber_8"
							name="phoneNumber_8"
							value={formik.values.phoneNumber_8}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_8 && Boolean(formik.errors.phoneNumber_8)}
							helperText={formik.touched.phoneNumber_8 && formik.errors.phoneNumber_8}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_8}
							InputLabelProps={{ shrink: indicationsData.completed_8 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_8}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_8" disabled={indicationsData.completed_8} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_8" disabled={indicationsData.completed_8} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_8 && Boolean(formik.errors.category_8) && <p className='errorHelperText'>{formik.errors.category_8}</p>}
					</FormGroup>

				</div>}

				{totalIndications >= 8 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 9</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_9"
							name="name_9"
							value={formik.values.name_9}
							onChange={formik.handleChange}
							error={formik.touched.name_9 && Boolean(formik.errors.name_9)}
							helperText={formik.touched.name_9 && formik.errors.name_9}
							disabled={indicationsData.completed_9}
							InputLabelProps={{ shrink: indicationsData.completed_9 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_9"
							name="cotitularidade_9"
							value={formik.values.cotitularidade_9}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_9 && Boolean(formik.errors.cotitularidade_9)}
							helperText={formik.touched.cotitularidade_9 && formik.errors.cotitularidade_9}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span>
						<TextInput
							fullWidth
							id="email_9"
							name="email_9"
							value={formik.values.email_9}
							onChange={formik.handleChange}
							error={formik.touched.email_9 && Boolean(formik.errors.email_9)}
							helperText={formik.touched.email_9 && formik.errors.email_9}
							disabled={indicationsData.completed_9}
							InputLabelProps={{ shrink: indicationsData.completed_9 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span>
						<TextInputMaskNew
							fullWidth
							id="phoneNumber_9"
							name="phoneNumber_9"
							value={formik.values.phoneNumber_9}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_9 && Boolean(formik.errors.phoneNumber_9)}
							helperText={formik.touched.phoneNumber_9 && formik.errors.phoneNumber_9}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_9}
							InputLabelProps={{ shrink: indicationsData.completed_9 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_9}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_9" disabled={indicationsData.completed_9} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_9" disabled={indicationsData.completed_9} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_9 && Boolean(formik.errors.category_9) && <p className='errorHelperText'>{formik.errors.category_9}</p>}
					</FormGroup>

				</div>}


				{totalIndications >= 9 && <div className='divConteudoCand'>
					<h4 className='candTextNumber'>Candidato 10</h4>



					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Nome do candidato:</span><TextInput
							fullWidth
							id="name_10"
							name="name_10"
							value={formik.values.name_10}
							onChange={formik.handleChange}
							error={formik.touched.name_10 && Boolean(formik.errors.name_10)}
							helperText={formik.touched.name_10 && formik.errors.name_10}
							disabled={indicationsData.completed_10}
							InputLabelProps={{ shrink: indicationsData.completed_10 }} />
					</div>

					{/* <div className='divInsc insituicao'>
						<span className="nameCandit candText">Co-titularidade:</span><TextInput
							fullWidth
							id="cotitularidade_10"
							name="cotitularidade_10"
							value={formik.values.cotitularidade_10}
							onChange={formik.handleChange}
							error={formik.touched.cotitularidade_10 && Boolean(formik.errors.cotitularidade_10)}
							helperText={formik.touched.cotitularidade_10 && formik.errors.cotitularidade_10}
							disabled={indicationsData.completed_1}
							InputLabelProps={{ shrink: indicationsData.completed_1 }} />
					</div> */}


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Email do candidato:</span>
						<TextInput
							fullWidth
							id="email_10"
							name="email_10"
							value={formik.values.email_10}
							onChange={formik.handleChange}
							error={formik.touched.email_10 && Boolean(formik.errors.email_10)}
							helperText={formik.touched.email_10 && formik.errors.email_10}
							disabled={indicationsData.completed_10}
							InputLabelProps={{ shrink: indicationsData.completed_10 }} />
					</div>


					<div className='divInsc insituicao'>
						<span className="nameCandit candText">Telefone do candidato:</span>
						<TextInputMaskNew
							fullWidth
							id="phoneNumber_10"
							name="phoneNumber_10"
							value={formik.values.phoneNumber_10}
							onChange={formik.handleChange}
							error={formik.touched.phoneNumber_10 && Boolean(formik.errors.phoneNumber_10)}
							helperText={formik.touched.phoneNumber_10 && formik.errors.phoneNumber_10}
							mask="(##) # ####-####"
							definitions={{
								'#': /[0-9]/,
							}}
							disabled={indicationsData.completed_10}
							InputLabelProps={{ shrink: indicationsData.completed_10 }} />
					</div>



					<div className='textAlertDiv'>

						<span className='textAlert'>*Preenchimento obrigatório</span>
					</div>
					<FormGroup>
						<FormLabel id="controlled-radio-buttons-group">Categoria:</FormLabel>
						<div className='divRadio'>
							<RadioGroup
								aria-labelledby="controlled-radio-buttons-group"
								value={formik.values.category_10}
								onChange={(event) => handleRadioChange(event)}
							>
								<FormControlLabel className='radioHome instituicao' value="Ciência" control={<Radio name="category_10" disabled={indicationsData.completed_10} />} label="Ciência" />
								<FormControlLabel className='radioHome instituicao' value="Tecnologia" control={<Radio name="category_10" disabled={indicationsData.completed_10} />} label="Tecnologia" />
							</RadioGroup>
						</div>
						{formik.touched.category_10 && Boolean(formik.errors.category_10) && <p className='errorHelperText'>{formik.errors.category_10}</p>}
					</FormGroup>

				</div>}




				<div className='row-gap last'>

					<ButtonRemoverCand
						label="- Remover candidato"
						type="outline"
						typeSubmit={false}
						onClick={handleRemoveCandidate}
					/>

					{totalIndications < 9 && <ButtonNewCand
						label="+  Adicionar novo candidato"
						type="outline"
						typeSubmit={false}
						onClick={() => setTotalIndications(old => old < 9 ? old + 1 : 4)}
					// style={{marginTop: formik.touched.shortRelevantScientificDiscoveries && Boolean(formik.errors.shortRelevantScientificDiscoveries) ? 0 : 20}}
					/>}

				</div>

				<div className='row-gap last'>



					{/* <ButtonVoltar
						type="outline"
						label="VOLTAR"
						onClick={() => { UserLogOut() }}
					/> */}

					<ButtonSalvar
						type="outline"
						label="SALVAR E CONTINUAR DEPOIS"
						color={baseColors.lightIndigo}
						onClick={ReloadButton()}
					/>

					{show && (
						<ConfirmPopupNavigation
							isOpen={show}
							onClose={() => setShow(false)}
							onBack={() => console.log("Voltando...")}
							onNext={async () => console.log("Avançando...")}
						/>
					)}



					<ButtonSubmeter
						label="FINALIZAR AS INDICAÇÕES AGORA"
						typeSubmit={false}
						onClick={() => setShow(true)}
					// style={{marginTop: formik.touched.shortRelevantScientificDiscoveries && Boolean(formik.errors.shortRelevantScientificDiscoveries) ? 0 : 20}}
					/>

					{/* <ButtonSubmeter
						label="SUBMETER INDICAÇÕES"
						color={baseColors.darkBlue}
					// style={{marginTop: formik.touched.shortRelevantScientificDiscoveries && Boolean(formik.errors.shortRelevantScientificDiscoveries) ? 0 : 20}}
					/> */}

				</div>

			</form>

		</div>




		</>
	)
}
